import React, { useEffect, useMemo, useState } from 'react';

import { useUIResourcePool } from '@/ducks/common/resources';
import { useMNVV } from '@/ducks/mnvv/hooks';
import { selectMnvvBookedDate, selectMnvvDiscount } from '@/ducks/mnvv/selectors';
import { getAuthFromStorage } from '@/helpers/auth/core';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { formatDate } from '@/helpers/util/dateUtil';
import { VWOFlag, useVWOFlag } from '@/hooks/useVWOFeature';
import { useAppSelector } from '@/store';

import UIResource from '../UIResource';
import UIResourceHtml from '../UIResource/Html';

import './MNVVOfferBanner.scss';

export const UI_RESOURCE_POOL = {
  accountTitle: 'MNVV.Account.title',
  discountIcon: 'MNVV.Account.Reservation.Off.Voyage.Icon',
  discountSubheading: 'VoyageCard.MNVV.discountSubheading',
  mnvvBackground: 'VoyageCard.MNVV.background',
  mnvvIcon: 'VoyageCard.MNVV.stamp',
  onboardCreditIcon: 'MNVV.Account.Reservation.Sailor.Loot.Icon',
  onboardCreditSubheading: 'VoyageCard.MNVV.onboardCreditSubheading',
  redemptionFulltitle: 'MNVV.earlyRedemption.fulltitle',
  strikethrough: 'MNVV.earlyRedemption.strikethrough',
} as const;

const BANNER_LIFE_TIME = 60 * 86400000;

const MNVVOfferBanner = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [targetTime, setTargetTime] = useState<Date>();

  useEffect(() => {
    const isAuth = getAuthFromStorage();
    setIsLoggedIn(!!isAuth);
  }, []);

  const {
    accountTitle,
    discountIcon,
    discountSubheading,
    mnvvBackground,
    mnvvIcon,
    onboardCreditIcon,
    onboardCreditSubheading,
    redemptionFulltitle,
    strikethrough,
  } = useUIResourcePool<
    keyof typeof UI_RESOURCE_POOL,
    'discountIcon' | 'mnvvBackground' | 'mnvvIcon' | 'onboardCreditIcon'
  >(UI_RESOURCE_POOL);

  const isMnvvOfferRevisionEnabled = useVWOFlag(VWOFlag.MNVV_OFFER_REVISION);

  const { isMNVV } = useMNVV();
  const bookedDate = useAppSelector(selectMnvvBookedDate);
  const { amount, currencyCode } = useAppSelector(selectMnvvDiscount)! || {};
  const currencySymbol = getSymbolFromCurrenciesData(currencyCode)!;

  useEffect(() => {
    let endTime = 0;
    let resInitDate;

    if (isMNVV && bookedDate) {
      if (!isNaN(new Date(bookedDate).getTime())) {
        resInitDate = new Date(bookedDate);
      }

      if (resInitDate) {
        endTime = resInitDate.getTime() + BANNER_LIFE_TIME;
        setTargetTime(new Date(endTime));
      }
    }
  }, [bookedDate, isMNVV]);

  const isBannerEnable = useMemo(() => {
    const now = new Date();
    const isOfferActive = targetTime ? now.getTime() < targetTime.getTime() : false;

    return isMNVV && isMnvvOfferRevisionEnabled && isLoggedIn && isOfferActive;
  }, [isMnvvOfferRevisionEnabled, isLoggedIn, targetTime, isMNVV]);

  if (!isBannerEnable) {
    return null;
  }

  return (
    <div className="MNVVOfferBanner" data-testid="mnvv-banner-container">
      <div className="MNVVOfferBanner__info">
        <div className="early-redemption">
          <span className="account-title">{accountTitle}</span>
          <span className="full-title">{redemptionFulltitle}</span>
          <span className="description">
            <UIResourceHtml id="MNVV.earlyRedemption.description" values={{ Date: formatDate(targetTime, 'd MMMM') }} />
          </span>
        </div>
        <div className="account-reservation">
          <div className="reservation-col">
            <span className="reservation-col__icon">
              <img alt={onboardCreditIcon?.alt ?? ''} src={onboardCreditIcon?.src ?? ''} />
            </span>
            <div className="reservation-col__text">
              <span className="title">{onboardCreditSubheading}</span>
              <span className="description">
                <UIResourceHtml id="MNVV.earlyRedemption.details" values={{ Strikethrough: strikethrough }} />
              </span>
            </div>
          </div>
          <div className="reservation-col">
            <span className="reservation-col__icon">
              <img alt={discountIcon?.alt ?? ''} src={discountIcon?.src ?? ''} />
            </span>
            <div className="reservation-col__text">
              <span className="title">{discountSubheading}</span>
              <span className="description">
                <UIResource id="MNVV.Account.Your.Voyage.description" values={{ Amount: amount, currencySymbol }} />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="MNVVOfferBanner__timer">
        <div className="timer-container">
          {/* TODO: uncomment Timer on future */}
          {/* {targetTime && <Timer className="timer-countdoun" targetTime={targetTime} />} */}
          <img className="timer-icon" src={mnvvIcon?.src ?? ''} />
        </div>
        <img className="timer-background" src={mnvvBackground?.src ?? ''} />
      </div>
    </div>
  );
};

export default MNVVOfferBanner;
