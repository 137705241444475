import PropTypes from 'prop-types';
import React from 'react';

import axios from 'axios';
import isEqual from 'lodash/isEqual';
import Lottie from 'react-lottie';

import { env } from '@/environment';

const propTypes = {
  defaultAnimation: PropTypes.string,
  durations: PropTypes.arrayOf(PropTypes.shape({})),
  selectedDurations: PropTypes.arrayOf(PropTypes.string),
};

const defaultProps = {
  defaultAnimation: 'large',
  durations: [],
  selectedDurations: [],
};

const animations = [];
let animationValue = '';

class LuggageAnimation extends React.Component {
  getMaxDuration = (durations) => durations?.length && durations?.reduce((a, b) => (a.min > b.min ? a : b));

  loadAnimations = () => {
    const promises = [];

    if (!animations.length) {
      const { durations } = this.props;

      const animationsToLoad = new Set();
      durations.forEach((startDuration) => {
        durations.forEach((endDuration) => {
          if (startDuration.animation !== endDuration.animation) {
            animationsToLoad.add(`${startDuration.animation}-${endDuration.animation}`);
          }
        });
      });

      animationsToLoad.forEach((key) => {
        const url = `${env.CONTEXT}/static/images/animations/Luggage/${key}.json`;
        const promise = axios.get(url).then((result) => {
          animations.push({ image: result.data, key });
        });
        promises.push(promise);
      });
    }

    Promise.all(promises).then(() => {
      this.setState({ animationsLoaded: true });
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      animationData: null,
      animationsLoaded: false,
      isPaused: false,
    };
    this.loadAnimations = this.loadAnimations.bind(this);
  }

  componentDidMount() {
    this.loadAnimations();
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectedDurations } = this.props;
    const { animationsLoaded } = this.state;
    if (!isEqual(prevProps.selectedDurations, selectedDurations) || prevState.animationsLoaded !== animationsLoaded) {
      this.updateAnimation(prevProps.selectedDurations, selectedDurations);
    }

    // Crutch to make library static DOM element unreadable for screen readers
    const lottieEl = document.getElementById('lottie-luggage')?.firstChild;
    lottieEl?.removeAttribute('role');
    lottieEl?.removeAttribute('aria-label');
    lottieEl?.removeAttribute('tabindex');
  }

  getAnimation(selectedDurations) {
    const { defaultAnimation, durations } = this.props;
    if (!selectedDurations.length) {
      return defaultAnimation;
    }
    const highestSelection = this.getMaxDuration(selectedDurations)?.min;
    return durations.find((duration) => duration.min === highestSelection).animation;
  }

  render() {
    const { animationData, animationsLoaded, isPaused } = this.state;

    if (!animationsLoaded || !animationData) {
      return null;
    }

    const lottieOptions = {
      animationData: animationData.image,
      autoplay: false,
      loop: false,
    };
    return (
      <div id="lottie-luggage">
        <Lottie height={86} isClickToPauseDisabled isPaused={isPaused} options={lottieOptions} width={287} />
      </div>
    );
  }

  updateAnimation(prevSelectedDurations, selectedDurations) {
    let animationData;
    const isInitial = isEqual(prevSelectedDurations, selectedDurations);
    const currentAnimation = this.getAnimation(selectedDurations);
    if (isInitial) {
      animationData = animations.find((animation) => animation.key.startsWith(currentAnimation));
    } else if (currentAnimation !== animationValue) {
      animationData = animations.find((animation) => animation.key === `${animationValue}-${currentAnimation}`);
    }

    if (animationData) {
      animationValue = currentAnimation;
      this.setState({ animationData, isPaused: isInitial });
    }
  }
}

LuggageAnimation.propTypes = propTypes;
LuggageAnimation.defaultProps = defaultProps;

export default LuggageAnimation;
