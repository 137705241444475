import React, { useContext, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { selectDeparturePorts } from '@/ducks/filters/selectors';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { getLabelforPorts } from '@/helpers/util/misc';
import { FilterKey } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const ShipFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const homePorts = useAppSelector(selectDeparturePorts);
  const { formatMessage } = useContext(FormattedMessageContext);
  // const previousLabel = useRef<null | string>(null);

  const label = useMemo(() => {
    const label = getLabelforPorts(homePorts, formatMessage);
    return isEmpty(homePorts) ? null : label;
  }, [homePorts, formatMessage]);

  return label ? (
    <SelectedFilterChip filterKey={FilterKey.PORT} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default ShipFilterChip;
