export type TFilters = {
  accessible?: boolean;
  agencyId?: string;
  agentId?: string;
  cabinType?: string;
  cabins: number;
  chooseCabinSort?: 'priceAsc' | 'recommended'; // TODO: revisit after https://virginvoyages.atlassian.net/browse/MSH-110709 VWO test is concluded, depending on its results
  currencyCode?: 'USD';
  durations: TFiltersDuration[];
  fromDate?: string;
  homePorts?: string[];
  initialized?: boolean;
  maxPrice: string;
  minPrice: string;
  nodes?: string[];
  packages?: string[];
  ports?: string[];
  priceType?: FiltersPriceType;
  promotionalVoyages?: string[];
  resetAll?: boolean;
  sailors: number;
  selectedRegions: {
    name: string;
    packages: string[];
    ports: string[];
    regionId: string;
  }[];
  selectedRegionsIds: string[];
  shipList: string[];
  sortType: string;
  toDate: string;
  weekend: boolean;
};

export type TFiltersDuration = { animation: string; max?: number; min: number };

export type TFiltersOptions = {
  cabinTypes: { code: string; name: string }[];
  defaultFilters: { cabins: number; sailors: number };
  durationOptions: TFiltersDuration[];
};

export enum FilterKey {
  ACCESSIBLE = 'accessible',
  CABIN_TYPE = 'cabinType',
  DURATION = 'duration',
  PORT = 'port',
  PRICE = 'price',
  SHIP = 'ship',
  TRAVEL_PARTY = 'travelParty',
  WEEKEND = 'weekend',
}

export enum FiltersPriceType {
  perCabin = 'perCabin',
  perSailor = 'perSailor',
  sailorPerNight = 'SailorPerNight',
}
