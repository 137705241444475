'use client';

import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  clearCabinCategoriesDataAction,
  clearInvoiceDataAction,
  payInFullPromoAppliedAction,
  priceTypeDataAction,
  setDefaultPriceTypeAction,
} from '@/actions';
import { HeadlineWithAdvertCardList } from '@/components/BlackFriday/HeadlineWithAdvertCardList';
import { QuickSearchCards } from '@/components/BlackFriday/HeadlineWithAdvertCardList/QuickSearchCards';
import Flyout from '@/components/Flyout';
import FilterOption from '@/components/Icon/FilterOption';
import Button from '@/components/elements/Button';
import { ModalContent, ModalContentBody } from '@/components/elements/ModalContent';
import { getAccessKey } from '@/ducks/accessKeys';
import { setAppliedLabels } from '@/ducks/appliedfilterLabels/setter';
import { selectLookup } from '@/ducks/common/selectors';
import { selectSettings } from '@/ducks/common/settings';
import { selectCurrencyCode, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { setMultipleVoyageFilter } from '@/ducks/multipleVoyageFilter';
import {
  getPackageSearch,
  resetFiltersForPackages,
  setIsVoyageInvalid,
  setPriceRangeForPackages,
  setPriceTypeForPackages,
} from '@/ducks/pages/chooseVoyage';
import { newInitFilters } from '@/ducks/pages/chooseVoyage/actions/applyFilters';
import { selectFilteredSailings } from '@/ducks/pages/chooseVoyage/filteredPackages/selectors';
import { setResetKeyAction } from '@/ducks/pages/chooseVoyage1.0';
import { getCelebrationsInfoSession } from '@/ducks/programs/celebrations/actions';
import { exitReferredBookerFlowAction } from '@/ducks/programs/mgm/actions';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { routes } from '@/ducks/routes';
import { getSearchParams } from '@/ducks/routes/history';
import { resetTravelParty } from '@/ducks/travelParty/slice';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import { removePaymentPaymentOption, removePaymentReservationNumber } from '@/helpers/managers/payment';
import {
  getSessionStorageValue,
  removeSessionStorageValue,
  removeStoredValue,
  setSessionStorageValue,
} from '@/helpers/util/storage';
import { trackSailorId } from '@/helpers/util/tracking';
import { getVWOUserId } from '@/helpers/vwo/getVWOUserId';
import getVWOClientInstance from '@/helpers/vwo/instance';
import { withIsDesktop } from '@/hocs/withIsDevice';
import withRouter from '@/hocs/withRouter';
import withSearchParams from '@/hocs/withSearchParams';
import tagmanager from '@/tagmanager';

import PackageItineraryResults from './PackageItineraryResults';

import './PackageResults.scss';

class ItineraryResults extends React.Component {
  isPackageInView = () => {
    if (document.documentElement.scrollTop === 0) {
      return false;
    }

    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    return reduce(
      document.getElementsByClassName('PackageItineraryResults__body'),
      (_, card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top > 0 || rect.top > windowHeight) {
          return false;
        }
        return true;
      },
      false,
    );
  };

  onDismiss = () => {
    const { actions } = this.props;
    actions.resetFiltersForPackages();
    actions.setResetKeyAction(true);
    actions.setIsVoyageInvalid(false);
  };

  onSearch = () => {
    if (this.props?.isDesktop) {
      window.scrollTo(0, 0);
    } else {
      this.setState({ mobileSearchAgainClickedFlag: !this.state.mobileSearchAgainClickedFlag });
    }
    this.setState({ searchAgain: false });
    tagmanager.tracker.voyagesFilter.trackReApplyFilters();
  };

  constructor(props) {
    super(props);
    this.state = {
      isChangedPackagesEventSent: false,
      isReady: false,
      searchAgain: false,
    };
    this.onClearFilter = this.onClearFilter.bind(this);
    this.handleScroll = throttle(this.handleScroll.bind(this), 150);
    this.onResetAllFilterClick = this.onResetAllFilterClick.bind(this);
  }

  componentDidMount() {
    const { appliedSailors, celebrations } = this.props;
    const { formatMessage } = this.context;

    const celebrationSessionData = getCelebrationsInfoSession();

    if (
      (celebrationSessionData && celebrationSessionData?.newReferral === false) ||
      celebrations?.params?.newReferral === false
    ) {
      const celebrationData =
        celebrations?.params?.voyageCode && celebrations?.params?.voyageId
          ? celebrations?.params
          : celebrationSessionData;
      const { packageCode, shipCode, voyageId } = celebrationData;
      routes.planner.preCheckOut.go({
        keepSearch: true,
        packageCode,
        sailors: appliedSailors,
        shipCode,
        voyageId,
      });
      return;
    }

    const { actions, configServiceData, searchParams } = this.props;

    const defaultPriceTypeFlow = () => {
      const { appliedPriceType } = this.props;
      if (!appliedPriceType) {
        actions.setPriceTypeForPackages(get(configServiceData, 'pricingType', 'perCabin'), false);
      } else {
        actions.setPriceTypeForPackages(appliedPriceType, false);
      }
    };

    const alternativeVWOPriceTypeFlow = async () => {
      const vwoClient = await getVWOClientInstance();

      const userId = getVWOUserId();
      const variationName = vwoClient.activate('defaultPriceTypeFeatureCampaign', userId);

      if (variationName === 'PerNight') {
        setSessionStorageValue('isDefaultPriceTypeWasChangedAB', true);

        actions.setPriceTypeForPackages('SailorPerNight', false);
        actions.priceTypeDataAction('SailorPerNight');
        actions.setDefaultPriceTypeAction('SailorPerNight');
      } else {
        defaultPriceTypeFlow();
      }
    };

    const alternativeVWOPriceTypeChecking = async () => {
      const vwoClient = await getVWOClientInstance();

      const userId = getVWOUserId();
      const variationName = vwoClient.activate('defaultPriceTypeFeatureCampaign', userId);

      if (variationName === 'PerNight') {
        const {
          filtersOptions: {
            defaultFilters: { priceType },
          },
        } = this.props;
        actions.priceTypeDataAction('SailorPerNight');
        actions.setDefaultPriceTypeAction('SailorPerNight');
        actions.setPriceTypeForPackages(priceType, false);
      }
    };

    const cabinType = searchParams.get('cabinType');
    const isSuitableCabinType = cabinType === 'TZ' || cabinType === 'IZ';

    const isDefaultPriceTypeWasChangedAB = getSessionStorageValue('isDefaultPriceTypeWasChangedAB');

    if (isSuitableCabinType) {
      actions.setPriceTypeForPackages('SailorPerNight', false);
      actions.priceTypeDataAction('SailorPerNight');
      actions.setDefaultPriceTypeAction('SailorPerNight');

      setSessionStorageValue('lockitinrateblitz', true);
    } else if (!isDefaultPriceTypeWasChangedAB) {
      alternativeVWOPriceTypeFlow();
    } else {
      defaultPriceTypeFlow();
    }
    alternativeVWOPriceTypeChecking();
    window.scrollTo(0, 0);

    window.addEventListener('scroll', this.handleScroll);

    removeStoredValue('primarySailorError');

    removeSessionStorageValue('primarySailor');
    removePaymentReservationNumber();
    removePaymentPaymentOption();
    removeSessionStorageValue('add_flights');

    actions.resetTravelParty();
    actions.payInFullPromoAppliedAction(false);
    actions.clearInvoiceDataAction();
    actions.clearCabinCategoriesDataAction();
    actions.setAppliedLabels(formatMessage);

    actions.newInitFilters();

    const { voyageId } = getSearchParams();

    if (voyageId) {
      actions.setMultipleVoyageFilter();
    }

    trackSailorId();
  }

  componentDidUpdate(prevProps) {
    const {
      actions,
      appliedCurrency,
      configServiceData,
      dispatch,
      filteredPackages,
      filteredSailings,
      packages,
      router,
      searchParams,
    } = this.props;
    const { isChangedPackagesEventSent } = this.state;
    const { featureFlags } = configServiceData;
    if (
      configServiceData &&
      !isEqual(
        get(configServiceData, 'pricingType', 'perCabin'),
        get(prevProps.configServiceData, 'pricingType', 'perCabin'),
      )
    ) {
      actions.setPriceTypeForPackages(get(configServiceData, 'pricingType', 'perCabin'));
    }
    if (featureFlags !== undefined) {
      if (!featureFlags.myAccountVersion2 && searchParams.get('accessKey')) {
        router.back();
      }
    }
    if (
      appliedCurrency !== prevProps.appliedCurrency ||
      (!isEmpty(packages) && !isEmpty(prevProps?.packages) && !isEqual(packages, prevProps?.packages))
    ) {
      const defaultPriceRange = {
        maxPrice: null,
        minPrice: null,
      };
      actions.setPriceRangeForPackages(defaultPriceRange, false);
    }

    const areSailingsChanged = !isEqual(filteredSailings, prevProps.filteredSailings);
    const isFetchedEventSent = !isEmpty(filteredSailings) && !isChangedPackagesEventSent;

    if (areSailingsChanged || isFetchedEventSent) {
      this.setState({
        isChangedPackagesEventSent: true,
      });
      tagmanager.observer.trackingEventsObserver.notify({
        data: {
          dispatch,
          packages: filteredPackages,
        },
        name: tagmanager.observer.TRACKING_EVENTS_FOR_OBSERVER.CHOOSE_SAILING_PAGE_LOADED,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const isSearchAgainBtnVisible = this.isPackageInView();
    this.setState({ searchAgain: isSearchAgainBtnVisible });
    const isGlobalNavVisible = document.getElementsByClassName('GlobalNavigation')?.[0]?.classList?.contains('-hidden');
    const searchAgainCta = document.getElementsByClassName('PackageItineraryResults__searchAgain')?.[0];
    if (!searchAgainCta) {
      return;
    }
    if (isGlobalNavVisible) {
      searchAgainCta.classList.remove('--down');
    } else {
      searchAgainCta.classList.add('--down');
    }
  }

  onClearFilter() {
    const { actions } = this.props;
    actions.resetFilters();
  }

  onResetAllFilterClick(checkMgm = true) {
    const { actions, mgm } = this.props;
    if (checkMgm && mgm.isReferredBooker) {
      this.handleOpenModal();
      return;
    }
    actions.resetFilters();
  }

  render() {
    const { isVoyageInvalid } = this.props;
    const { mobileSearchAgainClickedFlag, searchAgain } = this.state;

    return (
      <div>
        <section className="NewVoyagePlanner">
          {searchAgain && (
            <div className="PackageItineraryResults__searchAgain">
              <div className="container">
                <div className="icon">
                  <FilterOption />
                </div>
                <div
                  className="text"
                  onClick={() => this.onSearch()}
                  onKeyDown={() => this.onSearch()}
                  role="button"
                  tabIndex={0}
                >
                  <span aria-label="hidden" />
                  <FormattedMessage defaultMessage="Search Again" id="ChooseVoyage.SearchAgain.button" />
                </div>
              </div>
            </div>
          )}
          <PackageItineraryResults
            headlineWithAdvertCardList={
              <HeadlineWithAdvertCardList>
                <QuickSearchCards />
              </HeadlineWithAdvertCardList>
            }
            mobileSearchAgainClickedFlag={mobileSearchAgainClickedFlag}
          />
        </section>
        {isVoyageInvalid && (
          <Flyout className="voyageId_invalid" direction="modal" onDismiss={this.onDismiss} open={!!isVoyageInvalid}>
            <ModalContent centered>
              <ModalContentBody>
                <h1>
                  <FormattedMessage
                    defaultMessage=" Oops!.., There was a problem retrieving the page you requested."
                    id="AccessKey.redirectError.heading"
                  />
                </h1>
                <p>
                  <FormattedMessage
                    defaultMessage=" Please re-select your voyage lorem ipsum dolor sit amet, consectetur adipiscing elit laboris nisi ut aliquip."
                    id="AccessKey.redirectError.body"
                  />
                </p>
              </ModalContentBody>
              <Button className="gotit_btn" onClick={this.onDismiss} variant="secondary">
                <FormattedMessage defaultMessage="OK, GOT IT!" id="AccessKey.redirectError.CTA" />
              </Button>
            </ModalContent>
          </Flyout>
        )}
      </div>
    );
  }
}

ItineraryResults.propTypes = {
  actions: PropTypes.shape({
    clearCabinCategoriesDataAction: PropTypes.func,
    clearInvoiceDataAction: PropTypes.func,
    payInFullPromoAppliedAction: PropTypes.func,
    resetFilters: PropTypes.func,
    resetTravelParty: PropTypes.func,
    setPriceRangeForPackages: PropTypes.func,
    setPriceTypeForPackages: PropTypes.func,
  }).isRequired,
  appliedCurrency: PropTypes.string.isRequired,
  appliedPriceType: PropTypes.string,
  celebrations: PropTypes.objectOf({
    isCelebrations: PropTypes.bool,
    params: {
      pkgCode: PropTypes.string,
      shipCode: PropTypes.string,
      voyageCode: PropTypes.string,
    },
  }),
  configServiceData: PropTypes.shape({
    featureFlags: PropTypes.shape({}),
  }),
  dispatch: PropTypes.func.isRequired,
  filteredSailings: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  filtersOptions: PropTypes.shape({
    loaded: PropTypes.bool,
    regions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  isVoyageInvalid: PropTypes.bool,
  itineraryDetails: PropTypes.shape({}),
  lookup: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
  sailingSearch: PropTypes.shape({
    error: PropTypes.any,
    loading: PropTypes.bool.isRequired,
    sailings: PropTypes.arrayOf(
      PropTypes.shape({
        startDate: PropTypes.string,
      }),
    ),
  }).isRequired,
};

ItineraryResults.defaultProps = {
  appliedPriceType: '',
  celebrations: {},
  configServiceData: {
    featureFlags: {},
  },
  isVoyageInvalid: false,
  itineraryDetails: {},
  lookup: {},
};

ItineraryResults.contextType = FormattedMessageContext;

function mapStateToProps(state) {
  return {
    accessKeyDetails: getAccessKey(state),
    appliedCurrency: selectCurrencyCode(state),
    appliedPriceType: selectPriceType(state),
    appliedSailors: selectSailors(state),
    celebrations: state?.celebrations,
    configServiceData: selectSettings(state),
    defaultPackages: state?.chooseVoyageNew?.mainPackages?.defaultPackages || [],
    filteredPackages: state?.chooseVoyageNew?.filteredPackages?.packages || [],
    filteredSailings: selectFilteredSailings(state),
    filtersOptions: getFiltersOptions(state),
    isVoyageInvalid: state?.chooseVoyageNew?.isVoyageInvalid,
    itineraryDetails: get(state, 'voyagePlanner.itineraryResults.itineraryDetails.data', {}),
    lookup: selectLookup(state),
    mgm: selectMgm(state),
    packages: state?.chooseVoyageNew?.mainPackages?.packages || [],
    sailingSearch: getPackageSearch(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearCabinCategoriesDataAction,
        clearInvoiceDataAction,
        exitReferredBookerFlow: exitReferredBookerFlowAction,
        newInitFilters,
        payInFullPromoAppliedAction,
        priceTypeDataAction,
        resetFiltersForPackages,
        resetTravelParty,
        setAppliedLabels,
        setDefaultPriceTypeAction,
        setIsVoyageInvalid,
        setMultipleVoyageFilter,
        setPriceRangeForPackages,
        setPriceTypeForPackages,
        setResetKeyAction,
      },
      dispatch,
    ),
    dispatch,
  };
}

export default withIsDesktop(
  withRouter(withSearchParams(connect(mapStateToProps, mapDispatchToProps)(ItineraryResults))),
);
