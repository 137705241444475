import { getAuthFromStorage } from '@/helpers/auth/core';
import tagmanager from '@/tagmanager';
import getPage from '@/tagmanager/routesMapping';

export const trackSailorId = () => {
  const auth = getAuthFromStorage();
  if (auth) {
    tagmanager.trackData({
      sailor_id: auth.userId, // unique sailor identifier
    });
  }
};

export const trackResetFilters = (filterName: string) => {
  const location = getPage();
  tagmanager.trackEvent({
    event: 'GTMevent',
    eventAction: 'link-click',
    eventCategory: `VV-${location}`,
    eventLabel: `reset|${filterName}`,
  });
};
