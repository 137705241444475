import React, { useCallback, useContext, useMemo, useState } from 'react';

import { selectAppliedFilterLabelsCount } from '@/ducks/appliedfilterLabels';
import { selectCabins, selectSailors } from '@/ducks/filters/selectors';
import { getDefaultFilters } from '@/ducks/filtersOptions/selectors';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { FilterKey } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const TravelPartyFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const cabins = useAppSelector(selectCabins);
  const sailors = useAppSelector(selectSailors);
  const defaultFilters = useAppSelector(getDefaultFilters);
  const appliedFilterLabelsCount = useAppSelector(selectAppliedFilterLabelsCount);
  const { formatMessage } = useContext(FormattedMessageContext);
  const [previousLabel, setPreviousLabel] = useState<null | string>(null);

  const onRemoveFilter = useCallback(() => {
    setPreviousLabel(null);
  }, [setPreviousLabel]);

  const label = useMemo(() => {
    if (cabins === defaultFilters.cabins && sailors === defaultFilters.sailors && previousLabel === null) {
      return null;
    } else if (!appliedFilterLabelsCount && sailors === defaultFilters.sailors) {
      return null;
    }

    const sailorText = formatMessage({
      defaultMessage: sailors > 1 ? 'Sailors' : 'Sailor',
      id: sailors > 1 ? 'TravelPartyRefinement.sailors' : 'TravelPartyRefinement.sailor',
    });
    const cabinText = formatMessage({
      defaultMessage: cabins > 1 ? 'Cabins' : 'Cabin',
      id: cabins > 1 ? 'TravelPartyRefinement.cabins' : 'TravelPartyRefinement.cabin',
    });

    const label = `${sailors} ${sailorText} , ${cabins} ${cabinText}`;
    setPreviousLabel(label);

    return label;
  }, [sailors, cabins, formatMessage, defaultFilters, appliedFilterLabelsCount, previousLabel, setPreviousLabel]);

  return label ? (
    <SelectedFilterChip
      filterKey={FilterKey.TRAVEL_PARTY}
      label={label}
      onBeforeRemoveFilter={onRemoveFilter}
      onOpenFilterModal={onOpenFilterModal}
    />
  ) : null;
};

export default TravelPartyFilterChip;
