import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import { connect } from 'react-redux';

import DurationFilter from '@/components/AdvancedFilterRefinement/DurationFilter';
import { selectDurations, selectWeekend } from '@/ducks/filters/selectors';
import {
  getPresentDurationsForPackages,
  isWeekendAvailableForPackages,
  setDurationsForPackages as setDurationsAction,
} from '@/ducks/pages/chooseVoyage';
import { setAppliedFilterLabels as setAppliedFilterLabelsAction } from '@/ducks/pages/chooseVoyage1.0';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { getDurationLabel } from '@/helpers/util/sailing';

import './DurationRefinement.scss';

class DurationRefinement extends React.Component {
  onDurationChange = (selectedDuration) => {
    const { durations, weekend } = this.state;
    const { setDurations } = this.props;
    const selected = durations.find((duration) => duration.min === selectedDuration.min);

    if (!selected) {
      const days = [...durations, selectedDuration];
      this.setState({ durations: days });
      setDurations(days, weekend, true);
      return;
    }

    const minDays = durations.filter((duration) => duration.min !== selectedDuration.min);
    this.setState({
      durations: minDays,
    });
    setDurations(minDays, weekend);
  };

  onRenderChange = () => {
    const { appliedDurations, appliedWeekend } = this.props;
    this.setState({
      durations: appliedDurations,
      weekend: appliedWeekend,
    });
  };

  onWeekendChange = (event) => {
    const { durations } = this.state;
    const { setDurations } = this.props;

    const weekend = event.target.checked;
    this.setState({ weekend });
    setDurations(durations, weekend);
    if (weekend === false) {
      this.updateFilterLabelForWeekend('');
    }
  };

  updateFilterLabel = (title, isDefault) => {
    const { labels, setAppliedFilterLabels } = this.props;
    if (!isDefault && labels.duration !== title) {
      setAppliedFilterLabels({ duration: title });
    } else if (isDefault && labels.duration !== '') {
      setAppliedFilterLabels({ duration: '' });
    }
  };

  updateFilterLabelForWeekend = (title) => {
    const { labels, setAppliedFilterLabels } = this.props;
    if (labels.weekend !== title) {
      setAppliedFilterLabels({ weekend: title });
    }
  };

  constructor(props) {
    super(props);

    this.state = {
      durations: [],
      weekend: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { appliedDurations, appliedWeekend } = this.props;
    if (prevProps.appliedDurations.length !== appliedDurations.length || appliedWeekend !== prevProps.appliedWeekend) {
      this.onRenderChange(false);
    }
  }

  getLabel() {
    const { appliedWeekend, filteredDurationOptions } = this.props;
    const { durations } = this.state;
    let labelId = '';
    let defaultMsg = '';
    let durationsLabel = '';
    let isDefault = false;
    let bothNightWeekend = false;
    const { formatMessage } = this.context;
    if (durations?.length) {
      durationsLabel = filteredDurationOptions
        .reduce((acc, duration) => {
          if (durations.find((d) => d.min === duration.min)) {
            acc.push(getDurationLabel(duration));
          }
          return acc;
        }, [])
        .join(', ');
      if (appliedWeekend) {
        labelId = 'AdvancedRefinement.durationAndWeekendLabel';
        defaultMsg = '{durations} nights and weekend only';
        bothNightWeekend = true;
      } else {
        labelId = 'AdvancedRefinement.label';
        defaultMsg = '{durations} nights';
        bothNightWeekend = false;
      }
    } else if (appliedWeekend) {
      labelId = 'AdvancedRefinement.weekendOnlyLabel';
      defaultMsg = 'Weekend only';
      bothNightWeekend = false;
    } else {
      labelId = 'AdvancedFilter.Duration.Default.Value';
      defaultMsg = 'Any number of nights';
      isDefault = true;
      bothNightWeekend = false;
    }
    const label = filteredDurationOptions
      ?.reduce((acc, duration) => {
        if (durations?.find((d) => d.min === duration.min)) {
          acc.push(getDurationLabel(duration));
        }
        return acc;
      }, [])
      .join(' & ');

    const title = formatMessage({
      defaultMessage: defaultMsg,
      id: labelId,
      values: { durations: durationsLabel },
    });
    if (appliedWeekend) {
      const weekendTxt = formatMessage({
        defaultMessage: 'Weekend voyages',
        id: 'ZeroResults.WeekendVoyages.txt',
      });
      this.updateFilterLabelForWeekend(weekendTxt[0]);
      if (bothNightWeekend) {
        const nightsTxt = formatMessage({
          defaultMessage: '{durations} nights',
          id: 'AdvancedRefinement.label',
          values: { durations: label },
        });
        this.updateFilterLabel(nightsTxt.join(''), isDefault);
      }
    } else {
      const newTitle = formatMessage({
        defaultMessage: defaultMsg,
        id: labelId,
        values: { durations: label },
      });
      this.updateFilterLabel(newTitle.join(''), isDefault);
    }
    return title.join('');
  }

  render() {
    const { durations, weekend } = this.state;
    const {
      closeFilterModal,
      defaultAnimation,
      filteredDurationOptions,
      isOpenFilterSection,
      isWeekendDisabled,
      toggleFilterState,
    } = this.props;

    return (
      <DurationFilter
        closeFilterModal={closeFilterModal}
        defaultAnimation={defaultAnimation}
        durations={durations}
        filteredDurationOptions={filteredDurationOptions}
        getDurationLabel={getDurationLabel}
        isOpenFilterSection={isOpenFilterSection}
        isWeekendDisabled={isWeekendDisabled}
        label={this.getLabel()}
        onDurationChange={this.onDurationChange}
        onRenderChange={this.onRenderChange}
        onWeekendChange={this.onWeekendChange}
        toggleFilterState={toggleFilterState}
        weekend={weekend}
      />
    );
  }
}

DurationRefinement.propTypes = {
  appliedDurations: PropTypes.arrayOf(PropTypes.number).isRequired,
  appliedWeekend: PropTypes.bool.isRequired,
  closeFilterModal: PropTypes.func,
  defaultAnimation: PropTypes.string,
  filteredDurationOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpenFilterSection: PropTypes.bool,
  isWeekendDisabled: PropTypes.bool.isRequired,
  labels: PropTypes.objectOf(PropTypes.shape({})),
  setAppliedFilterLabels: PropTypes.func,
  setDurations: PropTypes.func.isRequired,
};
DurationRefinement.defaultProps = {
  closeFilterModal: () => {},
  defaultAnimation: 'large',
  isOpenFilterSection: false,
  labels: {},
  setAppliedFilterLabels: () => {},
};

DurationRefinement.contextType = FormattedMessageContext;

const mapStateToProps = (state) => ({
  appliedDurations: selectDurations(state),
  appliedWeekend: selectWeekend(state),
  defaultAnimation: get(state, 'filtersOptions.defaultDurationAnimation', 'large'),
  filteredDurationOptions: getPresentDurationsForPackages(state),
  isWeekendDisabled: isWeekendAvailableForPackages(state),
  labels: get(state, 'appliedfilterLabels', {}),
});
const mapDispatchToProps = {
  setAppliedFilterLabels: setAppliedFilterLabelsAction,
  setDurations: setDurationsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(DurationRefinement);
