import Image from 'next/image';
import React, { useCallback, useMemo, useRef } from 'react';

import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import ArrowLeft from '@/components/Icon/ArrowLeft';
import CaretRight from '@/components/Icon/CaretRight';
import { FormCheck, FormGroup } from '@/components/elements';
import useCustomArrowKeys from '@/ducks/a11y/hooks/useCustomArrowKeys';
import { DESTINATION_TYPE as TOGGLE_VALUES } from '@/ducks/pages/chooseVoyage/destinationSelectors';
import { FormattedMessage } from '@/helpers/formatted-message';

import Itinerary from '../RegionRefinement/Itinerary';
import Toggle from './Toggle';

const DestinationDetails = ({
  checkboxRenderData,
  getActiveClassNames,
  isCheckSelected,
  isRegionSelected,
  isWholeSelected,
  itineraryFilters,
  onItineraryChange,
  onToggleChange,
  portsOfCall,
  selectedPackagesFn,
  selectedPortsFn,
  showItineraries,
  showRegions,
  toggleValue,
}) => {
  const regionsContainerRef = useRef(null);
  const itinerariesContainerRef = useRef(null);

  const onItineraryClick = useCallback(() => {
    if (!itinerariesContainerRef.current) {
      return;
    }

    itinerariesContainerRef.current.scrollTo(0, 0);
  }, []);

  const detailClasses = classNames('DestinationRefinement__Details', { reSizeHeight: showItineraries });

  const toggleClasses = classNames({
    DestinationRefinement__filterToggle: true,
    toggle: showItineraries,
  });

  const mobileRegionsClassNames = classNames({
    filterRegion: true,
    showRegions,
  });

  const mobileItinerariesClassNames = classNames({
    filterItineraries: true,
    showItineraries,
  });

  const sortRegions = useMemo(
    () =>
      itineraryFilters &&
      itineraryFilters.sort((a, b) => {
        if (a.name === b.name) {
          return 0;
        }
        return a.name < b.name ? -1 : 1;
      }),
    [itineraryFilters],
  );

  const getSelectedPackageNames = (packageCodes, packages) => {
    const { selectedPackagesNames } = packageCodes.reduce(
      (acc, packgId) => {
        const packageName =
          !isEmpty(packages) &&
          packages.find((packageCode) => packageCode.code === packgId || packageCode.id === packgId);
        if (packageName && !acc.selectedPackagesNames.includes(packageName.name)) {
          return { selectedPackagesNames: [...acc.selectedPackagesNames, packageName.name] };
        }
        return acc;
      },
      { selectedPackagesNames: [] },
    );

    return selectedPackagesNames;
  };

  const handleListItemKeyDownRegions = useCallback(
    (event, index) => {
      if (event.key === 'ArrowRight') {
        // Move focus to the Itineraries section
        event.preventDefault();
        const itinerariesSection = document.getElementById('firstElementOfPortsOrItineraries');
        itinerariesSection.focus();
      } else if (event.key === 'ArrowUp') {
        const currentItinerary = sortRegions[index - 1];
        onItineraryChange({ regionId: currentItinerary?.id, toItineraries: true })(event);
      } else if (event.key === 'ArrowDown') {
        const currentItinerary = sortRegions[index + 1];
        onItineraryChange({ regionId: currentItinerary?.id, toItineraries: true })(event);
      } else if (event.key === 'Enter' || event.key === ' ' || event.key === 'Space') {
        event.preventDefault();
        const currentItinerary = sortRegions[index];
        isRegionSelected({ regionId: currentItinerary?.id, toItineraries: false })(event);
      }
    },
    [onItineraryChange, isRegionSelected, sortRegions],
  );

  const handleKeyDownItinerariesAndPorts = useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      // Move focus to the Regions section
      event.preventDefault();
      const regionsSection = document.getElementById('firstElementOfRegions');
      regionsSection.focus();
    }
  }, []);

  useCustomArrowKeys({
    element: itinerariesContainerRef.current,
    elementsQuery: '[role="tab"]',
    isHorizontalArrowsIncluded: false,
  });

  useCustomArrowKeys({
    element: regionsContainerRef.current,
    elementsQuery: '[role="tab"]',
    isHorizontalArrowsIncluded: false,
  });

  return (
    <section className={detailClasses}>
      <div className={toggleClasses}>
        <Toggle id="destinationFilterType" onChange={onToggleChange} value={toggleValue} />
      </div>

      <div
        aria-controls="destinationFilterType"
        className={mobileRegionsClassNames}
        id="destinationFilterRegions"
        ref={regionsContainerRef}
        role="tablist"
      >
        {sortRegions.map((itinerary, index) => {
          const { image } = itinerary;
          const selectedArr =
            toggleValue === 'itineraries' ? selectedPackagesFn(itinerary.id) : selectedPortsFn(itinerary.id);
          const selectedPackageNames = getSelectedPackageNames(
            selectedArr,
            toggleValue === 'itineraries' ? itinerary.packages : itinerary.ports,
          );

          const regionId = `dest_region_${itinerary.id.replace(/\s+/, '-', 'g')}`;
          const regionLabelId = `${regionId}_label`;
          const regionSelectStatusId = `${regionId}_select`;

          return (
            <div
              aria-labelledby={`${regionLabelId} ${regionSelectStatusId}`}
              className={getActiveClassNames(itinerary, 'region')}
              id={index === 0 ? 'firstElementOfRegions' : ''}
              key={regionId}
              onClick={onItineraryClick}
              onKeyDown={(event) => handleListItemKeyDownRegions(event, index)}
              role="tab"
              tabIndex={index === 0 || !showItineraries ? 0 : -1}
            >
              <FormCheck
                checked={isCheckSelected(itinerary.id)}
                id={regionId}
                onChange={(event) => isRegionSelected({ regionId: itinerary.id, toItineraries: false })(event)}
                tabIndex={-1}
                type="checkbox"
                value={itinerary.id}
              />
              <div
                className="regionRow"
                id={itinerary.id}
                onClick={(event) => onItineraryChange({ toItineraries: true })(event)}
              >
                <div className="image">
                  {image && <Image alt="" height="100" src={image.src} width="100" />}

                  <div className={getActiveClassNames(itinerary, 'textDescription')}>
                    <p className="text" id={regionSelectStatusId}>
                      {isWholeSelected(itinerary.id) ? (
                        <FormattedMessage defaultMessage="All selected" id="DestinationRefinement.selected" />
                      ) : (
                        <FormattedMessage
                          defaultMessage={`${selectedPackageNames.length} selected`}
                          id="DestinationRefinement.selected"
                        />
                      )}
                    </p>
                  </div>
                </div>

                <div className="description" id={regionLabelId}>
                  <div className="name">{itinerary.name}</div>
                  <div className="title">{itinerary.title}</div>
                </div>

                <button aria-hidden="true" className="DestinationIcon --button" tabIndex={-1}>
                  <span className="">
                    <span className="caretRight">
                      <CaretRight />
                    </span>

                    <span className="arrowRight">
                      <ArrowLeft />
                    </span>
                  </span>
                </button>

                <div className={getActiveClassNames(itinerary, '')} key={itinerary.name} />
              </div>
            </div>
          );
        })}
      </div>

      <div
        aria-controls={`destinationFilterRegions destinationFilterType`}
        className={mobileItinerariesClassNames}
        onKeyDown={(e) => handleKeyDownItinerariesAndPorts(e)}
        ref={itinerariesContainerRef}
      >
        <FormGroup className="Itineraries" role="tablist" tabIndex={-1}>
          {toggleValue === TOGGLE_VALUES.ITINERARIES && Array.isArray(checkboxRenderData)
            ? checkboxRenderData.map((itinerary, index) => (
                <Itinerary
                  checked={itinerary.checked}
                  className={itinerary.className}
                  code={itinerary.code}
                  description={itinerary.description}
                  id={index === 0 ? 'firstElementOfPortsOrItineraries' : `dest_itinerary_${itinerary.code}`}
                  imageUrl={itinerary.imageUrl}
                  isNewFilter
                  key={itinerary.code}
                  name={itinerary.name}
                  onChange={itinerary.onChange}
                  portsOfCall={portsOfCall}
                  tabIndex={index === 0 ? 0 : -1}
                  toggleValue={toggleValue}
                />
              ))
            : Object.entries(checkboxRenderData || {}).map(([name, itineraries], countryIndex) => (
                <React.Fragment key={name}>
                  {name && <div className="countryName">{name}</div>}
                  {itineraries.map((itinerary, index) => (
                    <Itinerary
                      checked={itinerary.checked}
                      className={itinerary.className}
                      code={itinerary.code}
                      description={itinerary.description}
                      id={
                        countryIndex === 0 && index === 0
                          ? 'firstElementOfPortsOrItineraries'
                          : `dest_port_${itinerary.code}`
                      }
                      imageUrl={itinerary.imageUrl}
                      isNewFilter
                      key={itinerary.code}
                      name={itinerary.name}
                      onChange={itinerary.onChange}
                      portsOfCall={portsOfCall}
                      tabIndex={countryIndex === 0 && index === 0 ? 0 : -1}
                      toggleValue={toggleValue}
                    />
                  ))}
                </React.Fragment>
              ))}
        </FormGroup>
      </div>
    </section>
  );
};

export default DestinationDetails;
