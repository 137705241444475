import type { RootState } from '@/store';

import { BookingChannelTypes } from '@/constants/settings';

export const selectAgentData = (state: RootState) => state.agentData;

export const selectAgencyCurrencyCode = (state: RootState) => selectAgentData(state)?.agencyCurrencyCode;

export const selectAgencyDetails = (state: RootState) => selectAgentData(state)?.agencyDetails;

export const selectAgentDetails = (state: RootState) => selectAgentData(state)?.agentDetails;

export const selectAgentCurrencyCode = (state: RootState) => selectAgentDetails(state)?.currency;

export const selectAgentBookingChannel = (state: RootState) => selectAgentDetails(state)?.bookingChannel;

export const selectFilterAgencyIsAvailable = (state: RootState) => {
  const agencyDetails = selectAgencyDetails(state);
  const agentDetail = selectAgentDetails(state);
  return (
    Boolean(agencyDetails?.agencyId || agentDetail?.agentId) &&
    agentDetail?.bookingChannel === BookingChannelTypes.fmLink
  );
};
