import { useCallback } from 'react';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';

import UIResource from '@/components/UIResource';
import Button from '@/components/elements/Button';
import { resetFiltersForPackages } from '@/ducks/pages/chooseVoyage';
import { useAppDispatch, useAppSelector } from '@/store';

type Props = {
  renderPackage: (e: FilteredPackage) => React.ReactNode;
};

const DefaultZeroResults = ({ renderPackage }: Props) => {
  const defaultPackages = useAppSelector((state) => state?.chooseVoyageNew?.mainPackages?.defaultPackages);
  const dispatch = useAppDispatch();
  const resetAll = useCallback(() => dispatch(resetFiltersForPackages()), [dispatch]);

  return (
    <>
      <p className="ZeroResultsDescription">
        <UIResource id="ChooseVoyage.ZeroResults.message" />
      </p>
      <p className="ZeroResultsReset">
        <Button onClick={resetAll} variant="link">
          <UIResource id="ResultRefinements.resetAll" />
        </Button>
      </p>
      {!!defaultPackages?.length && (
        <>
          <h3 className="ZeroResultsDefaultVoyagesHeading">
            <UIResource id="ChooseVoyage.Discover.heading" />
          </h3>
          <div className="ZeroResultsDefaultVoyages">{defaultPackages?.map(renderPackage)}</div>
        </>
      )}
    </>
  );
};

export default DefaultZeroResults;
