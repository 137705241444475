import { useMemo } from 'react';

import { useGetBFResources } from '@/ducks/programs/blackFriday/hooks/useGetBFResources';

const useBlackFridayCountdown = () => {
  const { countdown: endTime, countdownClockToggle: isEnabled } = useGetBFResources();
  return useMemo(() => ({ endTime, isEnabled }), [endTime, isEnabled]);
};

export default useBlackFridayCountdown;
