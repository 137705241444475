import type { ChangeEvent, UIEvent } from 'react';

import { useUIResourcePool } from '@/ducks/common/resources';
import { DESTINATION_TYPE } from '@/ducks/pages/chooseVoyage/destinationSelectors';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import './Toggle.scss';

type ToggleProps = { id: string; onChange: () => void; value: string };

const Toggle = ({ id, onChange, value }: ToggleProps) => {
  const { itinerariesLabel, portsLabel } = useUIResourcePool({
    itinerariesLabel: 'RegionRefinements.itineraries',
    portsLabel: 'RegionRefinements.port',
  });

  const handleChange = (e: ChangeEvent | UIEvent) => {
    e.preventDefault();
    onChange();
  };

  const ontoggleKeyDown = useOnKeyDown(onChange, { isArrowKeysAllowed: true });

  return (
    <div className="toggleSwitch" id={id} onClick={handleChange} onKeyDown={ontoggleKeyDown} tabIndex={0}>
      <div className="can-toggle can-toggle-rebrand">
        <input
          aria-hidden="true"
          checked={value === DESTINATION_TYPE.PORTS_OF_CALL}
          onChange={handleChange}
          tabIndex={-1}
          type="checkbox"
        />
        <label htmlFor={id}>
          <div
            aria-hidden="true"
            className="can-toggle__switch"
            data-checked={portsLabel}
            data-unchecked={itinerariesLabel}
          />
        </label>
      </div>
      <div aria-live="assertive" className="sr-only" role="status">
        Destination selector, {value === DESTINATION_TYPE.PORTS_OF_CALL ? portsLabel : itinerariesLabel} selected
      </div>
    </div>
  );
};

export default Toggle;
