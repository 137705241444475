import Image from 'next/image';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';

import classNames from 'classnames';
import get from 'lodash/get';
import noop from 'lodash/noop';

import { FormCheck } from '@/components/elements';
import { DESTINATION_TYPE } from '@/ducks/pages/chooseVoyage/destinationSelectors';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import './Itinerary.scss';

/**
 * Itinerary select component
 * @section Elements
 */

export const propTypes = {
  /** Is itinerary selected */
  checked: PropTypes.bool,
  /** className */
  className: PropTypes.string,
  /** Itinerary code */
  code: PropTypes.string.isRequired,
  /** Itinerary description */
  description: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.string]).isRequired,
  /** disabled */
  disabled: PropTypes.bool,
  /** Itinerary image */
  imageUrl: PropTypes.shape({}),
  isNewFilter: PropTypes.bool,
  /** Itinerary name */
  name: PropTypes.string.isRequired,

  /** Change handler */
  onChange: PropTypes.func,
  tabIndex: PropTypes.number,
};

const defaultProps = {
  checked: false,
  className: null,
  disabled: false,
  imageUrl: {
    src: '',
  },
  isNewFilter: false,
  onChange: noop,
  tabIndex: 0,
};

const Itinerary = ({
  ariaControls,
  checked,
  className,
  code,
  description,
  disabled,
  id,
  imageUrl = {},
  isNewFilter,
  name,
  onChange,
  tabIndex,
  toggleValue,
}) => {
  const cssClasses = classNames({
    '-disabled': disabled,
    Itinerary: true,
    [className]: !!className,
    enableBottom: isNewFilter,
    isNewFilter: toggleValue === DESTINATION_TYPE.PORTS_OF_CALL,
  });
  const { src } = imageUrl;
  const listItemRef = useRef(null);
  const handleOnClick = (event) => {
    if (isNewFilter) {
      const targetInput = event.currentTarget.querySelector('input');
      if (targetInput) {
        let state = get(targetInput, 'checked', false);
        if (event.key) {
          // ADA // manually change state as it stale // https://virginvoyages.atlassian.net/browse/MSH-110235?focusedCommentId=902271
          state = !checked;
        }

        onChange({
          currentTarget: {
            checked: state,
            id: get(targetInput, 'id', ''),
          },
        });
      }
    }
  };

  const handleKeyDown = useOnKeyDown(handleOnClick, { isDefaultPrevented: true });

  const labelId = `itinerary${id}_label`;
  const checkStatusId = `itinerary${id}_checked`;

  return (
    <div
      aria-controls={ariaControls}
      aria-labelledby={`${labelId} ${checkStatusId}`}
      className={cssClasses}
      id={id}
      onClick={handleOnClick}
      onKeyDown={(event) => handleKeyDown(event)}
      ref={listItemRef}
      role="tab"
      tabIndex={tabIndex}
    >
      <FormCheck
        aria-label="select an itinerary to filter the results"
        checked={checked}
        disabled={disabled}
        id={code}
        onChange={!isNewFilter ? onChange : null}
        tabIndex={-1}
        type="checkbox"
        value={code}
      >
        {!isNewFilter && (
          <div className="Itinerary__row" id={labelId}>
            <div className="Itinerary__textSection">
              <div className="Itinerary__name">{name}</div>
              <div className="Itinerary__description">{description}</div>
            </div>
          </div>
        )}
        {isNewFilter && (
          <div id={labelId} style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} tabIndex={-1}>
            <div className="Itinerary__row isNewFilter" tabIndex={-1}>
              <div className="Itinerary__textSection isNewFilter">
                <div className="Itinerary__name isNewFilter">{name}</div>
                <div className="Itinerary__description isNewFilter">{description}</div>
              </div>
            </div>
            <div className="Itinerary__image isNewFilter">
              {src && <Image alt="" fill sizes="100vw, 100vh" src={src} />}
            </div>
          </div>
        )}
        {!isNewFilter && (
          <div className="Itinerary__image">{src && <Image alt="" fill sizes="100vw, 100vh" src={src} />}</div>
        )}

        <div class="sr-only" id={checkStatusId}>
          {checked ? 'Selected' : 'Not selected'}
        </div>
      </FormCheck>
    </div>
  );
};

Itinerary.propTypes = propTypes;

Itinerary.defaultProps = defaultProps;

export default Itinerary;
