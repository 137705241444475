import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import { useMainFilterHandlers } from '@/components/ChooseVoyage/MainFilter/hooks';
import FilterOption from '@/components/Icon/FilterOption';
import DateRefinement from '@/components/ResultRefinements/DateRefinement';
import DestinationRefinement from '@/components/ResultRefinements/DestinationRefinement';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { FormattedMessage } from '@/helpers/formatted-message';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import './MainFilter.scss';

const propTypes = {
  mgm: PropTypes.shape({
    isReferredBooker: PropTypes.bool.isRequired,
  }).isRequired,
  onOpenFilterModal: PropTypes.func,
};

const defaultProps = {
  toggleAdvanceRefinementFilterFlyout: () => {},
};

const MainFilter = ({ mgm, onOpenFilterModal }) => {
  const onOpenFilterModalWithKeyDown = useOnKeyDown(onOpenFilterModal, { isDefaultPrevented: true });
  const { dateFilterOpened, destinationFilterOpened, setDateFilterOpenedHandler, setDestinationFilterOpenedHandler } =
    useMainFilterHandlers();

  return (
    <div className="NewResultRefinements">
      <div className="NewResultRefinements__newFilterPrimaryRow" id="ResultRefinements__newFilterPrimaryRow">
        <div className="NewResultRefinements__newFilterContainer" id="ResultRefinements__newFilterContainer">
          <div className="primaryContainer" id="primaryContainer" tabIndex={0}>
            <DestinationRefinement
              filterOpened={destinationFilterOpened}
              onRenderChange={setDestinationFilterOpenedHandler}
            />
            <DateRefinement
              disabled={mgm.isReferredBooker}
              filterOpened={dateFilterOpened}
              isChooseVoyageNew
              onRenderChange={setDateFilterOpenedHandler}
            />
          </div>
          <div
            className="secondaryContainer"
            onClick={onOpenFilterModal}
            onKeyDown={onOpenFilterModalWithKeyDown}
            role="button"
            tabIndex="0"
          >
            <span className="filtersIconTitle">
              <FormattedMessage defaultMessage="Filters" id="ResultRefinements.filters" />
            </span>
            <FilterOption aria-label="Filter Option" />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  mgm: selectMgm(state),
});

MainFilter.defaultProps = defaultProps;
MainFilter.propTypes = propTypes;

export default connect(mapStateToProps)(MainFilter);
