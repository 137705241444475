import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import type { TPriceType } from '@/infra/types/common/base';

import PRICE_TYPES from '@/constants/priceTypes';
import { selectDefaultFilterPriceType } from '@/ducks/filtersOptions';
import { useAppSelector } from '@/store';

const usePriceType = (): TPriceType => {
  const defaultPriceType = useAppSelector(selectDefaultFilterPriceType);
  const searchParams = useSearchParams();
  const priceType = searchParams.get('priceType');

  return useMemo(
    () => (priceType && Object.values(PRICE_TYPES).includes(priceType) ? priceType : defaultPriceType),
    [defaultPriceType, priceType],
  );
};

export default usePriceType;
