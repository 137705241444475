import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { useIsMobile } from '@/hooks/useIsDevice';

const Grabable = ({ children, elementProps, htmlElement: Parent, rowHtmlElement: Row }) => {
  const { className, ...props } = elementProps;
  const initialPosition = useRef({});
  const parentRef = useRef();
  const rowRef = useRef();
  const isMovementAllowed = useRef(false);
  const isMovementRegistered = useRef(false);

  const isChildrenEmpty = children.filter((c) => c.type(c.props) !== null).length === 0;

  const onMouseMove = useCallback((event) => {
    if (!isMovementAllowed.current) {
      return;
    }
    const clientX = event.clientX ?? event.touches?.[0]?.clientX;
    const clientY = event.clientY ?? event.touches?.[0]?.clientY;
    const dx = clientX - initialPosition.current.x;
    const dy = clientY - initialPosition.current.y;
    rowRef.current.scrollLeft = initialPosition.current.left - dx;
    rowRef.current.scrollTop = initialPosition.current.top - dy;
    isMovementRegistered.current = true;
  }, []);

  const onMouseDown = useCallback((event) => {
    isMovementRegistered.current = false;
    isMovementAllowed.current = true;
    event.stopImmediatePropagation();
    initialPosition.current = {
      left: rowRef.current.scrollLeft,
      top: rowRef.current.scrollTop,
      x: event.clientX ?? event.touches?.[0]?.clientX,
      y: event.clientY ?? event.touches?.[0]?.clientY,
    };
  }, []);

  const onClick = useCallback((event) => {
    if (isMovementRegistered.current) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }, []);

  const onMouseUp = useCallback(() => {
    isMovementAllowed.current = false;
  }, []);

  const isMobileDevice = useIsMobile();
  useEffect(() => {
    if (rowRef?.current) {
      rowRef.current.addEventListener(isMobileDevice ? 'touchstart' : 'mousedown', onMouseDown);
      rowRef.current.addEventListener(isMobileDevice ? 'touchend' : 'mouseup', onMouseUp);
      rowRef.current.addEventListener(isMobileDevice ? 'touchmove' : 'mousemove', onMouseMove);
      rowRef.current.addEventListener('click', onClick);
      if (!isMobileDevice) {
        rowRef.current.addEventListener('mouseleave', onMouseUp);
      }
    }

    return () => {
      if (rowRef?.current) {
        rowRef.current.removeEventListener(isMobileDevice ? 'touchstart' : 'mousedown', onMouseDown);
        rowRef.current.removeEventListener(isMobileDevice ? 'touchend' : 'mouseup', onMouseUp);
        rowRef.current.removeEventListener(isMobileDevice ? 'touchmove' : 'mousemove', onMouseMove);
        rowRef.current.removeEventListener('click', onClick);
        if (!isMobileDevice) {
          rowRef.current.removeEventListener('mouseleave', onMouseUp);
        }
      }
    };
  }, [isChildrenEmpty]);

  return isChildrenEmpty ? null : (
    <Parent {...props} className={classNames('draggable', { [className]: !!className })} ref={parentRef}>
      <Row className="row" ref={rowRef}>
        {children}
      </Row>
    </Parent>
  );
};

Grabable.propTypes = {
  children: [PropTypes.node],
  elementProps: PropTypes.shape({
    className: PropTypes.string,
  }),
  htmlElement: PropTypes.string,
  rowHtmlElement: PropTypes.string,
};

Grabable.defaultProps = {
  children: null,
  elementProps: {},
  htmlElement: 'div',
  rowHtmlElement: 'div',
};

export default Grabable;
