import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import { useMainFilterHandlers } from '@/components/ChooseVoyage/MainFilter/hooks';
import DateRefinement from '@/components/ResultRefinements/DateRefinement';
import DestinationRefinement from '@/components/ResultRefinements/DestinationRefinement';
import UIResource from '@/components/UIResource';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
const FilterRefinementMobileView = ({ mgm, renderMainFilter, sailingsCount, toggleFilterState }) => {
  const { dateFilterOpened, destinationFilterOpened, setDateFilterOpenedHandler, setDestinationFilterOpenedHandler } =
    useMainFilterHandlers({
      onDateFilterStatusChange: toggleFilterState('date'),
      onDestinationFilterStatusChange: toggleFilterState('destination'),
    });

  return (
    <section className="filterRefinementMobileView">
      <div className="filterRefinementMobileView__title">
        <UIResource id="ChooseVoyage.Results.heading" values={{ N: sailingsCount }} />
      </div>
      <div className="filterRefinementMobileView__mainFilter">
        <DestinationRefinement
          filterOpened={destinationFilterOpened}
          onRenderChange={setDestinationFilterOpenedHandler}
          renderMainFilter={renderMainFilter}
        />
        <DateRefinement
          disabled={mgm.isReferredBooker}
          filterOpened={dateFilterOpened}
          isChooseVoyageNew
          onRenderChange={setDateFilterOpenedHandler}
          renderMainFilter={renderMainFilter}
        />
      </div>
    </section>
  );
};

FilterRefinementMobileView.propTypes = {
  mgm: PropTypes.shape({
    isReferredBooker: PropTypes.bool.isRequired,
  }).isRequired,
  renderMainFilter: PropTypes.func,
  sailingsCount: PropTypes.number,
};

FilterRefinementMobileView.defaultProps = {
  renderMainFilter: () => {},
};

function mapStateToProps(state) {
  return {
    mgm: selectMgm(state),
    sailingsCount: state?.cruisesCommon?.voyageData?.sailingsCount,
  };
}

export default connect(mapStateToProps)(FilterRefinementMobileView);
