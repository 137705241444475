import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import CabinTypeFilter from '@/components/AdvancedFilterRefinement/CabinTypeFilter';
import { selectCabinType } from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import { setCabinType as setCabinTypeAction } from '@/ducks/pages/chooseVoyage';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { FormattedMessageContext } from '@/helpers/formatted-message';

import './CabinTypeRefinement.scss';

class CabinTypeRefinement extends React.Component {
  componentDidUpdate(prevProps) {
    const { appliedType } = this.props;
    if (prevProps.appliedType !== appliedType) {
      this.onRenderChange(false);
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedType: null,
    };
  }

  onCabinTypeChange = (value) => {
    const { setCabinType, trackCabinType } = this.props;
    const { formatMessage } = this.context;
    let defaultVal = formatMessage({
      defaultMessage: 'Any — the best available',
      id: 'AdvancedFilter.cabintype.Default.value',
    });
    [defaultVal] = defaultVal;
    const newState = value.trim() === defaultVal.trim() ? null : value;

    this.setState({ selectedType: newState });
    setCabinType(newState, newState || undefined);
    trackCabinType(newState || defaultVal);
  };

  onRenderChange = () => {
    const { appliedType } = this.props;
    this.setState({ selectedType: appliedType });
  };

  render() {
    const { selectedType } = this.state;
    const { cabinTypes, closeFilterModal, isOpenFilterSection, toggleFilterState } = this.props;

    return (
      <CabinTypeFilter
        cabinTypes={cabinTypes}
        closeFilterModal={closeFilterModal}
        isOpenFilterSection={isOpenFilterSection}
        onCabinTypeChange={this.onCabinTypeChange}
        onRenderChange={this.onRenderChange}
        selectedType={selectedType}
        toggleFilterState={toggleFilterState}
      />
    );
  }
}

CabinTypeRefinement.propTypes = {
  appliedType: PropTypes.string,
  cabinTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeFilterModal: PropTypes.func,
  setCabinType: PropTypes.func.isRequired,
  toggleView: PropTypes.bool,
  trackCabinType: PropTypes.func,
};
CabinTypeRefinement.defaultProps = {
  appliedType: null,
  closeFilterModal: () => {},
  toggleView: false,
  trackCabinType: () => {},
};

CabinTypeRefinement.contextType = FormattedMessageContext;

const mapStateToProps = (state) => ({
  appliedType: selectCabinType(state),
  cabinTypes: getFiltersOptions(state).cabinTypes,
});
const mapDispatchToProps = {
  setCabinType: setCabinTypeAction,
  trackCabinType: tracking.trackCabinType,
};

export default connect(mapStateToProps, mapDispatchToProps)(CabinTypeRefinement);
