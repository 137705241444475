import Warning from '@publicImages/svg/Shape.svg?icon';
import Alert from '@publicImages/svg/alert.svg?icon';

import { getSessionStorageValue } from '@/helpers/util/storage';

import Notification from '..';

type Props = {
  errors: Array<React.ReactNode>;
  heading?: string;
  id?: string;
};

const NotificationError = ({ errors, heading, id }: Props) => {
  if (!errors) {
    return null;
  }

  const invalidErrorMessage = getSessionStorageValue('paymentEmailInvalidError');
  return (
    <Notification error heading={heading} icon={invalidErrorMessage ? <Warning /> : <Alert />} id={id}>
      <ul className="Notification__errors" role="alert">
        {errors.map((error) => (
          <li key={String(error)}>{error}</li>
        ))}
      </ul>
    </Notification>
  );
};

export default NotificationError;
