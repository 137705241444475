import React, { useCallback } from 'react';

import classNames from 'classnames';

import ArrowLeft from '@/components/Icon/ArrowLeft';
import FilterDestination from '@/components/Icon/filterDestination';
import { FormCheck } from '@/components/elements';
import Button from '@/components/elements/Button';
import { DESTINATION_TYPE as TOGGLE_VALUES } from '@/ducks/pages/chooseVoyage/destinationSelectors';
import { FormattedMessage } from '@/helpers/formatted-message';
import useOnKeyDown from '@/hooks/useOnKeyDown';

const DestinationHeader = ({
  count,
  getActiveClassNames,
  isCheckSelected,
  isRegionSelected,
  regionId,
  selectedRegion,
  showItineraries,
  toggleItineraries,
  toggleRegions,
  toggleValue,
}) => {
  const headerClasses = classNames({
    DestinationRefinement__Header: true,
    reSizeHeight: showItineraries,
  });

  const destinationTitleClasses = classNames({
    destinationTitle: true,
  });

  const mobileRegionTitleClasses = classNames({
    mobileRegionTitle: true,
    toggle: showItineraries,
  });

  const goBackToRegion = useCallback(() => {
    toggleItineraries(false);
    toggleRegions(false);
  }, [toggleItineraries, toggleRegions]);

  const regionName = selectedRegion?.name || '';
  const itinerariesCount = count || '0';
  const onKeyDownSelectAllButton = useOnKeyDown(isRegionSelected({ regionId, toItineraries: true }));

  return (
    <section className={headerClasses}>
      <div className="filterDescription">
        <div className={destinationTitleClasses}>
          <span className="icon">
            <FilterDestination />
          </span>
          <span className="title">
            <FormattedMessage defaultMessage="Destinations" id="DestinationRefinement.Book" />
          </span>
        </div>
        <div className={mobileRegionTitleClasses}>
          <span className="regionTitleMobile">{regionName}</span>
          <Button
            aria-label="Go back"
            className="filterIcon"
            disableBtnClass
            onClick={goBackToRegion}
            tabIndex={showItineraries ? 0 : -1}
          >
            <ArrowLeft />
          </Button>
          <div
            className={getActiveClassNames({ id: regionId }, 'allItineraryCheck')}
            onClick={isRegionSelected({ regionId, toItineraries: true })}
            onKeyDown={onKeyDownSelectAllButton}
          >
            <FormCheck
              checked={isCheckSelected(regionId)}
              id={`ALL-${regionId}`}
              name="allCheck"
              onChange={(event) => isRegionSelected({ regionId, toItineraries: true })(event)}
              tabIndex={showItineraries ? 0 : -1}
              type="checkbox"
            >
              <span className="allItineraryCheckText">
                <FormattedMessage
                  defaultMessage="Select all {count} {toggleValue}"
                  id="Destination.allCheckbox"
                  values={{
                    count: itinerariesCount,
                    toggleValue,
                  }}
                />
              </span>
            </FormCheck>
          </div>
        </div>
        <div className="regionTitle">
          <FormattedMessage defaultMessage="Regions" id="Book CTA" />
        </div>
        <div className="itinerariesTitle">
          {`${itinerariesCount} ${regionName} `}
          {toggleValue === TOGGLE_VALUES.ITINERARIES ? (
            <FormattedMessage defaultMessage="Itineraries" id="Destination.itineraries" />
          ) : (
            <FormattedMessage defaultMessage="Ports-of-call" id="Destination.ports" />
          )}
        </div>
      </div>
    </section>
  );
};

export default DestinationHeader;
