import { forwardRef } from 'react';
import type { ComponentType, PropsWithChildren } from 'react';

import cn from 'classnames';

import type { Package } from '@/infra/types/voyageInfo/package';

import { useUIResourcePool } from '@/ducks/common/resources';
import useIsBlackFridaySailing from '@/ducks/programs/blackFriday/hooks/useIsBlackFridaySailing';

import { useBarTabAmount } from '../hooks';

import './PackageCard.scss';

export type TProps = {
  duration: number;
};

const UI_RESOURCE_POOL = {
  barTabText: 'BlackFriday.voyageCardBanner.BarTab',
  saleTitle: 'BlackFriday.voyageCardBanner.Title',
} as const;

const SaleBadge = ({ duration }: TProps) => {
  const barTab = useBarTabAmount(duration);
  const { barTabText, saleTitle } = useUIResourcePool(UI_RESOURCE_POOL);

  const isBarTabDynamic = barTabText?.includes('{N}');

  // Context on business requirements on MSH-113559
  let displayMessage = null;
  if (barTabText) {
    if (barTab) {
      displayMessage = barTabText.replace('{N}', barTab);
    } else if (!isBarTabDynamic) {
      displayMessage = barTabText;
    }
  }

  return (
    <div className="PackageCard__badge">
      {displayMessage && <div className="barTab">{displayMessage}</div>}
      <div className="sale">{saleTitle}</div>
    </div>
  );
};

export type TSaleBadgeHostProps = PropsWithChildren<{
  className?: string;
  packageData: Package;
}>;

export const withSaleBadge = <P,>(Component: ComponentType<TSaleBadgeHostProps & P>) => {
  const SaleBadgeWrapper = forwardRef<typeof Component, TSaleBadgeHostProps & P>(
    ({ children, className, packageData, ...props }, ref) => {
      const isSale = useIsBlackFridaySailing(packageData.sailingList);
      return (
        <Component
          className={cn(isSale && 'sale', packageData?.isPortChangeAvailable && 'portChange', className)}
          packageData={packageData}
          ref={ref}
          {...(props as P)}
        >
          {children}
          {isSale && <SaleBadge duration={packageData.duration} />}
        </Component>
      );
    },
  );
  SaleBadgeWrapper.displayName = 'SaleBadgeWrapper';
  return SaleBadgeWrapper as React.ForwardRefExoticComponent<P>;
};
