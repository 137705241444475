// import { resetFilters } from '@/ducks/filters/setters';
// import { FILTER_KEY } from '@/ducks/filters/types';
import { routes } from '@/ducks/routes';

import { removeAgencyData } from './setters';

export const resetFmFlow = () => () => {
  //   routes.searchWithout(['agencyId', 'agentId']);
  //   removeAgencyData();
  //   resetFilters(FILTER_KEY.currencyCode);

  routes.searchWithout(['agencyId', 'agentId', 'currencyCode']);
  removeAgencyData();
};
