import { useMemo } from 'react';

import isNil from 'lodash/isNil';

import { useGetBFResources } from '@/ducks/programs/blackFriday/hooks';

export type TPromotionAddon = {
  max?: number;
  min?: number;
  name: string;
};

export const useBarTabAmount = (duration: number): string => {
  const { promotionAddons } = useGetBFResources();
  return useMemo(
    () =>
      promotionAddons?.find(
        ({ max, min }: TPromotionAddon) => (isNil(min) || duration >= min) && (isNil(max) || duration <= max),
      )?.name,
    [duration, promotionAddons],
  );
};
