import UIResource from '@/components/UIResource';
import { resetMnvvFlowSearchCriteria } from '@/ducks/mnvv/reset';
import { resetFiltersForPackages } from '@/ducks/pages/chooseVoyage';
import { useAppDispatch } from '@/store';

import TrueZeroResults from './TrueZeroResults';

const MNVVTrueZeroResults = () => {
  const dispatch = useAppDispatch();

  const resetFilters = () => {
    dispatch(resetFiltersForPackages());
  };

  const clearAllFilters = () => {
    resetMnvvFlowSearchCriteria();
    resetFilters();
  };

  return (
    <TrueZeroResults
      description={<UIResource id="ChooseVoyage.ZeroResults.MNVVheading" />}
      emoji={<UIResource id="ChooseVoyage.ZeroResults.MNVVemojis" />}
      firstButtonText={<UIResource id="ChooseVoyage.ZeroResults.MNVVprimaryCTA" />}
      onFirstButtonClick={resetFilters}
      onSecondButtonClick={clearAllFilters}
      secondButtonText={<UIResource id="ChooseVoyage.ZeroResults.MNVVsecondaryCTA" />}
    />
  );
};

export default MNVVTrueZeroResults;
