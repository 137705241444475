import type { CookieChangeListener } from 'universal-cookie';

import { useEffect, useState } from 'react';

import type { TOptional } from '@/types/common';

import { cookies } from './';

const parseIfPossible = (value: string) => {
  try {
    return JSON.parse(value);
  } catch {
    // Non JSON value
    return value;
  }
};

export const useCookieValue = <R = unknown>(cookieName: string): TOptional<R> => {
  const [value, setValue] = useState(() => parseIfPossible(cookies.get(cookieName)));

  useEffect(() => {
    const listener: CookieChangeListener = ({ name, value }) => {
      if (cookieName === name) {
        setValue(parseIfPossible(value));
      }
    };
    cookies.addChangeListener(listener);
    return () => cookies.removeChangeListener(listener);
  }, [cookieName]);

  return value;
};
