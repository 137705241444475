import { useCurrencyCode } from '@/ducks/currencyCode';
import { getTaxInclusive } from '@/helpers/data/mappers/Common';
import { VWOFlag, useVWOFlag } from '@/hooks/useVWOFeature';

const useIsTaxIncluded = () => {
  const { currencyCode } = useCurrencyCode();
  const isTaxesAndFeesFeatureEnabled = useVWOFlag(VWOFlag.TAXES_AND_FEES);
  return getTaxInclusive(currencyCode, isTaxesAndFeesFeatureEnabled);
};

export default useIsTaxIncluded;
