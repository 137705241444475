import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import BaseFilter from '@/components/AdvancedFilterRefinement/BaseFilter';
import { selectLookup } from '@/ducks/common/selectors';
import { selectDeparturePorts } from '@/ducks/filters/selectors';
import { setDeparturePortsForPackages as setDeparturePortsActionForPackages } from '@/ducks/pages/chooseVoyage';
import {
  getItineraryFilters,
  setAppliedFilterLabels as setAppliedFilterLabelsAction,
} from '@/ducks/pages/chooseVoyage1.0';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { getLabelforPorts } from '@/helpers/util';

import { FormCheck } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';

class DeparturePortFilter extends BaseFilter {
  checkIfDisabled = (port) => {
    const { portsOfRegion } = this.state;
    const { mainPackageSailings } = this.props;
    let newSailings = [];
    newSailings = mainPackageSailings.filter((s) => s.ports.some((p) => p.code === port.code));

    if (portsOfRegion && portsOfRegion.length > 0) {
      const portData = portsOfRegion.find((p) => p.code === port.code);
      return !(portData || newSailings.length > 0);
    }
    return !(newSailings.length > 0);
  };

  getDefaultPortTitle = () => {
    const { formatMessage } = this.context;
    const title = formatMessage({
      defaultMessage: 'Any Port',
      id: 'AdvancedFilter.Port.Default.value',
    });
    return title;
  };

  onSelection = (port) => {
    const { selectedDeparturePorts, setDeparturePortsForPackages } = this.props;
    const selected = selectedDeparturePorts.find((currPort) => currPort.code === port.code);

    if (!selected) {
      setDeparturePortsForPackages([...selectedDeparturePorts, port]);
      return;
    }
    const homePorts = selectedDeparturePorts.filter((selecPort) => selecPort.code !== port.code);
    setDeparturePortsForPackages(homePorts);
  };

  updateFilterLabel = () => {
    const { labels, selectedDeparturePorts, setAppliedFilterLabels } = this.props;
    const { formatMessage } = this.context;
    const title = getLabelforPorts(selectedDeparturePorts, formatMessage);
    const portTitle = title || this.getDefaultPortTitle();
    this.setState({ label: portTitle });
    if (labels.port !== title) {
      setAppliedFilterLabels({ port: title });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      label: '',
      portsOfRegion: [],
    };
  }

  componentDidMount() {
    const { itineraryFilters } = this.props;
    const portsOfRegion = itineraryFilters.filter((filter) => filter.isApplied > 0);
    if (!isEmpty(portsOfRegion)) {
      const { ports } = portsOfRegion.reduce(
        (acc, region) => ({
          ports: [...acc.ports, ...region.ports],
        }),
        { ports: [] },
      );
      this.setState({ portsOfRegion: ports });
    }
    this.updateFilterLabel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.selectedDeparturePorts !== this.props?.selectedDeparturePorts) {
      this.updateFilterLabel();
    }
  }

  render() {
    const { label } = this.state;
    const { closeFilterModal, isOpenFilterSection, metaDeparturePorts, selectedDeparturePorts } = this.props;
    const { formatMessage } = this.context;
    const departurePort = formatMessage({
      defaultMessage: 'Departure Port',
      id: 'AdvancedFilter.DeparturePort',
    });
    return (
      <div className="AF__row DeparturePortFilter__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          contentName="departure-port"
          defaultValue={label}
          filterName={departurePort}
          isDeparturePort
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="port-list action">
            {metaDeparturePorts &&
              metaDeparturePorts.map((port) => (
                <FormCheck
                  checked={!isEmpty(selectedDeparturePorts?.find((dp) => dp?.code === port?.code))}
                  disabled={this.checkIfDisabled(port)}
                  id={`port_${port.code}`}
                  inputTabIndex={0}
                  key={port.code}
                  name={`port_${port.name}`}
                  onChange={() => {
                    this.onSelection(port);
                  }}
                  role="presentation"
                  tabIndex={-1}
                  type="checkbox"
                >
                  <p className="name">{port.name}</p>
                  <p className="location">{port.countryName}</p>
                </FormCheck>
              ))}
          </div>
        </AdvancedFilterHOC>
      </div>
    );
  }
}
DeparturePortFilter.propTypes = {
  closeFilterModal: PropTypes.func.isRequired,
  isOpenFilterSection: PropTypes.bool,
  itineraryFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mainPackageSailings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  metaDeparturePorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedDeparturePorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAppliedFilterLabels: PropTypes.func.isRequired,
  setDeparturePortsForPackages: PropTypes.func.isRequired,
};

DeparturePortFilter.defaultProps = {
  isOpenFilterSection: false,
};

DeparturePortFilter.contextType = FormattedMessageContext;

const mapStateToProps = (state) => ({
  itineraryFilters: getItineraryFilters(state),
  labels: get(state, 'appliedfilterLabels', {}),
  mainPackageSailings: get(state, 'chooseVoyageNew.mainPackages.sailings', []),
  metaDeparturePorts: selectLookup(state)?.homePorts ?? [],
  selectedDeparturePorts: selectDeparturePorts(state),
});
const mapDispatchToProps = {
  setAppliedFilterLabels: setAppliedFilterLabelsAction,
  setDeparturePortsForPackages: setDeparturePortsActionForPackages,
};

export default connect(mapStateToProps, mapDispatchToProps)(DeparturePortFilter);
