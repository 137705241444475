import React, { useContext, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { selectShipList } from '@/ducks/filters/selectors';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { getLabelforShips } from '@/helpers/util/misc';
import { FilterKey } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const PortFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const shipList = useAppSelector(selectShipList);
  const { formatMessage } = useContext(FormattedMessageContext);

  const label = useMemo(() => {
    return isEmpty(shipList) ? null : getLabelforShips(shipList, formatMessage);
  }, [shipList, formatMessage]);

  return label ? (
    <SelectedFilterChip filterKey={FilterKey.SHIP} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default PortFilterChip;
