import PropTypes from 'prop-types';
import React from 'react';

import { getMonth, getYear, isAfter } from 'date-fns';
import { omit } from 'lodash';
import noop from 'lodash/noop';
import { connect } from 'react-redux';

import DatePicker from '@/components/DatePicker';
import Calendar from '@/components/Icon/Calendar';
import Filter from '@/components/elements/Filter';
import { selectLookup } from '@/ducks/common/selectors';
import { setFilterDates } from '@/ducks/filters';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { getDefaultFilters, getFiltersOptions } from '@/ducks/filtersOptions';
import { removeMultipleVoyageFilter } from '@/ducks/multipleVoyageFilter';
import { isMultipleVoyageFilterActive } from '@/ducks/pages/chooseVoyage/selectorDetails';
import { routes } from '@/ducks/routes';
import { getSearchParam } from '@/ducks/routes/history';
import { isServerSide } from '@/helpers/isServerSide';
import { scrollIntoViewAsync } from '@/helpers/scrollIntoView';
import { completeMonthLabel, currentNYTime, format, parse } from '@/helpers/util/dateUtil';
import { getMnvvReservation } from '@/helpers/util/misc';
import { trackResetFilters } from '@/helpers/util/tracking';
import withSearchParams from '@/hocs/withSearchParams';

import './DateRefinement.scss';

class DateRefinement extends React.Component {
  getPillLabelForChooseVoyageNew = () => {
    const { appliedEndDate, appliedStartDate, hideTitle } = this.props;
    const clNames = !hideTitle ? 'refinementText' : 'highlight';
    const startMonth = completeMonthLabel(appliedStartDate);
    const endMonth = completeMonthLabel(appliedEndDate);

    if (!startMonth || !endMonth) {
      return <></>;
    }
    return (
      <span className={clNames} suppressHydrationWarning>
        {startMonth}
        {' - '}
        {endMonth}
      </span>
    );
  };

  getPillState = () => {
    const { appliedEndDate, appliedStartDate, defaultEndDate, defaultStartDate, filterOpened } = this.props;
    const { isMobile } = this.state;
    const parentClass = isServerSide() ? null : document.getElementById('primaryContainer');
    if (filterOpened) {
      if (parentClass) {
        parentClass.classList.add('lightBorder');
      }
      return 'open';
    }
    if (!filterOpened) {
      if (parentClass) {
        parentClass.classList.remove('lightBorder');
      }
    }

    if (isMobile) {
      return 'arrow';
    }

    return appliedStartDate !== defaultStartDate || appliedEndDate !== defaultEndDate ? 'applied' : 'default';
  };

  handleResetIconClick = () => {
    const { defaultEndDate, defaultStartDate, setFilterDates } = this.props;
    setFilterDates(defaultStartDate, defaultEndDate);
  };

  isApplyDisabled = () => {
    const { reset, selectedEnd, selectedStart } = this.state;
    const { appliedEndDate, appliedStartDate, isMultipleVoyageFilter } = this.props;

    if (reset && isMultipleVoyageFilter) {
      return false;
    }
    return selectedStart === appliedStartDate && selectedEnd === appliedEndDate;
  };

  onDismiss = () => {};

  onRenderChange = (isFilterOpen) => {
    const { appliedEndDate, appliedStartDate, onRenderChange, setFilterOpened } = this.props;
    onRenderChange?.(isFilterOpen);
    setFilterOpened?.(isFilterOpen);
    this.setState({
      selectedEnd: appliedEndDate,
      selectedStart: appliedStartDate,
    });
  };

  onSelectMonth = (start, end) => {
    this.setState({
      selectedEnd: end,
      selectedStart: start,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { reset, selectedEnd, selectedStart } = this.state;
    const { isMultipleVoyageFilter, removeMultipleVoyageFilter, searchParams, setFilterDates } = this.props;
    const { isMNVV } = getMnvvReservation();
    const maxDate = getSearchParam('maxDate');

    const isSameYear =
      getYear(parse(selectedStart)) === getYear(parse(maxDate)) ||
      getYear(parse(selectedStart)) === getYear(parse(maxDate));
    const isSameMonth =
      getMonth(parse(selectedStart)) === getMonth(parse(maxDate)) ||
      getMonth(parse(selectedEnd)) === getMonth(parse(maxDate));
    const isSelectedDate = isMNVV && maxDate && isSameMonth && isSameYear;
    const selectedDate = !isSelectedDate ? selectedEnd : maxDate;
    if (reset && isMultipleVoyageFilter) {
      removeMultipleVoyageFilter();
      routes.planner.chooseVoyage.go(omit(Object.fromEntries(searchParams?.entries()), ['voyageId']));
    } else {
      setFilterDates(selectedStart, selectedDate);
    }
  };

  resetSelectedDates = () => {
    const { defaultEndDate, defaultStartDate } = this.props;
    this.setState({
      reset: true,
      selectedEnd: defaultEndDate,
      selectedStart: defaultStartDate,
    });

    trackResetFilters('date-range');
  };

  shResetDisplay = () => {
    const { appliedEndDate, appliedStartDate, defaultEndDate, defaultStartDate, isMultipleVoyageFilter } = this.props;
    if (isMultipleVoyageFilter) {
      return false;
    }
    const noDateSelection = appliedStartDate === undefined && appliedEndDate === undefined;
    if (noDateSelection) {
      return true;
    }
    if (!noDateSelection) {
      const isSelectedStartDateSameAsDefault = appliedStartDate === defaultStartDate;
      const isSelectedEndDateSameAsDefault = appliedEndDate === defaultEndDate;
      if (isSelectedStartDateSameAsDefault && isSelectedEndDateSameAsDefault) {
        return true;
      }
      return false;
    }
    return false;
  };

  updateMaxDate = (date) => {
    const { isMNVV } = getMnvvReservation();
    const mnvvMaxDate = getSearchParam('maxDate');
    const maxDate = parse(date);
    if (!isMNVV || !mnvvMaxDate) {
      return date;
    }
    if (mnvvMaxDate && isAfter(parse(mnvvMaxDate), maxDate)) {
      return format(maxDate);
    }
    return format(mnvvMaxDate);
  };

  constructor(props) {
    super(props);
    // Holds the selected date range locally, until submitted by the user
    this.state = {
      isMobile: false,
      reset: false,
      selectedEnd: null,
      selectedStart: null,
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  async componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    if (window.innerWidth <= 576) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  }

  onEvent = (...args) => {
    const locator = '.DateRefinement.-newFilter .FilterPopover.-newFilter';
    scrollIntoViewAsync(locator, undefined, { block: 'nearest' }, 300);
    this.props.onEvent?.(...args);
  };

  render() {
    const { applyNewFilterUI, disabled, filterOpened, maxDate, minDate, renderMainFilter } = this.props;
    const updatedMaxDate = this.updateMaxDate(maxDate);
    const { selectedEnd, selectedStart } = this.state;

    const pill = {
      disabled,
      headerIcon: <Calendar />,
      icon: !applyNewFilterUI && <Calendar />,
      label: this.getPillLabelForChooseVoyageNew(),
      onResetIconClick: this.handleResetIconClick,
      state: this.getPillState(),
      title: { defaultMessage: 'Travel Dates', id: 'DateRefinement.heading' },
      variant: 'large',
    };
    const popover = {
      ariaLabel: 'Select travel dates',
      bodyclass: '-refinementOpen',
      className: 'DateRefinement -newFilter',
      disabled,
      id: 'DateRefinement',
      isApplyDisabled: this.isApplyDisabled(),
      onDismiss: this.onDismiss,
      onRenderChange: this.onRenderChange,
      onReset: this.resetSelectedDates,
      onSubmit: this.onSubmit,
      open: filterOpened,
      renderMainFilter,
      shResetDisplay: this.shResetDisplay(),
      title: { defaultMessage: 'Travel Dates', id: 'DateRefinement.heading' },
    };
    return (
      <Filter
        applyNewFilterUI
        label="Date Options"
        onEvent={this.onEvent}
        pill={pill}
        popover={popover}
        position="top"
        showItineraries={false}
        tabIndex={0}
      >
        <DatePicker
          applyNewFilterUI={applyNewFilterUI}
          isShowHeader={false}
          maxDate={updatedMaxDate}
          minDate={minDate}
          onSelectMonth={this.onSelectMonth}
          rangeEnd={selectedEnd}
          rangeStart={selectedStart}
          resetSelectedDates={noop}
        />
      </Filter>
    );
  }
}

DateRefinement.propTypes = {
  appliedEndDate: PropTypes.string.isRequired,
  appliedStartDate: PropTypes.string.isRequired,
  applyNewFilterUI: PropTypes.bool,
  defaultEndDate: PropTypes.string.isRequired,
  defaultStartDate: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  filterOpened: PropTypes.bool,
  hideTitle: PropTypes.bool,
  isMultipleVoyageFilter: PropTypes.bool,
  maxDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  onEvent: PropTypes.func,
  onRenderChange: PropTypes.func,
  removeMultipleVoyageFilter: PropTypes.func.isRequired,
  renderMainFilter: PropTypes.func,
  setFilterDates: PropTypes.func.isRequired,
  setFilterOpened: PropTypes.func,
};
DateRefinement.defaultProps = {
  applyNewFilterUI: true,
  disabled: false,
  filterOpened: false,
  hideTitle: false,
  isMultipleVoyageFilter: false,
  onEvent: () => {},
  onRenderChange: () => {},
  renderMainFilter: () => {},
  setFilterOpened: () => {},
};

const mapStateToProps = (state) => {
  const filtersOptions = getFiltersOptions(state);
  const defaultFilters = getDefaultFilters(state);
  const currentDate = currentNYTime(selectLookup(state).serverISOtime);

  return {
    appliedEndDate: selectToDate(state),
    appliedStartDate: selectFromDate(state),
    defaultEndDate: defaultFilters.toDate,
    defaultStartDate: defaultFilters.fromDate,
    isMultipleVoyageFilter: isMultipleVoyageFilterActive(state),
    maxDate: filtersOptions.maxDate || format(currentDate),
    minDate: filtersOptions.minDate || format(currentDate),
  };
};

const mapDispatchToProps = {
  removeMultipleVoyageFilter,
  setFilterDates,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSearchParams(DateRefinement));
