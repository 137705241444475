import UIResource from '@/components/UIResource';
import { selectHeadlineBackground } from '@/ducks/quickSearchItems/selectors';
import { useAppSelector } from '@/store';

import { BlackFridayTimer } from '../Timer';

import './HeadlineWithAdvertCardList.scss';

type Props = {
  children: React.ReactNode;
};

export const HeadlineWithAdvertCardList = ({ children }: Props) => {
  const background = useAppSelector(selectHeadlineBackground);

  return (
    <div className="HeadlineWithAdvertCardList">
      <div className="heading">
        <h2 className="heading__title">
          <UIResource id="BlackFriday.pageTitle" />
        </h2>
        <BlackFridayTimer />
      </div>
      <div className="draggable">
        <div className="row">
          <div className="card__container">{children}</div>
        </div>
      </div>

      <div className="step-wave">
        <div className="step-wave--cut-top"></div>
        <span className="step-wave--bg" dangerouslySetInnerHTML={{ __html: background ?? '' }} />
      </div>
    </div>
  );
};
