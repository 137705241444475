/* eslint-disable perfectionist/sort-enums */

export enum CURRENCY_CODE_SOURCE {
  invoice = 'invoice',
  store = 'store',
  url = 'url',
  fm = 'fm',
  mnvv = 'mnvv',
  cookie = 'cookie',
}
