import { useSearchParams } from 'next/navigation';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

import cn from 'classnames';
import { memoize, omit } from 'lodash';
import intersection from 'lodash/intersection';
import { useSelector } from 'react-redux';

import DisabledSailorPopup from '@/components/DisabledSailorPopup';
import Flyout from '@/components/Flyout';
import CloseWithoutBackground from '@/components/Icon/ChooseVoyage/CloseWithoutBackground';
import Close from '@/components/Icon/Close';
import Filter from '@/components/Icon/FilterOption';
import CabinTypeRefinement from '@/components/ResultRefinements/CabinTypeRefinement';
import DurationRefinement from '@/components/ResultRefinements/DurationRefinement';
import TravelPartyRefinement from '@/components/ResultRefinements/TravelPartyRefinement';
import UIResource from '@/components/UIResource';
import { Button } from '@/components/elements';
import breakpoints from '@/constants/breakpoints';
import { ArrowButtonToggleAnimated } from '@/containers/VoyagePlanner/ChooseCabin/components/ArrowButtonToggleAnimated';
import Status from '@/ducks/a11y/components/Status';
import useActionStatus from '@/ducks/a11y/hooks/useActionStatus';
import { selectAppliedFilterLabelsCount } from '@/ducks/appliedfilterLabels';
import { useUIResource } from '@/ducks/common/resources';
import { selectSettings } from '@/ducks/common/settings/selectors';
import { removeMultipleVoyageFilter } from '@/ducks/multipleVoyageFilter';
import { resetFiltersForPackages } from '@/ducks/pages/chooseVoyage';
import { selectFilteredSailingsCount } from '@/ducks/pages/chooseVoyage/filteredPackages/selectors';
import { setResetKeyAction } from '@/ducks/pages/chooseVoyage1.0';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { routes } from '@/ducks/routes';
import enforceArray from '@/helpers/enforceArray';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import useOnWindowResize from '@/hooks/useOnWindowResize';
import { useAppDispatch } from '@/store';
import tagmanager from '@/tagmanager';

import DeparturePortFilter from './DeparturePortFilter';
import FilterRefinementMobileView from './FilterRefinementMobileView';
import PriceRangeFilter from './PriceRangeFilter';
import ShipFilter from './ShipFilter';

import './AdvancedFilterRefinement.scss';

const UI_RESOURCE_POOL = {
  applyFilterText: 'AdvancedFilter.cta',
};

const RESET_STATUS_TEXT = 'Reset button clicked';

const AdvancedFilterRefinement = ({
  closeFilterModal,
  collapseSection,
  expandSection,
  expandedSections,
  isMultipleVoyageFilter,
  isOpen,
  renderMainFilter = () => {},
}) => {
  const dispatch = useAppDispatch();
  const [isDisabledSailorModalOpen, setIsDisabledSailorModalOpen] = useState(false);
  const [isOpenRefinementSection, setIsOpenRefinementSection] = useState(false);

  const appliedFilterLabelsCount = useSelector(selectAppliedFilterLabelsCount);
  const configServiceData = useSelector(selectSettings);
  const filteredSailingsCount = useSelector(selectFilteredSailingsCount) || 0;
  const isLoading = useSelector(selectIsFilteringLoading);

  const hasResults = filteredSailingsCount > 0;
  const hasExpansions = !!expandedSections?.length;

  const label = isLoading ? '' : filteredSailingsCount;

  const isMobile = useOnWindowResize(() => window.innerWidth < breakpoints.md) ?? false;
  const applyFilterText = useUIResource(UI_RESOURCE_POOL.applyFilterText, { n: label });

  const searchParams = useSearchParams();

  const checkFilterAvailable = useCallback(
    (filterName) => !!intersection(enforceArray(filterName), expandedSections).length,
    [expandedSections],
  );

  const onApplyFilters = useCallback(
    (e) => {
      e.preventDefault();
      closeFilterModal();
      tagmanager.tracker.voyagesFilter.trackShowFiltersResult({
        label: applyFilterText,
        results: filteredSailingsCount,
      });
    },
    [applyFilterText, closeFilterModal, filteredSailingsCount],
  );

  const onCloseClick = useCallback(
    (e) => {
      e.preventDefault();
      closeFilterModal();
      tagmanager.tracker.voyagesFilter.trackHideFilters();
    },
    [closeFilterModal],
  );

  const onDisabledSailorModalClick = useCallback(() => {
    setIsDisabledSailorModalOpen(!isDisabledSailorModalOpen);
  }, [isDisabledSailorModalOpen, setIsDisabledSailorModalOpen]);

  const onResetFilter = useCallback(() => {
    dispatch(resetFiltersForPackages());
    dispatch(setResetKeyAction(true));
    if (isMultipleVoyageFilter) {
      dispatch(removeMultipleVoyageFilter());
      routes.planner.chooseVoyage.go(omit(Object.fromEntries(searchParams?.entries()), ['voyageId']));
    }
  }, [dispatch, searchParams, isMultipleVoyageFilter]);

  const { actionWithStatus: resetWithStatus, closeStatus, isStatusShown } = useActionStatus(onResetFilter);

  const onSailorsericeBtnClick = useCallback(() => {
    setIsDisabledSailorModalOpen(false);
    const landingPageUrl = configServiceData?.ContactUsUrl;
    window.open(landingPageUrl, '_blank');
  }, [configServiceData, setIsDisabledSailorModalOpen]);

  const toggleFilterState = useCallback(
    (section) =>
      memoize((state) => {
        (state ? expandSection : collapseSection)(section);
      }),
    [collapseSection, expandSection],
  );

  const toggleIsOpenRefinementSection = useCallback(() => {
    if (!isOpenRefinementSection) {
      tagmanager.tracker.voyagesFilter.trackShowFilters();
    }

    setIsOpenRefinementSection(!isOpenRefinementSection);
  }, [isOpenRefinementSection, setIsOpenRefinementSection]);

  const toggleIsOpenRefinementSectionOnKeyDown = useOnKeyDown(toggleIsOpenRefinementSection);

  const handleOnResetFilterKeyDown = useOnKeyDown(resetWithStatus);

  const handleOnCloseClick = useOnKeyDown(onCloseClick);

  const handleOnApplyFilters = useOnKeyDown(onApplyFilters);

  const resetFiltersAllowed = appliedFilterLabelsCount > 0 || isMultipleVoyageFilter;

  return (
    <Flyout
      appear={false}
      delay={0}
      direction="right"
      focusOnOpenSelector=".AdvancedFilter__header .close .Icon.Close "
      hideCrossButton
      onDismiss={closeFilterModal}
      open={isOpen}
    >
      <div className="AdvancedFilter__section">
        {isMobile ? (
          <>
            <div className="FilterRefinementHeader">
              <button
                aria-label="Close"
                className="close --withoutBackground"
                onClick={onCloseClick}
                onKeyDown={handleOnCloseClick}
                tabIndex={0}
                type="button"
              >
                <CloseWithoutBackground />
              </button>
              <h2 className="FilterRefinementHeader__title">
                <UIResource id="AdvancedFilter.header.title" />
              </h2>

              <div className="FilterRefinementHeader__line" />
            </div>

            <div className={cn('FilterRefinement', { 'no-scroll': hasExpansions && isMobile })}>
              <FilterRefinementMobileView renderMainFilter={renderMainFilter} toggleFilterState={toggleFilterState} />
              <div className="advancedFilterView">
                <div
                  aria-expanded={isOpenRefinementSection}
                  className="heading"
                  onClick={toggleIsOpenRefinementSection}
                  onKeyDown={toggleIsOpenRefinementSectionOnKeyDown}
                  role="button"
                  tabIndex={hasExpansions ? -1 : 0}
                >
                  <div className="title">
                    <div className="title__icon">
                      <Filter aria-label="Filter Option" />
                    </div>
                    <span className="title__text">
                      <UIResource id="AdvancedFilter.title" />
                    </span>
                  </div>
                  <ArrowButtonToggleAnimated direction={isOpenRefinementSection ? 'up' : 'down'} />
                </div>
                {isOpenRefinementSection && (
                  <div className="content">
                    <TravelPartyRefinement
                      closeFilterModal={closeFilterModal}
                      isOpenFilterSection={checkFilterAvailable(['travelParty', 'accessible'])}
                      onDisabledSailorModalClick={onDisabledSailorModalClick}
                      toggleFilterState={toggleFilterState('travelParty')}
                    />
                    <CabinTypeRefinement
                      closeFilterModal={closeFilterModal}
                      isOpenFilterSection={checkFilterAvailable('cabinType')}
                      toggleFilterState={toggleFilterState('cabinType')}
                    />
                    <DurationRefinement
                      closeFilterModal={closeFilterModal}
                      isOpenFilterSection={checkFilterAvailable(['duration', 'weekend'])}
                      toggleFilterState={toggleFilterState('duration')}
                    />
                    <PriceRangeFilter
                      closeFilterModal={closeFilterModal}
                      isOpenFilterSection={checkFilterAvailable('price')}
                      toggleFilterState={toggleFilterState('price')}
                    />
                    <ShipFilter
                      closeFilterModal={closeFilterModal}
                      isOpenFilterSection={checkFilterAvailable('ship')}
                      toggleFilterState={toggleFilterState('ship')}
                    />
                    <DeparturePortFilter
                      closeFilterModal={closeFilterModal}
                      isOpenFilterSection={checkFilterAvailable('port')}
                      toggleFilterState={toggleFilterState('port')}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="AdvancedFilter__main">
            <div className="AdvancedFilter__header" id="AdvancedFilter__header">
              <button className="close" onClick={onCloseClick} tabIndex={-1}>
                <Close aria-label="Close" tabIndex={0} type="button" />
              </button>
              <h2 className="title">
                <UIResource id="AdvancedFilter.title" />
              </h2>
            </div>
            <div className="AdvancedFilter__body">
              <TravelPartyRefinement
                closeFilterModal={closeFilterModal}
                isOpenFilterSection={checkFilterAvailable(['travelParty', 'accessible'])}
                onDisabledSailorModalClick={onDisabledSailorModalClick}
                toggleFilterState={toggleFilterState('travelParty')}
              />
              <CabinTypeRefinement
                closeFilterModal={closeFilterModal}
                isOpenFilterSection={checkFilterAvailable('cabinType')}
                toggleFilterState={toggleFilterState('cabinType')}
              />
              <DurationRefinement
                closeFilterModal={closeFilterModal}
                isOpenFilterSection={checkFilterAvailable(['duration', 'weekend'])}
                toggleFilterState={toggleFilterState('duration')}
              />
              <PriceRangeFilter
                closeFilterModal={closeFilterModal}
                isOpenFilterSection={checkFilterAvailable('price')}
                toggleFilterState={toggleFilterState('price')}
              />
              <ShipFilter
                closeFilterModal={closeFilterModal}
                isOpenFilterSection={checkFilterAvailable('ship')}
                toggleFilterState={toggleFilterState('ship')}
              />
              <DeparturePortFilter
                closeFilterModal={closeFilterModal}
                isOpenFilterSection={checkFilterAvailable('port')}
                toggleFilterState={toggleFilterState('port')}
              />
              <DisabledSailorPopup
                onDismissClick={onDisabledSailorModalClick}
                onSailorsericeBtnClick={onSailorsericeBtnClick}
                showModal={isDisabledSailorModalOpen}
              />
            </div>
          </div>
        )}
        <div className="AdvancedFilter__footer FooterActionBar">
          <div className="row">
            {resetFiltersAllowed ? (
              <div
                className="col col1"
                onClick={resetWithStatus}
                onKeyDown={handleOnResetFilterKeyDown}
                role="button"
                tabIndex={0}
              >
                <UIResource id="AdvancedFilter.resetFilter.txt" />
              </div>
            ) : (
              <div className="col col1" />
            )}
            <div className="col col2">
              <Button
                block
                disabled={!hasResults && !isLoading}
                id="PriceBreakdownCheckout"
                onClick={onApplyFilters}
                onKeyDown={handleOnApplyFilters}
                variant="primary"
              >
                {applyFilterText}
              </Button>
            </div>
          </div>

          <Status hideAfter={1000} isShown={isStatusShown} onHide={closeStatus} srOnly>
            {RESET_STATUS_TEXT}
          </Status>
        </div>
      </div>
    </Flyout>
  );
};

AdvancedFilterRefinement.propTypes = {
  closeFilterModal: PropTypes.func.isRequired,
  collapseSection: PropTypes.func,
  expandSection: PropTypes.func,
  expandedSections: PropTypes.arrayOf(PropTypes.string),
  isMultipleVoyageFilter: PropTypes.bool,
  renderMainFilter: PropTypes.func,
};

export default AdvancedFilterRefinement;
