import PropTypes from 'prop-types';

import classNames from 'classnames';
import { connect } from 'react-redux';

import AdvancedFilterHOC from '@/components/AdvancedFilterRefinement/AdvancedFilterHOC';
import BaseFilter from '@/components/AdvancedFilterRefinement/BaseFilter';
import SailorAnimation from '@/components/Animations/SailorAnimation';
import CabinDoor from '@/components/Icon/CabinDoor';
import Sailors from '@/components/Icon/Sailors';
import UIResource from '@/components/UIResource';
import { getAppliedFilterLabels } from '@/ducks/appliedfilterLabels/appliedfilterlabel';
import { selectCabins, selectSailors } from '@/ducks/filters/selectors';
import { getDefaultFilters, getMaxSailors } from '@/ducks/filtersOptions';
import { setSailorsForPackages as setSailorsActionForPackages } from '@/ducks/pages/chooseVoyage';
import { setAppliedFilterLabels as setAppliedFilterLabelsAction } from '@/ducks/pages/chooseVoyage1.0';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { getLabelForSailors, getMnvvReservation } from '@/helpers/util/misc';
import { imitateClickOnKeyEvent } from '@/hooks/useOnKeyDown';

import AccessibilityRefinement from '../AccessibilityRefinement';

import './TravelPartyRefinement.scss';

class TravelPartyRefinement extends BaseFilter {
  onSelect = (index) => {
    const sailor = index + 1;
    const { isMNVV } = getMnvvReservation();
    if (isMNVV && sailor === 1) {
      this.props.onDisabledSailorModalClick();
      return;
    }
    const travelParty = getLabelForSailors(sailor, this.props.cabins, this.context.formatMessage);
    this.props.setSailors(sailor);
    this.props.setAppliedFilterLabels({ travelParty });
  };

  render() {
    const { applyNewFilterUI, cabins, closeFilterModal, defaultSailors, isOpenFilterSection, sailors } = this.props;
    const { formatMessage } = this.context;
    const travelPartyText = formatMessage({
      defaultMessage: 'Travel Party',
      id: 'AdvancedFilter.TravelParty.heading',
    });

    return (
      <div className="AF__row TravelParty__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          defaultValue={getLabelForSailors(sailors, cabins, formatMessage)}
          filterName={travelPartyText}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="TravelPartyRefinement">
            <div className="TravelPartyRefinement__heading">
              <div className="TravelPartyRefinement__icon">
                <Sailors />
              </div>
              <span className="TravelPartyRefinement__title">
                <UIResource id="TravelPartyRefinement.sailors" />
              </span>
            </div>

            <div className={`TravelPartyRefinement__imgContents ${applyNewFilterUI ? '-newFilter' : ''}`}>
              <SailorAnimation defaultSailor={defaultSailors} selectedSailor={sailors} />
            </div>
            <div className="TravelPartyRefinement__renderSailorButtons" role="radiogroup">
              {this.renderSailorButtons()}
            </div>

            <div className="TravelPartyRefinement__cabins">
              <div className="TravelPartyRefinement__heading">
                <div className="TravelPartyRefinement__icon">
                  <CabinDoor />
                </div>
                <span className="TravelPartyRefinement__title">
                  <UIResource id="TravelPartyRefinement.cabins" />
                </span>
              </div>
              <span className="cabins__count">{cabins}</span>
            </div>

            <AccessibilityRefinement />
          </div>
        </AdvancedFilterHOC>
      </div>
    );
  }

  renderSailorButtons() {
    const { applyNewFilterUI, maxSailors, sailors } = this.props;
    const { isMNVV } = getMnvvReservation();

    const baseClass = applyNewFilterUI
      ? 'TravelPartyRefinement__sailorBtn__version2'
      : 'TravelPartyRefinement__sailorBtn';

    return (
      <div className="sailorBtnCentred">
        {[...Array(maxSailors).keys()].map((sailor) => {
          const buttonClass = classNames(baseClass, {
            active: sailor + 1 === sailors,
            mnvvSailorBtnDisabled: isMNVV && sailor === 0,
            sailorCentred: true,
          });
          return (
            <div
              aria-checked={sailor + 1 === sailors}
              className={buttonClass}
              key={sailor}
              onClick={() => this.onSelect(sailor)}
              onKeyDown={imitateClickOnKeyEvent}
              ref={(el) => (this[`button${sailor}`] = el)}
              role="radio"
              tabIndex={0}
            >
              <span>{sailor + 1}</span>
            </div>
          );
        })}
      </div>
    );
  }
}

TravelPartyRefinement.propTypes = {
  cabins: PropTypes.number,
  closeFilterModal: PropTypes.bool.isRequired,
  labels: PropTypes.objectOf(PropTypes.shape({})),
  maxSailors: PropTypes.number,
  onDisabledSailorModalClick: PropTypes.func,
  sailors: PropTypes.number,
  setAppliedFilterLabels: PropTypes.func.isRequired,
  setSailors: PropTypes.func,
};

TravelPartyRefinement.defaultProps = {
  applyNewFilterUI: true,
};

const mapStateToProps = (state) => ({
  cabins: selectCabins(state),
  defaultSailors: getDefaultFilters(state).sailors,
  labels: getAppliedFilterLabels(state),
  maxSailors: getMaxSailors(state),
  sailors: selectSailors(state),
});

const mapDispatchToProps = {
  setAppliedFilterLabels: setAppliedFilterLabelsAction,
  setSailors: setSailorsActionForPackages,
};

TravelPartyRefinement.contextType = FormattedMessageContext;

export default connect(mapStateToProps, mapDispatchToProps)(TravelPartyRefinement);
