import PropTypes from 'prop-types';
import React from 'react';

import classnames from 'classnames';

import Arrow from '../Icon/Arrow';
import MonthButton from './MonthButton';

const propTypes = {
  applyNewFilterUI: PropTypes.bool,
  hideBothArrows: PropTypes.bool,
  hideLeftArrow: PropTypes.bool,
  hideRightArrow: PropTypes.bool,
  hoverIdx: PropTypes.number,
  isLeftArrowDisabled: PropTypes.bool.isRequired,
  isRightArrowDisabled: PropTypes.bool.isRequired,
  isShowHeader: PropTypes.bool,
  label: PropTypes.string.isRequired,
  months: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.shape({}).isRequired,
      disabled: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  onLeftArrowClick: PropTypes.func.isRequired,
  onMonthClick: PropTypes.func.isRequired,
  onMonthHover: PropTypes.func.isRequired,
  onRightArrowClick: PropTypes.func.isRequired,
  rangeEnd: PropTypes.number,
  rangeStart: PropTypes.number,
  resetYears: PropTypes.func.isRequired,
  yearIndex: PropTypes.number.isRequired,
};

const defaultProps = {
  applyNewFilterUI: false,
  hideBothArrows: false,
  hideLeftArrow: false,
  hideRightArrow: false,
  hoverIdx: null,
  isShowHeader: true,
  rangeEnd: null,
  rangeStart: null,
};

const YearGroup = ({
  applyNewFilterUI,
  hideBothArrows,
  hideLeftArrow,
  hideRightArrow,
  hoverIdx,
  isLeftArrowDisabled,
  isRightArrowDisabled,
  isShowHeader,
  label,
  months,
  onLeftArrowClick,
  onMonthClick,
  onMonthHover,
  onRightArrowClick,
  rangeEnd,
  rangeStart,
  resetYears,
  yearIndex,
}) => (
  <div className="DatePicker__yearGroup">
    {isShowHeader && (
      <>
        <div className="datepickerHeader">Travel dates</div>
        <span className="resetMonthsSelected" onClick={() => resetYears()}>
          Reset
        </span>
      </>
    )}
    {hideLeftArrow || hideRightArrow || hideBothArrows ? (
      <div className="arrowsNYear">
        {!hideLeftArrow && !hideBothArrows && (
          <div
            className={classnames('leftArrow', { disableArrow: isLeftArrowDisabled })}
            onClick={() => !isLeftArrowDisabled && onLeftArrowClick(yearIndex)}
            type="button"
          >
            <Arrow />
          </div>
        )}
        <h2>{label}</h2>
        {!hideRightArrow && !hideBothArrows && (
          <div
            className={classnames('rightArrow', { disableArrow: isRightArrowDisabled })}
            onClick={() => !isRightArrowDisabled && onRightArrowClick(yearIndex - 1)}
            type="button"
          >
            <Arrow />
          </div>
        )}
      </div>
    ) : (
      <div className="arrowsNYear">
        <div
          className={classnames('leftArrow', { disableArrow: isLeftArrowDisabled })}
          onClick={() => !isLeftArrowDisabled && onLeftArrowClick(yearIndex)}
          type="button"
        >
          <Arrow />
        </div>
        <h2>{label}</h2>
        <div
          className={classnames('rightArrow', { disableArrow: isRightArrowDisabled })}
          onClick={() => !isRightArrowDisabled && onRightArrowClick(yearIndex)}
          type="button"
        >
          <Arrow />
        </div>
      </div>
    )}
    <div className="DatePicker__monthContainer">
      {months.map((m) => (
        <MonthButton
          applyNewFilterUI={applyNewFilterUI}
          hoverIdx={hoverIdx}
          key={m.date.valueOf()}
          monthData={m}
          onMonthClick={onMonthClick}
          onMonthHover={onMonthHover}
          rangeEnd={rangeEnd}
          rangeStart={rangeStart}
        />
      ))}
    </div>
  </div>
);

YearGroup.defaultProps = defaultProps;
YearGroup.propTypes = propTypes;

export default YearGroup;
