import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';
import { omit } from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import without from 'lodash/without';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FilterDestination from '@/components/Icon/filterDestination';
import UIResource from '@/components/UIResource';
import Filter from '@/components/elements/Filter';
import Price from '@/components/elements/Price';
import breakpoints from '@/constants/breakpoints';
import { setResetAll as setResetAllAction } from '@/ducks/filters';
import { selectPackages, selectPorts, selectRegions } from '@/ducks/filters/selectors';
import { removeMultipleVoyageFilter } from '@/ducks/multipleVoyageFilter';
import {
  getPackageSearch,
  getSelectedRegionLabel,
  setRegionForPackages as setRegionForPackagesAction,
} from '@/ducks/pages/chooseVoyage';
import {
  DESTINATION_TYPE as TOGGLE_VALUES,
  getFiltersForPackages,
} from '@/ducks/pages/chooseVoyage/destinationSelectors';
import { isMultipleVoyageFilterActive } from '@/ducks/pages/chooseVoyage/selectorDetails';
import {
  getResetKey,
  setPackgesInFilter as setPackgesInFilterAction,
  setPortsInFilter as setPortsInFilterAction,
  setSelectedRegionIds as setSelectedRegionIdsAction,
  setTrackingDestinationFilter as trackingDestinationFilterAction,
} from '@/ducks/pages/chooseVoyage1.0';
import { routes } from '@/ducks/routes';
import { getAmountTaxAware } from '@/helpers/data/mappers/Summary';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import { isServerSide } from '@/helpers/isServerSide';
import { scrollIntoViewAsync } from '@/helpers/scrollIntoView';
import { checkForValueType } from '@/helpers/util';
import { trackResetFilters } from '@/helpers/util/tracking';
import withSearchParams from '@/hocs/withSearchParams';

import DestinationDetails from './DestinationDetails';
import DestinationHeader from './DestinationHeader';

import './DestinationRefinement.scss';

const DEFAULT_REGION_ID = 'CARIBBEAN';

const getCurrentTab = (itineraryFilters) => {
  const hasPorts = itineraryFilters.findIndex(({ appliedPorts }) => appliedPorts.length > 0) !== -1;

  if (hasPorts) {
    return TOGGLE_VALUES.PORTS_OF_CALL;
  }

  return TOGGLE_VALUES.ITINERARIES;
};

class DestinationRefinement extends React.Component {
  callSetRegionItinerariesAction = (onlySelectedRegion) => {
    const { actions } = this.props;
    const { selectedRegions } = this.state;
    if (onlySelectedRegion.length > 0) {
      onlySelectedRegion.forEach((data) => {
        const { packages, ports, regionId } = data;
        const filters = {
          packages: packages.length ? packages : [],
          ports: ports.length ? ports : [],
        };
        actions.setRegionForPackages(regionId, filters);
        return null;
      });
    } else {
      selectedRegions.forEach((data) => {
        const { regionId } = data;
        const filters = {
          packages: [],
          ports: [],
        };
        actions.setRegionForPackages(regionId, filters);
        return null;
      });
    }
  };

  checkBoxRenderPorts = (itineraries, onChange) => {
    const key = Object.keys(!isEmpty(itineraries) ? itineraries : []);
    let portsOfCall = {};

    key.map((country) => {
      const result = itineraries[country].map((pkg) => {
        const selectedArr = this.selectedPortsFn();

        const { code } = pkg;
        const description = '';
        return {
          checked: selectedArr.includes(code),
          // but technically enable selected ones
          className: '',
          code,
          // Style all non-present itineraries as disabled,
          description,
          imageUrl: pkg?.smallThumbnail || {},
          name: pkg?.name || '',
          onChange,
        };
      });
      portsOfCall = {
        ...portsOfCall,
        [country]: result,
      };
      return null;
    });
    return portsOfCall;
  };

  checkboxRenderData = (itineraries, selected, onChange) =>
    itineraries &&
    this.sortRegions(itineraries).map((pkg) => {
      const code = pkg.id || pkg.code;

      const selectedArr = selected === 'packages' ? this.selectedPackagesFn() : this.selectedPortsFn();

      let description = '';
      if (pkg.startingPrice) {
        const price = (
          <Price
            amount={getAmountTaxAware(pkg.startingPrice)}
            currencyCode={get(pkg, 'startingPrice.currencyCode', 'USD')}
          />
        );

        description = (
          <FormattedMessage
            defaultMessage="{numNights} nights from {price}"
            id="RegionRefinements.itineraryDescription"
            values={{ numNights: pkg.duration, price }}
          />
        );
      }
      return {
        checked: selectedArr.includes(code),
        // but technically enable selected ones
        className: '',
        code,
        // Style all non-present itineraries as disabled,
        description,
        imageUrl: pkg?.image || {},
        name: pkg?.name || '',
        onChange,
      };
    });

  // To remove duplicate packages i.e., package with same ids
  filterItineraryPackages = (packages) => packages && [...new Map(packages.map((m) => [m.id, m])).values()];

  filterItineraryPackagesWithSameNames = (packages) => {
    let data = [];
    if (packages?.length) {
      const { itineraries } = packages.reduce(
        (acc, pack) => {
          const filteredPackages = packages.filter((p) => p.name === pack.name);
          let lowestPricePackage = filteredPackages.length > 0 ? filteredPackages[0] : pack;
          filteredPackages.forEach((p) => {
            const amount = get(p, 'startingPrice.amount');
            const price = get(lowestPricePackage, 'startingPrice.amount');
            if (price === undefined) {
              lowestPricePackage = p;
            } else if (amount !== undefined && price !== undefined && price >= amount) {
              lowestPricePackage = p;
            }
          });

          const ispackageAlreadyPresent = acc?.itineraries?.find((item) => item.name === lowestPricePackage.name);
          if (!ispackageAlreadyPresent) {
            return {
              itineraries: [...acc.itineraries, lowestPricePackage],
            };
          }
          return {
            itineraries: [...acc.itineraries],
          };
        },
        { itineraries: [] },
      );
      data = packages && [...new Map(itineraries.map((m) => [m.name, m])).values()];
    }
    return data;
  };

  filterPackageSelected = (selectedRegionPackages) => {
    const { itineraryFilters, selectedFilterPackages } = this.props;
    const masterData = itineraryFilters.filter((itinerary) =>
      selectedRegionPackages.some((region) => region.regionId === itinerary.id),
    );
    // find regions of selectedFilter
    const tobeDeletedPackages = [];
    selectedFilterPackages.forEach((packgId) => {
      const pckgRegionData = masterData.filter((region) => region.packages.some((pckg) => pckg.id === packgId));
      if (isEmpty(pckgRegionData)) {
        tobeDeletedPackages.push(packgId);
      }
    });
    this.removePackgeFromFilter(tobeDeletedPackages);
  };

  filterPackages = (packages) => {
    if (checkForValueType(packages)) {
      const updatesPackages = this.filterItineraryPackages(packages);
      return this.filterItineraryPackagesWithSameNames(updatesPackages);
    }
    return packages;
  };

  filterPortSelected = (selectedRegionPackages) => {
    const { itineraryFilters, selectedFilterPorts } = this.props;
    const masterData = itineraryFilters.filter((itinerary) =>
      selectedRegionPackages.some((region) => region.regionId === itinerary.id),
    );
    // find regions of selectedFilter
    const tobeDeletedPorts = [];
    selectedFilterPorts.forEach((portId) => {
      const portRegionData = masterData.filter((region) =>
        region.ports.some((port) => port.id === portId || port.code === portId),
      );
      if (isEmpty(portRegionData)) {
        tobeDeletedPorts.push(portId);
      }
    });
    this.removePortFromFilter(tobeDeletedPorts);
  };

  getActiveClassNames = (itinerary, value) => {
    const { selectedRegion, selectedRegions, toggleValue } = this.state;
    const selected = filterRegionsById(selectedRegions, itinerary.id);
    let active = false;
    if (!isEmpty(selected) && selected[0].regionId === itinerary.id) {
      if (selected[0].packages.length > 0 || selected[0].ports.length > 0) {
        active = true;
      }
    }
    if (!isEmpty(selected) && selected[0].regionId === itinerary.id) {
      const isAnyItinerarySelected = selected[0].packages.length > 0;
      const isAnyPortSelected = selected[0].ports.length > 0;
      if (
        (isAnyItinerarySelected && toggleValue === TOGGLE_VALUES.ITINERARIES) ||
        (isAnyPortSelected && toggleValue === TOGGLE_VALUES.PORTS_OF_CALL)
      ) {
        active = true;
      }
    }
    if (value === 'region') {
      return classNames({
        DestinationRefinement__regionContainer: true,
        active: active && !this.isWholeSelected(itinerary.id),
      });
    }
    if (value === 'textDescription') {
      return classNames({
        active,
        text_description: true,
      });
    }
    if (value === 'allItineraryCheck') {
      return classNames({
        active: active && !this.isWholeSelected(itinerary.id),
        allItineraryCheck: true,
      });
    }
    return classNames({
      active: selectedRegion.regionId === itinerary.id,
      horizontalLine: true,
    });
  };

  getPackagePortsSelection = (
    selectedRegions,
    selectedRegion,
    checked,
    id,
    includeData,
    excludeData,
    itineraryFilters,
  ) => {
    const payload = [...selectedRegions];
    if (payload.length > 0) {
      payload.map((state) => {
        state[excludeData] = []; // eslint-disable-line no-param-reassign
        if (state.regionId === selectedRegion.regionId) {
          if (includeData === 'packages') {
            const packages = checked ? [...state[includeData], id] : [...state[includeData]];
            state[includeData] = this.getPackages(packages, selectedRegion.regionId, checked, itineraryFilters, id); // eslint-disable-line no-param-reassign
          } else {
            state[includeData] = checked ? [...state[includeData], id] : without(state[includeData], id); // eslint-disable-line no-param-reassign
          }
        }
        return null;
      });
    }
    return payload;
  };

  getPackages = (selectedPackages, regionId, checked, itineraryFilters, id) => {
    const region = itineraryFilters.filter((reg) => reg.id === regionId);
    const { packages } = region[0];
    const newPackages = [...selectedPackages];

    selectedPackages.forEach((selectedPackage) => {
      const currentPackage = packages?.find((pckgId) => pckgId.id === selectedPackage);
      if (currentPackage && checked) {
        const filteredPackages = packages.filter((pckg) => pckg.name === currentPackage.name);
        filteredPackages.forEach((filteredPackage) => {
          const isAlreadySelected = newPackages.includes(filteredPackage.id);
          if (isAlreadySelected) {
            return;
          }
          newPackages.push(filteredPackage.id);
        });
      } else if (selectedPackage === id) {
        const filteredPackages = packages.filter((pckg) => pckg.name === currentPackage.name);
        filteredPackages.forEach((filteredPackage) => {
          const removeIdx = newPackages.indexOf(filteredPackage.id);
          newPackages.splice(removeIdx, 1);
        });
      }
    });
    return newPackages;
  };

  getPackagesOrPortsOfRegion = (regionId, type, exsistingData, arg) => {
    const { selectedRegionsFromStore } = this.props;
    if (arg === 'onDismiss') {
      return exsistingData;
    }
    if (selectedRegionsFromStore.length <= 0) {
      return exsistingData;
    }
    const currentRegionDetails = findRegionById(selectedRegionsFromStore, regionId)?.[0];
    if (type === 'appliedPackages' && currentRegionDetails?.packages?.length) {
      return currentRegionDetails.packages;
    }
    if (type === 'appliedPorts' && currentRegionDetails?.ports?.length) {
      return currentRegionDetails.ports;
    }
    return exsistingData;
  };

  getPillLabel = () => {
    const { hideTitle, itineraryFilters, selectedRegionsFromStore } = this.props;
    const regionsSelected = this.sortRegions(itineraryFilters).filter((data) => {
      const findRegion = selectedRegionsFromStore?.find(
        (region) => region.regionId === data.id && (region.packages.length > 0 || region.ports.length > 0),
      );
      if (!findRegion) {
        return null;
      }
      const { appliedPackages, appliedPorts } = data;
      return appliedPackages.length > 0 || appliedPorts.length > 0;
    });

    const textClassName = !hideTitle ? 'refinementText' : 'highlight';
    if (hideTitle) {
      const label = getSelectedRegionLabel(itineraryFilters);
      return label === 'default' ? (
        <div className={textClassName}>
          <UIResource id="ChooseVoyage.Results.AllDestinations" />
          <span className="refinementTextSeparator">,</span>
        </div>
      ) : (
        <div className={textClassName}>
          {label}
          <span className="refinementTextSeparator">,</span>
        </div>
      );
    }
    if (!regionsSelected.length) {
      return (
        <span className={textClassName}>
          <UIResource id="Destination.filter.text" />
        </span>
      );
    }
    if (regionsSelected.length > 0) {
      let label = regionsSelected.slice(0, 2).map((data, idx) => {
        const { appliedPackages, appliedPorts, isWholeApplied, isWholePortsApplied, name } = data;
        const value = appliedPackages.length > 0 ? 'appliedPackages' : 'appliedPorts';
        if ((!isWholeApplied && value === 'appliedPackages') || (!isWholePortsApplied && value === 'appliedPorts')) {
          if (idx <= 1 && (appliedPackages.length > 0 || appliedPorts.length > 0)) {
            const selectedPackageNames = this.getSelectedPackageNames(
              value === 'appliedPackages' ? appliedPackages : appliedPorts,
              value === 'appliedPackages' ? data.packages : data.ports,
            );
            data = `${name} (${selectedPackageNames.length})`; // eslint-disable-line no-param-reassign
            return data;
          }
        } else {
          return name;
        }

        return null;
      });
      label = label.join(', ');
      return <span className={textClassName}>{label}</span>;
    }
  };

  getPillState = () => {
    const { filterOpened } = this.props;
    const { isMobile } = this.state;
    const parentClass = isServerSide() ? null : document.getElementById('primaryContainer');
    if (filterOpened) {
      if (parentClass) {
        parentClass.classList.add('lightBorder');
      }
      return 'open';
    }
    if (!filterOpened) {
      if (parentClass) {
        parentClass.classList.remove('lightBorder');
      }
    }

    if (isMobile) {
      return 'arrow';
    }

    return 'default';
  };

  getRegionsSelection = (selectedRegions, toggleValue, itineraryFilters, isWholeSelected, id) => {
    const itinerary = findFirstFilterById(itineraryFilters, id);
    let payload = [...selectedRegions];
    if (toggleValue === TOGGLE_VALUES.ITINERARIES) {
      if (payload.length > 0) {
        payload.map((state) => {
          if (state.regionId === id) {
            state.packages = isWholeSelected(id) ? [] : map(itinerary.packages, 'id'); // eslint-disable-line no-param-reassign
            state.ports = []; // eslint-disable-line no-param-reassign
          } else if (!selectedRegions.some((e) => e.regionId === id)) {
            payload = [
              ...payload,
              {
                packages: isWholeSelected(id) ? [] : map(itinerary.packages, 'id'),
                ports: [],
                regionId: id,
              },
            ];
          }
          return null;
        });
      } else {
        payload = [
          ...payload,
          {
            packages: isWholeSelected(id) ? [] : map(itinerary.packages, 'id'),
            ports: [],
            regionId: id,
          },
        ];
      }
    } else if (payload.length > 0) {
      payload.map((state) => {
        if (state.regionId === id) {
          state.ports = isWholeSelected(id) ? [] : map(itinerary.ports, 'code'); // eslint-disable-line no-param-reassign
          state.packages = []; // eslint-disable-line no-param-reassign
        } else if (!selectedRegions.some((e) => e.regionId === id)) {
          payload = [
            ...payload,
            {
              packages: [],
              ports: isWholeSelected(id) ? [] : map(itinerary.ports, 'code'),
              regionId: id,
            },
          ];
        }
        return null;
      });
    } else {
      payload = [
        ...payload,
        {
          packages: [],
          ports: this.isWholeSelected(id) ? [] : map(itinerary.ports, 'code'),
          regionId: id,
        },
      ];
    }
    return payload;
  };

  getSelectedPackageNames = (packageCodes, packages) => {
    const { selectedPackagesNames } = packageCodes.reduce(
      (acc, packgId) => {
        const packageName =
          !isEmpty(packages) &&
          packages.find((packageCode) => packageCode.code === packgId || packageCode.id === packgId);
        if (packageName && !acc.selectedPackagesNames.includes(packageName.name)) {
          return { selectedPackagesNames: [...acc.selectedPackagesNames, packageName.name] };
        }
        return acc;
      },
      { selectedPackagesNames: [] },
    );

    return selectedPackagesNames;
  };

  isApplyDisabled = () => {
    const { reset, selectedRegions } = this.state;
    const { isMultipleVoyageFilter, itineraryFilters, selectedFilterPackages, selectedFilterPorts } = this.props;

    if (reset && isMultipleVoyageFilter) {
      return false;
    }

    const selectedRegionInState = selectedRegions?.find((region) => region.packages.length || region.ports.length);
    const selectedRegionInStore = itineraryFilters?.find((itinerary) => itinerary.isApplied);
    const isInitialState = selectedRegionInState === undefined && selectedRegionInStore === undefined;

    const packagesInState = selectedRegions.flatMap((region) => region.packages);
    const portsInState = selectedRegions.flatMap((region) => region.ports);

    const arePortsChanged = !isEqual(portsInState.sort(), [...selectedFilterPorts].sort());
    const arePackagesChanged = !isEqual(packagesInState.sort(), [...selectedFilterPackages].sort());

    if (isInitialState) {
      return true;
    }

    if (arePortsChanged || arePackagesChanged) {
      return false;
    }

    if (!reset) {
      return true;
    }

    return false;
  };

  isCheckSelected = (id) => {
    const { itineraryFilters } = this.props;
    const { selectedRegions, toggleValue } = this.state;

    const selectedRegion = filterRegionsById(selectedRegions, id);

    if (!selectedRegions.length > 0 || isEmpty(id) || isEmpty(selectedRegion)) {
      return false;
    }
    const itineraryFilter = findFirstFilterById(itineraryFilters, id);

    if (toggleValue === TOGGLE_VALUES.ITINERARIES) {
      if (
        !isEmpty(selectedRegion) &&
        selectedRegion[0].packages.length > 0 &&
        selectedRegion[0].packages.length === itineraryFilter.packages.length
      ) {
        return true;
      }
    } else if (
      !isEmpty(selectedRegion) &&
      selectedRegion[0].ports.length > 0 &&
      selectedRegion[0].ports.length === itineraryFilter.ports.length
    ) {
      return true;
    }

    return false;
  };

  isPackagesAndPortsEmptyForAllRegions = () => {
    const { selectedRegions } = this.state;
    const regions = selectedRegions?.find((region) => region?.packages?.length || region?.ports?.length);
    return regions;
  };

  isRegionSelected =
    ({ regionId, toItineraries }) =>
    (event) => {
      const { selectedRegions, toggleValue } = this.state;
      const { itineraryFilters } = this.props;
      this.onItineraryChange({ regionId, toItineraries })(event);

      const selectedRegionPayload = this.getRegionsSelection(
        selectedRegions,
        toggleValue,
        itineraryFilters,
        this.isWholeSelected,
        regionId,
      );
      this.setState({
        selectedRegions: selectedRegionPayload,
      });
    };

  isWholeSelected = (id, value = '') => {
    const { selectedRegions, toggleValue } = this.state;
    const { itineraryFilters } = this.props;
    const toggledValue = value || toggleValue;
    if (selectedRegions.length > 0) {
      const itinerary = findFirstFilterById(itineraryFilters, id);
      const data = filterRegionsById(selectedRegions, id);
      if (toggledValue === TOGGLE_VALUES.ITINERARIES) {
        return data.length > 0 ? data[0].packages.length === itinerary.packages.length : false;
      }
      return data.length > 0 ? data[0].ports.length === itinerary.ports.length : false;
    }
    return false;
  };

  onApply = (event) => {
    event.stopPropagation();
    const { reset, selectedRegions } = this.state;
    const { actions, closeFilterModal, isMultipleVoyageFilter, searchParams } = this.props;

    actions.setResetAll(false);
    setTimeout(() => {
      this.setState({ reset: false });
    }, 0);
    const onlySelectedRegion = selectedRegions.filter((region) => !isEmpty(region.ports) || !isEmpty(region.packages));
    actions.setSelectedRegionIds(onlySelectedRegion);
    const regionsWithPackageSelection = selectedRegions.filter((selectedRegion) => !isEmpty(selectedRegion.packages));
    this.filterPackageSelected(regionsWithPackageSelection);
    const regionsWithPortSelection = selectedRegions.filter((selectedRegion) => !isEmpty(selectedRegion.ports));
    this.filterPortSelected(regionsWithPortSelection);

    this.callSetRegionItinerariesAction(onlySelectedRegion);
    if (reset && isMultipleVoyageFilter) {
      actions.removeMultipleVoyageFilter();
      routes.planner.chooseVoyage.go(omit(Object.fromEntries(searchParams?.entries()), ['voyageId']));
    }
    closeFilterModal?.();

    actions.setTrackingDestinationFilter(this.isWholeSelected);
  };

  onDismiss = (event) => {
    if (event?.currentTarget?.id === 'root') {
      // ADA
      // flyout calls dismiss cb on apply btn click ONLY for key events. for click not - and thats fine
      // https://virginvoyages.atlassian.net/browse/MSH-110235?focusedCommentId=902271
      return;
    }

    this.setSelectedRegions('onDismiss');
  };

  onItineraryChange =
    ({ regionId, toItineraries }) =>
    (event) => {
      const id = regionId ?? event.currentTarget?.id;
      const { itineraryFilters } = this.props;

      const itinerary = itineraryFilters.filter((a) => a.id === id)[0];
      const selectedRegion = {
        name: itinerary?.name,
        packages: this.filterPackages(itinerary?.packages),
        ports: itinerary?.ports,
        portsOfCall: itinerary?.portsOfCall,
        regionId: itinerary?.id,
      };
      const redirectProps = {
        showItineraries: !!toItineraries,
        showRegions: !!toItineraries,
      };
      // setState doesn't make a deep comparison itself
      if (
        !isEqual(selectedRegion, this.state.selectedRegion) ||
        redirectProps.showItineraries !== this.state.showItineraries ||
        redirectProps.showRegions !== this.state.showRegions
      ) {
        this.setState({
          selectedRegion,
          ...redirectProps,
        });
        this.setState({ isItineraryChangeApplied: true });
      }
    };

  onPackageChange = (event) => {
    const { selectedRegion, selectedRegions } = this.state;
    const { itineraryFilters } = this.props;
    const { checked, id } = event.currentTarget;

    const payload = this.getPackagePortsSelection(
      selectedRegions,
      selectedRegion,
      checked,
      id,
      'packages',
      'ports',
      itineraryFilters,
    );

    this.setState({
      selectedRegions: payload,
    });
  };

  onPortChange = (event) => {
    const { selectedRegion, selectedRegions } = this.state;
    const { itineraryFilters } = this.props;
    const { checked, id } = event.currentTarget;

    const payload = this.getPackagePortsSelection(
      selectedRegions,
      selectedRegion,
      checked,
      id,
      'ports',
      'packages',
      itineraryFilters,
    );
    this.setState({
      selectedRegions: payload,
    });
  };

  onRenderChange = (isFilterOpen) => {
    const { onRenderChange, setFilterOpened } = this.props;
    // eslint-disable-next-line no-unused-expressions
    onRenderChange?.(isFilterOpen);
    setFilterOpened?.(isFilterOpen);
  };

  onReset = (data) => {
    const { selectedRegions } = this.state;
    const removePayload = [];
    selectedRegions.forEach((region) => {
      const selectRegion = cloneDeep(region);
      selectRegion.packages = [];
      selectRegion.ports = [];
      removePayload.push(selectRegion);
    });

    this.setState({
      reset: true,
      selectedRegions: removePayload,
    });
    if (window.innerWidth > 768) {
      this.setDefaultRegion();
    }
    if (!isEmpty(data) && !data.resetAll) {
      trackResetFilters('destination');
    }
  };

  onToggleChange = () => {
    const { toggleValue } = this.state;
    this.setState({
      toggleValue: toggleValue === TOGGLE_VALUES.ITINERARIES ? TOGGLE_VALUES.PORTS_OF_CALL : TOGGLE_VALUES.ITINERARIES,
    });
  };

  removePackgeFromFilter = (packages) => {
    const { actions, selectedFilterPackages } = this.props;
    const result = new Set(selectedFilterPackages);
    packages.forEach((id) => result.delete(id));
    actions.setPackgesInFilter(Array.from(result));
  };

  removePortFromFilter = (ports) => {
    const { actions, selectedFilterPorts } = this.props;
    const result = new Set(selectedFilterPorts);
    ports.forEach((id) => result.delete(id));
    actions.setPortsInFilter(Array.from(result));
  };

  resetItitneraryStateChange = () => {
    const { closeFilterModal } = this.props;
    this.setState({ isItineraryChangeApplied: false });
    closeFilterModal();
  };

  selectedPackagesFn = (id) => {
    const { selectedRegion, selectedRegions } = this.state;
    const { itineraryFilters } = this.props;
    if (selectedRegions.length > 0) {
      const data = filterRegionsById(selectedRegions, id || selectedRegion.regionId);
      return data.length > 0
        ? this.getPackages(data?.[0]?.packages, data?.[0]?.regionId, true, itineraryFilters, '')
        : [];
    }
    return [];
  };

  selectedPortsFn = (id) => {
    const { selectedRegion, selectedRegions } = this.state;

    if (selectedRegions.length > 0) {
      const data = filterRegionsById(selectedRegions, id || selectedRegion.regionId);
      return data.length > 0 ? data[0].ports : [];
    }
    return [];
  };

  setDefaultRegion = () => {
    try {
      const { itineraryFilters } = this.props;
      const REGION_ID = isEmpty(itineraryFilters) ? DEFAULT_REGION_ID : itineraryFilters[0]?.id;
      this.onItineraryChange({ regionId: REGION_ID, toItineraries: false })();
    } catch (e) {
      this.onItineraryChange({
        regionId: DEFAULT_REGION_ID,
        toItineraries: false,
      })();
    }
  };

  setSelectedRegions = (arg = '') => {
    const { itineraryFilters, selectedRegionsFromStore } = this.props;
    if (!isEmpty(arg)) {
      const filters = [];
      itineraryFilters.forEach((data) => {
        const findRegion = findRegionById(selectedRegionsFromStore, data.id);
        const appliedPackages = findRegion?.length ? data.appliedPackages : [];
        const appliedPorts = findRegion?.length ? data.appliedPorts : [];
        filters.push({
          name: data.name,
          packages: this.getPackagesOrPortsOfRegion(data.id, 'appliedPackages', appliedPackages, arg),
          ports: this.getPackagesOrPortsOfRegion(data.id, 'appliedPorts', appliedPorts, arg),
          regionId: data.id,
        });
        return null;
      });
      this.setState({ selectedRegions: filters });
    } else {
      this.setState({ selectedRegions: [] });
    }
  };

  shResetDisplay = () => {
    const { isMultipleVoyageFilter, itineraryFilters } = this.props;
    if (isMultipleVoyageFilter) {
      return false;
    }
    const isAnyRegionSelectedAtStore = itineraryFilters?.find((itinerary) => itinerary.isApplied);
    return isAnyRegionSelectedAtStore === undefined;
  };

  sortRegions = (itineraryFilters) =>
    itineraryFilters &&
    itineraryFilters.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });

  constructor(props) {
    super(props);
    this.state = {
      isItineraryChangeApplied: false,
      isMobile: false,
      reset: false,
      selectedRegion: {
        isScrolled: false,
        name: '',
        packages: [],
        ports: [],
        portsOfCall: {},
        regionId: '',
      },
      selectedRegions: [],
      showItineraries: false,
      showRegions: false,
      toggleValue: props.tabApplied || TOGGLE_VALUES.ITINERARIES,
    };
    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    if (window.innerWidth > 768) {
      this.setDefaultRegion();
    }
    this.setSelectedRegions('onLoad');
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', () => {
      if (window.innerWidth > breakpoints.md) {
        this.setState({ isScrolled: true });
      }
    });
  }

  componentDidUpdate(prevProps, _prevState, snapshot) {
    const {
      itineraryFilters,
      overallPackages,
      resetKey: { resetAll },
      tabApplied,
    } = this.props;

    const { overallPackages: overallPackagesPrev } = prevProps;

    const { isItineraryChangeApplied, isScrolled, reset, selectedRegion } = this.state;
    if (resetAll && !reset) {
      this.onReset();
    }
    if (get(prevProps, 'itineraryFilters') !== itineraryFilters && !isScrolled) {
      this.setSelectedRegions('onLoad');
    }
    if (
      snapshot === undefined &&
      this.isPackagesAndPortsEmptyForAllRegions() === undefined &&
      !reset &&
      !isItineraryChangeApplied
    ) {
      this.setSelectedRegions('onLoad');
    }

    const overallPackagesIDs = (overallPackages || []).map((p) => p.packageCode);
    const overallPackagesPrevIDs = (overallPackagesPrev || []).map((p) => p.packageCode);

    const arePackagesChanged = !isEqual(overallPackagesIDs, overallPackagesPrevIDs);
    if (arePackagesChanged || !selectedRegion.regionId) {
      this.setDefaultRegion();
    }

    if (tabApplied && tabApplied !== prevProps.tabApplied) {
      this.setState({ toggleValue: tabApplied });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', () => {
      this.setState({ isScrolled: false });
    });
  }

  getSnapshotBeforeUpdate(prevProps, prevState) {
    const { reset, selectedRegions } = this.state;

    if (prevState?.selectedRegions !== selectedRegions && !reset) {
      return prevState.selectedRegions;
    }
    return null;
  }

  handleWindowResize() {
    if (window.innerWidth <= 576) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  }

  onEvent = (...args) => {
    const locator = '.DestinationRefinement.-newFilter .FilterPopover.filterDestination';
    scrollIntoViewAsync(locator, undefined, { block: 'nearest' }, 300);
    this.props.onEvent?.(...args);
  };

  render() {
    const {
      portsOfCall,
      selectedRegion: { packages, ports, regionId },
      selectedRegion,
      showItineraries,
      showRegions,
      toggleValue,
    } = this.state;
    const { disabled, filterOpened, itineraryFilters, renderMainFilter } = this.props;
    const checkboxData =
      toggleValue === TOGGLE_VALUES.ITINERARIES
        ? this.checkboxRenderData(!isEmpty(selectedRegion) ? packages : [], 'packages', this.onPackageChange)
        : this.checkBoxRenderPorts(!isEmpty(selectedRegion) ? selectedRegion?.portsOfCall : [], this.onPortChange);
    const count = toggleValue === TOGGLE_VALUES.ITINERARIES ? packages && packages.length : ports && ports.length;
    const pill = {
      disabled,
      headerIcon: <FilterDestination />,
      icon: true,
      label: this.getPillLabel(),
      state: this.getPillState(),
      title: { defaultMessage: 'Destinations', id: 'filters.destination' },
      variant: 'large',
    };
    const popover = {
      ariaLabel: 'Destination',
      bodyclass: '-refinementOpen',
      className: 'DestinationRefinement -newFilter',
      disabled,
      id: 'Destination',
      isApplyDisabled: this.isApplyDisabled(),
      onDismiss: this.onDismiss,
      onRenderChange: this.onRenderChange,
      onReset: this.onReset,
      onSubmit: this.onApply,
      open: filterOpened,
      renderMainFilter,
      shResetDisplay: this.shResetDisplay(),
      title: { defaultMessage: 'Destination', id: 'Destination.heading' },
    };

    return (
      <Filter
        applyNewFilterUI
        label="Destination Options"
        newFilterDestination
        onEvent={this.onEvent}
        pill={pill}
        popover={popover}
        showItineraries={showItineraries}
        tabIndex={0}
        toggleAdvancedRefinement={this.resetItitneraryStateChange}
      >
        <div className="DestinationRefinement">
          <DestinationHeader
            count={count}
            getActiveClassNames={this.getActiveClassNames}
            isCheckSelected={this.isCheckSelected}
            isRegionSelected={this.isRegionSelected}
            regionId={regionId}
            selectedRegion={selectedRegion}
            showItineraries={showItineraries}
            toggleItineraries={() => this.setState({ showItineraries: false })}
            toggleRegions={() => this.setState({ showRegions: false })}
            toggleValue={toggleValue}
          />
          <DestinationDetails
            checkboxRenderData={checkboxData}
            getActiveClassNames={this.getActiveClassNames}
            isCheckSelected={this.isCheckSelected}
            isRegionSelected={this.isRegionSelected}
            isWholeSelected={this.isWholeSelected}
            itineraryFilters={itineraryFilters}
            onItineraryChange={this.onItineraryChange}
            onToggleChange={this.onToggleChange}
            portsOfCall={portsOfCall}
            selectedPackagesFn={this.selectedPackagesFn}
            selectedPortsFn={this.selectedPortsFn}
            showItineraries={showItineraries}
            showRegions={showRegions}
            toggleValue={toggleValue}
          />
        </div>
      </Filter>
    );
  }
}

DestinationRefinement.propTypes = {
  closeFilterModal: PropTypes.func,
  filterOpened: PropTypes.bool,
  hideTitle: PropTypes.bool,
  ifLockItInRatePromo: PropTypes.bool,
  onEvent: PropTypes.func,
  onRenderChange: PropTypes.func,
  renderMainFilter: PropTypes.func,
  selectedFilterPackages: PropTypes.arrayOf(PropTypes.shape({})),
  selectedFilterPorts: PropTypes.arrayOf(PropTypes.shape({})),
  setFilterOpened: PropTypes.func,
};
DestinationRefinement.defaultProps = {
  closeFilterModal: () => {},
  filterOpened: false,
  hideTitle: false,
  ifLockItInRatePromo: false,
  onEvent: () => {},
  onRenderChange: () => {},
  renderMainFilter: () => {},
  selectedFilterPackages: [],
  selectedFilterPorts: [],
};

DestinationRefinement.contextType = FormattedMessageContext;

const mapStateToProps = (state) => {
  const itineraryFilters = getFiltersForPackages(state);
  return {
    isMultipleVoyageFilter: isMultipleVoyageFilterActive(state),
    itineraryFilters: getFiltersForPackages(state),
    overallPackages: getPackageSearch(state).packages,
    // Both region and classifications filters structures
    resetKey: getResetKey(state),
    selectedFilterPackages: selectPackages(state),
    selectedFilterPorts: selectPorts(state),
    selectedRegionsFromStore: selectRegions(state),
    tabApplied: getCurrentTab(itineraryFilters),
  };
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      removeMultipleVoyageFilter,
      setPackgesInFilter: setPackgesInFilterAction,
      setPortsInFilter: setPortsInFilterAction,
      setRegionForPackages: setRegionForPackagesAction,
      setResetAll: setResetAllAction,
      setSelectedRegionIds: setSelectedRegionIdsAction,
      setTrackingDestinationFilter: trackingDestinationFilterAction,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withSearchParams(DestinationRefinement));

const findFirstFilterById = (itineraryFilters, id) => itineraryFilters?.filter((e) => e.id === id)?.[0];
const filterRegionsById = (regions, id) => regions?.filter((region) => region.regionId === id);
const findRegionById = (regions, id) => regions?.filter((region) => region.regionId === id);
