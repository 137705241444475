import SortIcon from '@publicImages/svg/sort.svg?icon';

import Select from '@/components/Select';
import { selectSortType } from '@/ducks/filters/selectors';
import { getDefaultFilters } from '@/ducks/filtersOptions';
import { setSortTypeForPackages } from '@/ducks/pages/chooseVoyage';
import { useAppDispatch, useAppSelector } from '@/store';

import { useSortOptions } from './hooks';

import classes from './SortRefinementForPackages.module.scss';

const SortRefinementForPackages = () => {
  const dispatch = useAppDispatch();

  const sortType = useAppSelector(selectSortType);
  const defaultSortType = useAppSelector((state) => getDefaultFilters(state).sortType);

  const onApply = (value: string) => {
    dispatch(setSortTypeForPackages(value));
  };

  const sortOptions = useSortOptions();

  return (
    <form className={classes.root}>
      <span aria-hidden="true" className={classes.title}>
        Sort:
      </span>
      <Select
        aria-label="Sort options"
        className={classes.select}
        defaultValue={defaultSortType}
        dropdownClassName={classes.portal}
        icon={<SortIcon />}
        onValueChange={onApply}
        options={sortOptions}
        value={sortType}
      />
    </form>
  );
};

export default SortRefinementForPackages;
