import Image from 'next/image';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import classNames from 'classnames';

import { FadeIn } from '@/components/Animations';
import SizzleReelsControls from '@/components/ChooseVoyage/PackageCardDetails/ImageVideo/SizzleReelsControls';
import Video from '@/components/Video';
import { VWOFlag, useVWOFlag } from '@/hooks/useVWOFeature';

import './ImageVideo.scss';

const ImageVideo = ({ controls, imgSrc, isImageHover, priority, sizzleReelsControls, videoSrc }) => {
  const isSizzleReelsCampaign = useVWOFlag(VWOFlag.SIZZLE_REELS);
  const videoRef = useRef(null);
  const isPlaying = isImageHover && isSizzleReelsCampaign;
  const isVideoEnabled = videoSrc && isSizzleReelsCampaign;
  const sizzleReelsControlsEnabled = sizzleReelsControls && isSizzleReelsCampaign;
  const controlsEnabled = controls && !sizzleReelsControlsEnabled;

  const className = classNames('ImageVideo', { _disableControls: !controlsEnabled });
  const [isVideoReady, setVideoReady] = useState(false);

  const onVideoReady = () => {
    if (isVideoEnabled) {
      setVideoReady(true);
    }
  };
  return (
    <div className={className}>
      {!isVideoReady && (
        <Image
          alt="Picture of the cruise"
          className="ImageVideo__imageItem"
          fill
          priority={priority}
          sizes="100vw, 100vh"
          src={imgSrc}
        />
      )}
      {isVideoEnabled && (
        <FadeIn in mountOnEnter timeout={500} unmountOnExit>
          <Video
            controls={controlsEnabled}
            loop
            muted
            onReady={onVideoReady}
            playing={isPlaying}
            playsinline
            video={videoSrc}
            videoRef={videoRef}
          />
        </FadeIn>
      )}
      {isVideoEnabled && sizzleReelsControlsEnabled && <SizzleReelsControls videoRef={videoRef} />}
    </div>
  );
};
ImageVideo.propTypes = {
  controls: PropTypes.bool,
  imgSrc: PropTypes.string.isRequired,
  isImageHover: PropTypes.bool,
  priority: PropTypes.bool,
  sizzleReelsControls: PropTypes.bool,
  videoSrc: PropTypes.string,
};

ImageVideo.defaultProps = {
  controls: false,
  isImageHover: false,
  priority: false,
  sizzleReelsControls: false,
  videoSrc: '',
};
export default ImageVideo;
