import { useEffect } from 'react';

import { INTERACTIVE_ELEMENTS_SELECTOR } from '../constants';

function useCustomArrowKeys({
  element,
  elementsQuery = undefined,
  focusableElementsQuery = undefined,
  isHorizontalArrowsIncluded = true,
  keyDownCallback = null,
}) {
  useEffect(() => {
    if (!element) {
      return;
    }

    const handleKeyDown = (event) => {
      const { key } = event;
      const allElements = Array.from(element.querySelectorAll(elementsQuery || INTERACTIVE_ELEMENTS_SELECTOR));
      const focusableElements = !focusableElementsQuery
        ? allElements
        : Array.from(element.querySelectorAll(focusableElementsQuery));

      const elementsCount = allElements.length;
      const currentIndex = allElements.findIndex((element) => element === document.activeElement);

      if (currentIndex === -1) {
        return;
      }

      const getNewIndex = (index) => {
        let newIndex = index;
        if (key === 'ArrowDown' || (isHorizontalArrowsIncluded && key === 'ArrowRight')) {
          event.preventDefault();
          newIndex = index + 1;
          if (newIndex > elementsCount - 1) {
            newIndex = elementsCount - 1;
          }
          return newIndex;
        } else if (key === 'ArrowUp' || (isHorizontalArrowsIncluded && key === 'ArrowLeft')) {
          event.preventDefault();
          newIndex = index - 1;
          if (newIndex < 0) {
            newIndex = 0;
          }
          return newIndex;
        }

        return -1;
      };

      let newIndex = currentIndex;
      let hasFocusable;

      do {
        newIndex = getNewIndex(newIndex);
        hasFocusable = focusableElements.includes(allElements[newIndex]);
      } while (newIndex > 0 && newIndex < elementsCount - 1 && !hasFocusable);

      if (hasFocusable) {
        allElements[newIndex].focus();
        keyDownCallback?.({
          element: allElements[newIndex],
          index: newIndex,
        });
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [element, isHorizontalArrowsIncluded, focusableElementsQuery, elementsQuery]);
}

export default useCustomArrowKeys;
