import React, { useCallback } from 'react';

import CloseWithoutBackground from '@/components/Icon/ChooseVoyage/CloseWithoutBackground';
import { resetFilterKeyForPackages } from '@/ducks/pages/chooseVoyage';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { resetAppliedFilterLabel } from '@/ducks/pages/chooseVoyage1.0';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import { FilterKey } from '@/infra/types/common/filters';
import { useAppDispatch } from '@/store';

const FILTER_KEYS_MAPPING = {
  [FilterKey.ACCESSIBLE]: 'accessible',
  [FilterKey.CABIN_TYPE]: 'cabinType',
  [FilterKey.DURATION]: 'durations',
  [FilterKey.PORT]: 'homePorts',
  [FilterKey.PRICE]: 'price',
  [FilterKey.SHIP]: 'shipList',
  [FilterKey.TRAVEL_PARTY]: 'sailors',
  [FilterKey.WEEKEND]: 'weekend',
};

const shLoadFilters = [FilterKey.CABIN_TYPE, FilterKey.TRAVEL_PARTY];

const SelectedFilterChip = ({
  filterKey,
  label,
  onBeforeRemoveFilter,
  onOpenFilterModal,
}: {
  filterKey: FilterKey;
  label: string;
  onBeforeRemoveFilter?: CallableFunction;
  onOpenFilterModal: CallableFunction;
}) => {
  const dispatch = useAppDispatch();

  const openFilterSelectionSection = useCallback(
    (filterName: FilterKey) => () => {
      onOpenFilterModal(filterName);
    },
    [onOpenFilterModal],
  );

  const removeSelectedFilter = useCallback(
    (key: FilterKey) => () => {
      const isAvailable = shLoadFilters.find((filterName) => filterName === key);
      const isFetchVoyages = !!isAvailable;
      onBeforeRemoveFilter?.();
      dispatch(resetFilterKeyForPackages(FILTER_KEYS_MAPPING[key], isFetchVoyages));
      dispatch(resetAppliedFilterLabel(key));
      tracking.trackDefaultFilter(key);
    },
    [dispatch, onBeforeRemoveFilter],
  );

  const onKeydownRemove = useOnKeyDown(removeSelectedFilter(filterKey));
  const onKeyDownOpenFilter = useOnKeyDown(openFilterSelectionSection(filterKey));

  return (
    <div className="filter" key={filterKey}>
      <div
        className="text"
        onClick={openFilterSelectionSection(filterKey)}
        onKeyDown={onKeyDownOpenFilter}
        role="button"
        tabIndex={0}
      >
        {label}
      </div>
      <div
        className="removeChipAction"
        onClick={removeSelectedFilter(filterKey)}
        onKeyDown={onKeydownRemove}
        role="button"
        tabIndex={0}
      >
        <CloseWithoutBackground />
      </div>
    </div>
  );
};

export default SelectedFilterChip;
