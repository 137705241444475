import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import Arrow from '@/components/Icon/Arrow';
import { useIsMobile } from '@/hooks/useIsDevice';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import Chevron from '../Icon/Chevron';
import AdvancedFilterMobileHOC from './AdvancedFilterMobileHOC';

const AdvancedFilterHOC = ({
  children,
  closeFilterModal,
  contentName,
  defaultValue,
  filterName,
  isOpen,
  toggleFilterSectionState,
}) => {
  const isMobile = useIsMobile();

  const toggleSection = (e) => {
    e.preventDefault();
    toggleFilterSectionState(filterName);
  };

  const onToggleSectionKeyDown = useOnKeyDown(toggleSection);

  return (
    <>
      <div className="row">
        <div className="col col1" onClick={toggleSection} onKeyDown={onToggleSectionKeyDown}>
          {filterName}
          <div className="border" />
        </div>
        <div className="col col2">
          <div
            aria-expanded={isOpen}
            className="header"
            onClick={toggleSection}
            onKeyDown={onToggleSectionKeyDown}
            role="button"
            tabIndex={0}
          >
            <p className="value">
              <span>{defaultValue}</span>
            </p>
            <button
              aria-expanded={isOpen}
              aria-label={Array.isArray(filterName) ? filterName.join(' ') : filterName}
              className={classNames('action')}
              onClick={toggleSection}
              onKeyDown={onToggleSectionKeyDown}
              tabIndex={-1}
            >
              {isMobile ? <Arrow ariaLabel="arrow left" /> : <Chevron position={isOpen ? 'up' : 'down'} />}
            </button>
          </div>
          {isMobile ? (
            <AdvancedFilterMobileHOC
              closeFilterModal={closeFilterModal}
              contentName={contentName}
              defaultValue={defaultValue}
              isOpen={isOpen}
              onTraverseBack={toggleSection}
              title={filterName}
            >
              {children}
            </AdvancedFilterMobileHOC>
          ) : (
            isOpen && <div className="content">{children}</div>
          )}
        </div>
      </div>
    </>
  );
};

AdvancedFilterHOC.propTypes = {
  children: PropTypes.oneOfType([PropTypes.objectOf]).isRequired,
  closeFilterModal: PropTypes.func.isRequired,
  contentName: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  filterName: PropTypes.string.isRequired,
  isDeparturePort: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  toggleContainerDisplay: PropTypes.func.isRequired,
  toggleFilterState: PropTypes.func.isRequired,
};

AdvancedFilterHOC.defaultProps = {
  contentName: '',
  isDeparturePort: false,
};

export default AdvancedFilterHOC;
