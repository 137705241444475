import PropTypes from 'prop-types';
import React from 'react';

import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';

import { Skeleton } from '@/components/Skeleton';
import UIResource from '@/components/UIResource';
import { getAppliedFilterLabels } from '@/ducks/appliedfilterLabels/appliedfilterlabel';
import { calcPriceRangeFromPackages } from '@/ducks/filters';
import { FILTER_TYPES } from '@/ducks/filters/constants';
import {
  selectCabinType,
  selectCurrencyCode,
  selectDeparturePorts,
  selectDurations,
  selectMaxPrice,
  selectMinPrice,
  selectPriceType,
  selectSailors,
  selectShipList,
} from '@/ducks/filters/selectors';
import { selectFilterByName } from '@/ducks/filters/values';
import { getDefaultFilters } from '@/ducks/filtersOptions';
import { setdefaultPriceRange } from '@/ducks/filtersOptions/helpers';
import {
  applyFilters,
  setPriceRangeForPackages as setPriceRangeActionForPackages,
  setPriceTypeForPackages as setPriceTypeActionForPackages,
} from '@/ducks/pages/chooseVoyage';
import { selectFilteredPackages } from '@/ducks/pages/chooseVoyage/filteredPackages/selectors';
import { selectPackages } from '@/ducks/pages/chooseVoyage/selectorDetails';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { setAppliedFilterLabels as setAppliedFilterLabelsAction } from '@/ducks/pages/chooseVoyage1.0';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import withSearchParams from '@/hocs/withSearchParams';
import tagmanager from '@/tagmanager';

import { BoxCheckbox, TextField } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

export const APPLY_FILTERS_DEBOUNCE_DELAY = 800;
export const INPUT_NUM_REGEXP = /^[0-9]+$/;

class PriceRangeFilter extends BaseFilter {
  handleChange = (priceType) => {
    const {
      calcPriceRangeFromPackages,
      filteredPackages,
      maxPrice,
      minPrice,
      priceType: prevPriceType,
      setPriceRangeForPackages,
      setPriceTypeForPackages,
      trackPriceType,
    } = this.props;
    if (priceType !== prevPriceType || filteredPackages.length === 0) {
      setPriceTypeForPackages(priceType);
      trackPriceType({ module: tagmanager.trackerConstants.FLYOUTS.REFINEMENT_RESULTS, priceType });

      const toBeAppliedPriceRange = calcPriceRangeFromPackages(priceType);
      this.setState({ packagesPriceRange: toBeAppliedPriceRange });
      if (maxPrice == null && minPrice == null) return;
      setPriceRangeForPackages(toBeAppliedPriceRange, false);
    }
  };

  onMaxPriceChange = (event) => {
    this.onPriceChange('max', event.target.value);
  };

  onMinPriceChange = (event) => {
    this.onPriceChange('min', event.target.value);
  };

  onPriceChange = (type, value) => {
    if (value && !INPUT_NUM_REGEXP.test(value)) {
      return;
    }

    const { applyPriceRangeFilters, defaultFilters, maxPrice, minPrice, setPriceRangeForPackages, trackPriceRange } =
      this.props;
    const numPrice = parseInt(value, 10) || 0;
    const selectedPrice =
      type === 'min'
        ? { maxPrice: maxPrice || defaultFilters?.maxPrice, minPrice: numPrice }
        : { maxPrice: numPrice, minPrice };
    setPriceRangeForPackages(selectedPrice, false);
    applyPriceRangeFilters();
    trackPriceRange(selectedPrice);
  };

  updateFilterLabel = (title) => {
    const { appliedPriceLabel, setAppliedFilterLabels } = this.props;
    if (appliedPriceLabel !== title) {
      setAppliedFilterLabels({ price: title });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      label: skeletonLabel,
      packagesPriceRange: { maxPrice: undefined, minPrice: undefined },
    };
  }

  componentDidMount() {
    const { applyDefaultPriceRange, calcPriceRangeFromPackages, packages, priceType } = this.props;

    if (!isEmpty(packages)) {
      const toBeAppliedPriceRange = calcPriceRangeFromPackages(priceType);
      applyDefaultPriceRange(toBeAppliedPriceRange);
      this.updatePriceRangeLabel();
    }
  }

  componentDidUpdate(prevProps) {
    const {
      cabinType,
      cabins,
      calcPriceRangeFromPackages,
      durations,
      isPackagesLoading,
      maxPrice,
      minPrice,
      packages,
      ports,
      priceType,
      sailors,
      ships,
    } = this.props;
    if (
      (prevProps.isPackagesLoading && !isPackagesLoading) ||
      !isEqual(prevProps.ports, ports) ||
      !isEqual(prevProps.ships, ships) ||
      !isEqual(prevProps.cabinType, cabinType) ||
      !isEqual(prevProps.durations, durations) ||
      !isEqual(prevProps.cabins, cabins) ||
      !isEqual(prevProps.sailors, sailors) ||
      !isEqual(prevProps.packages, packages)
    ) {
      const toBeAppliedPriceRange = calcPriceRangeFromPackages(priceType);
      this.setState({ packagesPriceRange: toBeAppliedPriceRange });
      this.updatePriceRangeLabel();
    }
    if (
      !isEqual(prevProps.priceType, priceType) ||
      !isEqual(prevProps.minPrice, minPrice) ||
      !isEqual(prevProps.maxPrice, maxPrice)
    ) {
      this.updatePriceRangeLabel();
    }
  }

  updatePriceRangeLabel() {
    const { appliedCurrency, calcPriceRangeFromPackages, isPackagesLoading, maxPrice, minPrice, priceType } =
      this.props;
    const { formatMessage } = this.context;

    let defaultMessage = '{n} - {m} per sailor';
    let id = 'AdvancedFilter.PriceRange.label.perSailor';
    if (priceType === 'perCabin') {
      defaultMessage = '{n} - {m} per cabin';
      id = 'AdvancedFilter.PriceRange.label.perCabin';
    } else if (priceType === 'SailorPerNight') {
      defaultMessage = '{n} - {m} Sailor per night';
      id = 'AdvancedFilter.PriceRange.label.SailorPerNight';
    }

    const toBeAppliedPriceRange = calcPriceRangeFromPackages(priceType);

    const updatedMaxPrice = maxPrice !== null ? maxPrice : toBeAppliedPriceRange?.maxPrice;
    const updatedMinPrice = minPrice !== null ? minPrice : toBeAppliedPriceRange?.minPrice;

    const labelString = formatMessage({
      defaultMessage,
      id,
      values: {
        m: `${getSymbolFromCurrenciesData(appliedCurrency)}${Math.floor(updatedMaxPrice || 0)}`,
        n: `${getSymbolFromCurrenciesData(appliedCurrency)}${Math.floor(updatedMinPrice || 0)}`,
      },
    })?.join('');
    this.setState({
      label: isPackagesLoading ? skeletonLabel : labelString,
    });
  }

  render() {
    const { formatMessage } = this.context;
    const { label, packagesPriceRange } = this.state;
    const { appliedCurrency, closeFilterModal, defaultFilters, isOpenFilterSection, maxPrice, minPrice, priceType } =
      this.props;

    const minPriceValue = minPrice ?? packagesPriceRange?.minPrice;
    const maxPriceValue = maxPrice ?? packagesPriceRange?.maxPrice;
    return (
      <div className="AF__row PriceRangeFilter__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          defaultValue={label}
          filterName={formatMessage({
            defaultMessage: 'Price Range {n}',
            id: 'AdvancedFilter.PriceRange',
            values: {
              n: getSymbolFromCurrenciesData(appliedCurrency),
            },
          })}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="action priceRange">
            <div className="row">
              <div className="minPrice">
                <TextField
                  defaultValue={minPriceValue ?? defaultFilters?.minPrice ?? 0}
                  form="priceRangeForm"
                  id="minPrice"
                  inputmode="numeric"
                  label={<UIResource id="AdvancedFilter.PriceRange.min.title" />}
                  labelClassname={'minPrice__label'}
                  name="minPrice"
                  onChange={this.onMinPriceChange}
                  pattern={INPUT_NUM_REGEXP.source}
                  tabIndex="0"
                  value={minPriceValue}
                />
              </div>
              <p className="to">
                <FormattedMessage defaultMessage="To" id="" />
              </p>
              <div className="maxPrice">
                <TextField
                  defaultValue={maxPriceValue ?? defaultFilters?.maxPrice}
                  form="priceRangeForm"
                  id="maxPrice"
                  inputmode="numeric"
                  label={<UIResource id="AdvancedFilter.PriceRange.max.title" />}
                  labelClassname={'maxPrice__label'}
                  name="maxPrice"
                  onChange={this.onMaxPriceChange}
                  pattern={INPUT_NUM_REGEXP.source}
                  tabIndex="0"
                  value={maxPriceValue}
                />
              </div>
              <form hidden id="priceRangeForm">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    this.toggleFilterSectionState();
                  }}
                  tabIndex={-1}
                  type="submit"
                />
              </form>
            </div>
            <div className="priceperDiv">
              <FormattedMessage defaultMessage="Price per:" id="AdvancedFilter.priceType.Priceper" />
            </div>
            <div className="perSailorDiv">
              <BoxCheckbox
                checked={priceType === 'perCabin'}
                name={formatMessage({
                  defaultMessage: 'Cabin',
                  id: 'AdvancedFilter.priceType.label.cabin',
                })}
                onBoxClick={() => this.handleChange('perCabin')}
              />
              <BoxCheckbox
                checked={priceType === 'perSailor'}
                name={formatMessage({
                  defaultMessage: 'Sailor',
                  id: 'AdvancedFilter.priceType.label.sailor',
                })}
                onBoxClick={() => this.handleChange('perSailor')}
              />
              <BoxCheckbox
                checked={priceType === 'SailorPerNight'}
                name={formatMessage({
                  defaultMessage: 'Sailor/night',
                  id: 'AdvancedFilter.priceType.label.sailorPerNight',
                })}
                onBoxClick={() => this.handleChange('SailorPerNight')}
              />
            </div>
          </div>
        </AdvancedFilterHOC>
      </div>
    );
  }
}

PriceRangeFilter.propTypes = {
  appliedCurrency: PropTypes.string.isRequired,
  appliedPriceLabel: PropTypes.string.isRequired,
  appliedSailors: PropTypes.string.isRequired,
  cabins: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeFilterModal: PropTypes.func.isRequired,
  filteredPackages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpenFilterSection: PropTypes.bool,
  maxPrice: PropTypes.number.isRequired,
  minPrice: PropTypes.number.isRequired,
  packages: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  priceType: PropTypes.string.isRequired,
  sailors: PropTypes.number.isRequired,
  setAppliedFilterLabels: PropTypes.func.isRequired,
  setPriceRangeForPackages: PropTypes.func.isRequired,
  setPriceTypeForPackages: PropTypes.func.isRequired,
  trackPriceRange: PropTypes.func,
  trackPriceType: PropTypes.func,
};

PriceRangeFilter.defaultProps = {
  isOpenFilterSection: false,
  trackPriceRange: () => {},
  trackPriceType: () => {},
};

PriceRangeFilter.contextType = FormattedMessageContext;

const debouncedApplyFiltersAction = debounce(applyFilters({ fetch: false }), APPLY_FILTERS_DEBOUNCE_DELAY);

const mapStateToProps = (state, { searchParams }) => {
  return {
    appliedCurrency: selectCurrencyCode(state),
    appliedPriceLabel: getAppliedFilterLabels(state).price,
    appliedSailors: selectSailors(state),
    cabinType: selectCabinType(state),
    cabins: selectFilterByName(state, FILTER_TYPES.cabins, searchParams),
    defaultFilters: getDefaultFilters(state),
    durations: selectDurations(state),
    filteredPackages: selectFilteredPackages(state),
    isPackagesLoading: selectIsFilteringLoading(state),
    maxPrice: selectMaxPrice(state),
    minPrice: selectMinPrice(state),
    packages: selectPackages(state),
    ports: selectDeparturePorts(state),
    priceType: selectPriceType(state),
    sailors: selectFilterByName(state, FILTER_TYPES.sailors, searchParams),
    ships: selectShipList(state),
  };
};

const mapDispatchToProps = {
  applyDefaultPriceRange: setdefaultPriceRange,
  applyPriceRangeFilters: () => debouncedApplyFiltersAction,
  calcPriceRangeFromPackages,
  setAppliedFilterLabels: setAppliedFilterLabelsAction,
  setPriceRangeForPackages: setPriceRangeActionForPackages,
  setPriceTypeForPackages: setPriceTypeActionForPackages,
  trackPriceRange: tracking.trackPriceRange,
  trackPriceType: tracking.trackPriceType,
};

export default withSearchParams(connect(mapStateToProps, mapDispatchToProps)(PriceRangeFilter));

const skeletonLabel = <Skeleton width={100} />;
