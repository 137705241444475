import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { connect } from 'react-redux';

import { FormCheck, FormGroup } from '@/components/elements';
import { setAppliedFilterLabels as setAppliedFilterLabelsAction } from '@/ducks/appliedfilterLabels/appliedfilterlabel';
import { selectAccessible } from '@/ducks/filters/selectors';
import { isAccessibleCabinAvailable, setAccessible as setAccessibleAction } from '@/ducks/pages/chooseVoyage';
import { FormattedMessage } from '@/helpers/formatted-message';

const AccessibilityRefinement = ({ accessible, isAccessibleDisabled, setAccessible, setAppliedFilterLabels }) => {
  const onAccessibleChange = useCallback(
    (event) => {
      const { checked } = event.target;
      setAccessible(checked);
      const cabinAccessible = checked ? 'Accessible' : '';
      setAppliedFilterLabels({ accessible: cabinAccessible });
    },
    [setAccessible, setAppliedFilterLabels],
  );

  const isCheckboxDisabled = isAccessibleDisabled || accessible;

  return (
    <form className="AccessibilityRefinement__packages" id="AccessibilityRefinementPackages">
      <FormGroup tabIndex={-1}>
        <FormCheck
          checked={accessible}
          disabled={!isCheckboxDisabled}
          id="accesscabin"
          inputTabIndex={0}
          onChange={onAccessibleChange}
          role="presentation"
          tabIndex={-1}
          type="checkbox"
        >
          <FormattedMessage
            defaultMessage="I need an Accessible Cabin"
            id="AdvancedRefinement.AccessibleCabin.checkbox"
          />
        </FormCheck>
      </FormGroup>
    </form>
  );
};

AccessibilityRefinement.propTypes = {
  accessible: PropTypes.bool.isRequired,
  isAccessibleDisabled: PropTypes.bool.isRequired,
  setAccessible: PropTypes.func.isRequired,
  setAppliedFilterLabels: PropTypes.func.isRequired,
};
AccessibilityRefinement.defaultProps = {};

const mapStateToProps = (state) => ({
  accessible: selectAccessible(state),
  isAccessibleDisabled: isAccessibleCabinAvailable(state),
});
const mapDispatchToProps = {
  setAccessible: setAccessibleAction,
  setAppliedFilterLabels: setAppliedFilterLabelsAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(AccessibilityRefinement);
