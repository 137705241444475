import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { selectLookup } from '@/ducks/common/selectors';
import { selectShipList } from '@/ducks/filters/selectors';
import { setSelectedShipsForPackages as setSelectedShipsActions } from '@/ducks/pages/chooseVoyage';
import { setAppliedFilterLabels as setAppliedFilterLabelsAction } from '@/ducks/pages/chooseVoyage1.0';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import { getLabelforShips } from '@/helpers/util';

import { FormCheck } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

class ShipFilter extends BaseFilter {
  getDefaultTitle = () => {
    const { formatMessage } = this.context;
    const title = formatMessage({
      defaultMessage: 'Any ladyship',
      id: 'AdvancedFilter.Ship.Default.value',
    });
    return title;
  };

  onSelection = (ship) => {
    const { selectedships, setSelecShips } = this.props;
    const selected = selectedships?.find((currShip) => currShip.id === ship.id);

    if (!selected) {
      setSelecShips([...selectedships, ship]);
      return;
    }
    const shipList = selectedships.filter((secShip) => secShip.id !== ship.id);

    setSelecShips(shipList);
  };

  updateFilterLabel = () => {
    const { labels, selectedships, setAppliedFilterLabels } = this.props;
    const { formatMessage } = this.context;
    const title = getLabelforShips(selectedships, formatMessage);
    const shipTitle = title || this.getDefaultTitle();
    this.setState({ label: shipTitle });
    if (labels.ship !== title) {
      setAppliedFilterLabels({ ship: title });
    }
  };

  constructor(props) {
    super(props);
    this.state = {
      label: '',
    };
  }

  componentDidMount() {
    this.updateFilterLabel();
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.selectedships !== this.props?.selectedships) {
      this.updateFilterLabel();
    }
  }

  render() {
    const { label } = this.state;
    const { closeFilterModal, isOpenFilterSection, mainSailings, metaShipList, selectedships } = this.props;
    const { formatMessage } = this.context;
    const shipText = formatMessage({
      defaultMessage: 'Ship',
      id: 'AdvancedFilter.Ship',
    });
    return (
      <div className="AF__row ShipFilter__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          contentName="ship-filter"
          defaultValue={label}
          filterName={shipText}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="action ship-list">
            {!isEmpty(metaShipList) &&
              metaShipList.map((ship) => (
                <FormCheck
                  checked={!isEmpty(selectedships?.find((selecShip) => selecShip?.id === ship?.id))}
                  disabled={!mainSailings.find((sailing) => sailing?.ship?.code === ship?.id)}
                  id={`ship_${ship?.id}`}
                  inputTabIndex={0}
                  key={ship?.id}
                  name={`ship_${ship?.id}`}
                  onChange={() => {
                    this.onSelection(ship);
                  }}
                  role="presentation"
                  tabIndex={-1}
                  type="checkbox"
                >
                  <p className="name">{ship?.name}</p>
                  <p className="year">{ship?.inaugurationDate}</p>
                </FormCheck>
              ))}
          </div>
          <p className="info">
            <FormattedMessage
              defaultMessage="All our ladyships are of the same luxury design; and adults-only. "
              id="AdvancedFilter.ship.info"
            />
          </p>
        </AdvancedFilterHOC>
      </div>
    );
  }
}

ShipFilter.contextType = FormattedMessageContext;

ShipFilter.propTypes = {
  closeFilterModal: PropTypes.func.isRequired,
  isOpenFilterSection: PropTypes.bool,
  labels: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  mainSailings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  metaShipList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setAppliedFilterLabels: PropTypes.func.isRequired,
  setSelecShips: PropTypes.func.isRequired,
};

ShipFilter.defaultProps = {
  isOpenFilterSection: false,
};

const mapStateToProps = (state) => ({
  labels: get(state, 'appliedfilterLabels', {}),
  mainSailings: get(state, 'chooseVoyageNew.mainPackages.sailings', []),
  metaShipList: selectLookup(state)?.ships ?? [],
  selectedships: selectShipList(state),
});

const mapDispatchToProps = {
  setAppliedFilterLabels: setAppliedFilterLabelsAction,
  setSelecShips: setSelectedShipsActions,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShipFilter);
