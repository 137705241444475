import React, { useContext, useMemo } from 'react';

import { selectWeekend } from '@/ducks/filters/selectors';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { FilterKey } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const WeekendFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const weekend = useAppSelector(selectWeekend);
  const { formatMessage } = useContext(FormattedMessageContext);

  const label = useMemo(() => {
    if (!weekend) {
      return null;
    }

    return formatMessage({
      defaultMessage: 'Weekend voyages',
      id: 'ZeroResults.WeekendVoyages.txt',
    });
  }, [weekend, formatMessage]);

  return label ? (
    <SelectedFilterChip filterKey={FilterKey.WEEKEND} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default WeekendFilterChip;
