'use client';

import Search from '@/components/Icon/Search';
import { Skeleton } from '@/components/Skeleton';
import { SKELETON_COLOR_SCHEMES } from '@/components/Skeleton/constants';
import Button from '@/components/elements/Button';
import { useUIResource } from '@/ducks/common/resources';

import './Card.scss';

const styles = SKELETON_COLOR_SCHEMES['gray-216-gray-410'];

type Props = {
  isLoading?: boolean;
  onClick?: (region: string) => void;
  price?: React.ReactNode;
  priceOld?: React.ReactNode;
  priceType?: React.ReactNode;
  title?: React.ReactNode;
  url?: string;
};

export const QuickSearchCard = ({ isLoading, onClick, price, priceOld, priceType, title, url }: Props) => {
  const from = useUIResource('QuickSearch.fromPrice');
  const onCardClick = () => onClick?.(String(title));

  return (
    <a className="QuickSearchCard" href={url} onClick={onCardClick} onKeyUp={onCardClick} tabIndex={0}>
      <div className="description">
        <p className="title">{isLoading ? <Skeleton {...styles} rounded width={100} /> : title}</p>
        <div className="prices">
          <span className="prices__priceNew">
            {isLoading ? <Skeleton {...styles} rounded width={50} /> : `${from} ${price}`}
          </span>
          <span className="prices__priceOld">{isLoading ? <Skeleton {...styles} rounded width={50} /> : priceOld}</span>
        </div>
        <p className="priceType">{isLoading ? <Skeleton {...styles} rounded width={70} /> : priceType}</p>
      </div>
      <div className="cta">
        <Button variant="primary">
          <Search />
        </Button>
      </div>
    </a>
  );
};
