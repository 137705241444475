import { useMemo } from 'react';

import { useUIResourcePool } from '@/ducks/common/resources';

export const useSortOptions = () => {
  const { date, durationAsc, durationDesc, priceAsc, priceDesc } = useUIResourcePool({
    date: 'VoyageSort.Date',
    durationAsc: 'VoyageSort.Shortest',
    durationDesc: 'VoyageSort.Longest',
    priceAsc: 'VoyageSort.PriceLowest',
    priceDesc: 'VoyageSort.PriceHighest',
  });
  return useMemo(
    () => [
      {
        enabled: true,
        label: date,
        value: 'date',
      },
      {
        enabled: true,
        label: durationDesc,
        value: 'duration-desc',
      },
      {
        enabled: true,
        label: durationAsc,
        value: 'duration-asc',
      },
      {
        enabled: true,
        label: priceDesc,
        value: 'price-desc',
      },
      {
        enabled: true,
        label: priceAsc,
        value: 'price-asc',
      },
    ],
    [date, durationAsc, durationDesc, priceAsc, priceDesc],
  );
};
