import { useCallback } from 'react';

import PriceTypeIcon from '@publicImages/svg/price-type.svg?icon';

import Select from '@/components/Select';
import { selectDefaultFilterPriceType } from '@/ducks/filtersOptions/selectors';
import { setPriceTypeForPackages as setPriceTypeAction } from '@/ducks/pages/chooseVoyage';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import usePriceType from '@/hooks/usePriceType';
import { useAppDispatch, useAppSelector } from '@/store';

import { usePriceTypeOptions, useUpdatePriceRangeOnPriceTypeChangeHandler } from './hooks';

import classes from './SortPriceType.module.scss';

const SortPriceType = () => {
  const dispatch = useAppDispatch();

  const priceType = usePriceType();
  const defaultPriceType = useAppSelector(selectDefaultFilterPriceType);

  const PriceTypeOptions = usePriceTypeOptions();

  const updatePriceRangeOnPriceTypeChange = useUpdatePriceRangeOnPriceTypeChangeHandler();
  const onApply = useCallback(
    (id: string) => {
      dispatch(setPriceTypeAction(id));
      updatePriceRangeOnPriceTypeChange(id);

      dispatch(tracking.trackPriceType({ priceType: id }));
    },
    [dispatch, updatePriceRangeOnPriceTypeChange],
  );

  return (
    <form className={classes.root}>
      <span aria-hidden={true} className={classes.title}>
        Price:
      </span>
      <Select
        aria-label="Sort price type"
        className={classes.select}
        defaultValue={defaultPriceType}
        dropdownClassName={classes.portal}
        icon={<PriceTypeIcon />}
        onValueChange={onApply}
        options={PriceTypeOptions}
        value={priceType}
      />
    </form>
  );
};

export default SortPriceType;
