import PropTypes from 'prop-types';
import React from 'react';

import { endOfMonth, getYear, startOfMonth } from 'date-fns';

import { format, parse } from '@/helpers/util/dateUtil';

import YearGroup from './YearGroup';
import { createMonthList, getIndexFromMonthList, groupIntoYears, isDefined } from './utils';

import './DatePicker.scss';

const propTypes = {
  applyNewFilterUI: PropTypes.bool,
  isShowHeader: PropTypes.bool,
  /** Last available voyage date, in the format YYYY-MM-DD */
  maxDate: PropTypes.string.isRequired,
  /** First available voyage date, in the format YYYY-MM-DD */
  minDate: PropTypes.string.isRequired,
  /** Callback when months are selected.
   * @param {Date} from - The minumum selected date. Will be the first day of that
   * selected month, at midnight
   * @param {Date} to - The maximum selected date. Will be the last day of that
   * selected month, at midnight
   */
  onSelectMonth: PropTypes.func.isRequired,
  /** Date at which to end the selected range from in the format YYYY-MM-DD. Can be any day of the month.  */
  rangeEnd: PropTypes.string,
  /** Date at which to start the selected range from in the format YYYY-MM-DD. Can be any day of the month.  */
  rangeStart: PropTypes.string,
  resetSelectedDates: PropTypes.func.isRequired,
};

const defaultProps = {
  applyNewFilterUI: false,
  isShowHeader: true,
  rangeEnd: null,
  rangeStart: null,
};

class DatePicker extends React.Component {
  constructor(props) {
    super(props);

    this.onMonthClick = this.onMonthClick.bind(this);
    this.onMonthHover = this.onMonthHover.bind(this);
    this.onLeftArrowClick = this.onLeftArrowClick.bind(this);
    this.onRightArrowClick = this.onRightArrowClick.bind(this);
    this.updateStates = this.updateStates.bind(this);
    this.handleWindowResize = this.handleWindowResize.bind(this);
    const calendarStates = this.setCalendar();
    this.state = {
      groupedYrs: calendarStates.groupedYears,
      // Month index that is the current hover/focus
      hoverIdx: calendarStates.hoverIdx,
      isMobile: false,

      // The selected latest date index
      rangeEnd: calendarStates.rangeEnd,
      // The selected earliest date index
      rangeStart: calendarStates.rangeStart,
      yearIndex: calendarStates.yearIndex,
    };
  }

  componentDidMount() {
    this.handleWindowResize();
    window.addEventListener('resize', this.handleWindowResize);
  }

  /**
   * Update internal state if necessary.
   */
  componentDidUpdate(prevProps) {
    const { rangeEnd, rangeStart } = this.props;
    if (rangeStart !== prevProps.rangeStart || rangeEnd !== prevProps.rangeEnd) {
      const rangeIdx = this.getRangeIdxFromProps();

      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        rangeEnd: rangeIdx.end,
        rangeStart: rangeIdx.start,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  /**
   * Extract the startRange and endRange from supplied props. Find the indexes
   * in the monthList which match these dates.
   * @returns {Object}
   */
  getRangeIdxFromProps() {
    const { rangeEnd, rangeStart } = this.props;
    let start = null;
    let end = null;

    if (rangeStart) {
      const rangeStartDate = startOfMonth(parse(rangeStart));
      start = getIndexFromMonthList(rangeStartDate, this.monthList);
    }
    if (rangeEnd) {
      const rangeEndDate = startOfMonth(parse(rangeEnd));
      end = getIndexFromMonthList(rangeEndDate, this.monthList);
    }
    return {
      end,
      start,
    };
  }

  handleWindowResize() {
    if (window.innerWidth < 768) {
      this.setState({
        isMobile: true,
      });
    } else {
      this.setState({
        isMobile: false,
      });
    }
  }

  onLeftArrowClick(idx) {
    this.setState({ yearIndex: idx - 1 });
  }

  /**
   * Handle click of a month
   * @param {number} idx
   */
  onMonthClick(idx) {
    const { rangeEnd, rangeStart } = this.state;
    if (isDefined(rangeStart) && isDefined(rangeEnd)) {
      this.setRange(idx, null);
      return;
    }

    if (isDefined(rangeStart)) {
      if (idx < rangeStart) {
        this.setRange(idx, null);
      } else {
        this.setRange(rangeStart, idx);
      }
      return;
    }
    this.setRange(idx, null);
  }

  /**
   * Set the month index that the mouse is hovering over, or the focus is on
   * @param {number} idx
   */
  onMonthHover(idx) {
    this.setState({
      hoverIdx: idx,
    });
  }

  onRightArrowClick(idx) {
    this.setState({ yearIndex: idx + 1 });
  }

  render() {
    const { groupedYrs, hoverIdx, isMobile, rangeEnd, rangeStart, yearIndex } = this.state;
    const { applyNewFilterUI, isShowHeader } = this.props;
    if (groupedYrs.length === 0) {
      return null;
    }
    const displayedYears = isMobile
      ? groupedYrs
      : groupedYrs.slice(yearIndex, Math.min(yearIndex + 2, groupedYrs.length));

    const previousYear = groupedYrs[yearIndex];
    if (!previousYear) {
      return null;
    }
    return (
      <>
        {!applyNewFilterUI ? (
          <div className="DatePicker">
            <YearGroup
              hoverIdx={hoverIdx}
              isLeftArrowDisabled={yearIndex === 0}
              isRightArrowDisabled={yearIndex === groupedYrs.length - 1}
              isShowHeader={isShowHeader}
              key={previousYear.year}
              label={`${previousYear.year}`}
              months={previousYear.months}
              onLeftArrowClick={this.onLeftArrowClick}
              onMonthClick={this.onMonthClick}
              onMonthHover={this.onMonthHover}
              onRightArrowClick={this.onRightArrowClick}
              rangeEnd={rangeEnd}
              rangeStart={rangeStart}
              resetYears={this.updateStates}
              yearIndex={yearIndex}
            />
          </div>
        ) : (
          <div className="DatePicker -newFilter">
            {!isMobile &&
              displayedYears.map((year, idx) => {
                const hasLess = yearIndex + idx > 0;
                const hasMore = yearIndex + idx < groupedYrs.length - 1;
                return (
                  <YearGroup
                    applyNewFilterUI={applyNewFilterUI}
                    hideLeftArrow={idx !== 0 || !hasLess}
                    hideRightArrow={idx === 0 || !hasMore}
                    hoverIdx={hoverIdx}
                    isLeftArrowDisabled={!hasLess}
                    isRightArrowDisabled={!hasMore}
                    isShowHeader={isShowHeader}
                    key={year.year}
                    label={`${year.year}`}
                    months={year.months}
                    onLeftArrowClick={this.onLeftArrowClick}
                    onMonthClick={this.onMonthClick}
                    onMonthHover={this.onMonthHover}
                    onRightArrowClick={this.onRightArrowClick}
                    rangeEnd={rangeEnd}
                    rangeStart={rangeStart}
                    resetYears={this.updateStates}
                    yearIndex={yearIndex + idx}
                  />
                );
              })}
            {isMobile &&
              displayedYears.map((year) => (
                <YearGroup
                  applyNewFilterUI={applyNewFilterUI}
                  hideBothArrows={isMobile}
                  hoverIdx={hoverIdx}
                  isLeftArrowDisabled={yearIndex === 0}
                  isRightArrowDisabled={yearIndex === groupedYrs.length - 2 || yearIndex === 0}
                  isShowHeader={isShowHeader}
                  key={year.year}
                  label={`${year.year}`}
                  months={year.months}
                  onLeftArrowClick={this.onLeftArrowClick}
                  onMonthClick={this.onMonthClick}
                  onMonthHover={this.onMonthHover}
                  onRightArrowClick={this.onRightArrowClick}
                  rangeEnd={rangeEnd}
                  rangeStart={rangeStart}
                  resetYears={this.updateStates}
                  yearIndex={yearIndex}
                />
              ))}
          </div>
        )}
      </>
    );
  }

  setCalendar() {
    // Round dates down to the first of the month
    const { applyNewFilterUI, maxDate, minDate, rangeStart: rangeStartDate } = this.props;
    // Create a list of months that runs from the min to the
    // max dates. List is padded on left/right so that the the calendar
    // rounds up/down to the nearest year
    // The month object is the main structure type which is used to pass data around
    // the component.
    const rangeStartTime = parse(rangeStartDate);
    const minTime = parse(minDate);
    const maxTime = parse(maxDate);
    this.monthList = createMonthList(minTime, maxTime, 2);

    // Group months into years so we can render them
    const groupedYears = groupIntoYears(this.monthList);
    const rangeIdx = this.getRangeIdxFromProps();

    const rangeStartYear = getYear(rangeStartTime);
    const minYear = getYear(minTime);
    const maxYear = getYear(maxTime);
    const yearIndex =
      rangeStartYear - (applyNewFilterUI && rangeStartYear === maxYear && minYear !== maxYear ? minYear + 1 : minYear);

    const hoverIdx = null;
    const rangeStart = rangeIdx.start;
    const rangeEnd = rangeIdx.end;

    return {
      groupedYears,
      hoverIdx,
      rangeEnd,
      rangeStart,
      yearIndex,
    };
  }

  /**
   * Convenience method to set the start/end range indexes with setState,
   * and callback with the selected actual dates
   * @param {number} rangeStart
   * @param {number} rangeEnd
   */
  setRange(rangeStart, rangeEnd) {
    const { onSelectMonth } = this.props;

    this.setState({
      rangeEnd,
      rangeStart,
    });

    // Lookup final values from monthList to call back with.
    // Start month date as string. Will be the 1st of the month.
    // null if there is no start month set

    const startDate = (this.monthList[rangeStart] && format(this.monthList[rangeStart].date)) || null;

    // End month, extended to the last day of that month, returned as a string
    // null if there is no end month set
    const endDate = (this.monthList[rangeEnd] && format(endOfMonth(this.monthList[rangeEnd].date))) || null;
    onSelectMonth(startDate, endDate);
  }

  updateStates() {
    const { resetSelectedDates } = this.props;
    const calendarStates = this.setCalendar();
    this.setState({
      groupedYrs: calendarStates.groupedYears,
      rangeEnd: calendarStates.rangeEnd,
      rangeStart: calendarStates.rangeStart,
      yearIndex: calendarStates.yearIndex,
    });
    if (resetSelectedDates) {
      resetSelectedDates();
    }
  }
}

DatePicker.propTypes = propTypes;
DatePicker.defaultProps = defaultProps;

export default DatePicker;
