import type { TCurrencyCode } from '@/infra/types/common';
import type { RootState } from '@/store';

import { getCookie as getCookieClient } from '@/ducks/cookies';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { selectAgencyCurrencyCode, selectAgencyDetails, selectFilterAgencyIsAvailable } from '@/ducks/fm/selectors';
import { selectMnvvCurrencyCode } from '@/ducks/mnvv/selectors';
import { getMnvvReservation } from '@/ducks/mnvv/utils';

// Temporary function. will be removed during filters refactoring merged
export const getCurrencyCode = (state: RootState, searchParams: URLSearchParams, getCookie = getCookieClient) => {
  const mnvvCurrencyCode = selectMnvvCurrencyCode(state);

  if (getMnvvReservation(searchParams).isMNVV && mnvvCurrencyCode) {
    return mnvvCurrencyCode as TCurrencyCode;
  }

  let currencyCode = searchParams.get('currencyCode');

  if (selectFilterAgencyIsAvailable(state)) {
    if (currencyCode) {
      const agentData = selectAgencyDetails(state);
      currencyCode = agentData?.multiCurrencies?.find((code) => code === currencyCode) || null;
    }

    if (!currencyCode) {
      currencyCode = selectAgencyCurrencyCode(state);
    }
  }

  return (currencyCode || getCookie('currencyCode') || selectCurrencyCode(state) || 'USD') as TCurrencyCode;
};
