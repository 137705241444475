import Image from 'next/image';
import { useCallback, useEffect, useState } from 'react';

import cn from 'classnames';

import type { TImage } from '@/infra/types/common/base';

import Flyout from '@/components/Flyout';
import FlyoutIcon from '@/components/Icon/FlyOut';
import ShareButton from '@/components/ShareButton';
import { useUIResource } from '@/ducks/common/resources';
import { selectConfigServiceFeatureFlags } from '@/ducks/common/settings';
import { applyFilterForPromotion } from '@/ducks/pages/chooseVoyage1.0';
import { useGetBFResources } from '@/ducks/programs/blackFriday/hooks';
import { useAppDispatch, useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import PromotionBannerFlyout from './PromotionBannerFlyout';

import './PromotionBannerCard.scss';

type TProps = {
  isSinglePackage?: boolean;
};

const PromotionBannerCard = ({ isSinglePackage }: TProps) => {
  const dispatch = useAppDispatch();
  const showPromotionBanner = useAppSelector(selectConfigServiceFeatureFlags)?.merchandisingBanner;
  const promotionBanner = useGetBFResources() as TPromotionBannerSlice;
  const {
    backgroundColor,
    bannerEyebrow,
    bannerImage,
    bannerMainPromoBenefit,
    bannerShareLink,
    bannerValueProp,
    id,
    mobileBannerImage,
    tabletBannerImage,
    title,
  } = promotionBanner || {};
  const buttonText = useUIResource('PromotionBannerCard.LearnMore');
  const [isFlyoutOpened, setIsFlyoutOpened] = useState(false);
  const [image, setImage] = useState<string>();

  const applyImage = useCallback(() => {
    // TODO: rework this with css vars and standard media query
    let newImage = bannerImage?.src;
    if (!isSinglePackage) {
      if (window.innerWidth >= 992) {
        newImage = bannerImage?.src;
      } else if (window.innerWidth >= 768) {
        newImage = tabletBannerImage?.src;
      } else {
        newImage = mobileBannerImage?.src;
      }
    } else if (window.innerWidth >= 1199) {
      newImage = bannerImage?.src;
    } else {
      newImage = mobileBannerImage?.src;
    }
    setImage(newImage);
  }, [bannerImage?.src, tabletBannerImage?.src, mobileBannerImage?.src]);

  useEffect(() => {
    setImage(bannerImage?.src);
    applyImage();
    window.addEventListener('resize', applyImage);
    return () => {
      window.removeEventListener('resize', applyImage);
    };
  }, [bannerImage?.src]);

  const openFlyoutHandler = useCallback(() => {
    setIsFlyoutOpened(true);

    const titleText = `${title}${bannerMainPromoBenefit || ''}`;
    tagmanager.tracker.common.trackLinkClick({
      labels: [buttonText, titleText, tagmanager.trackerConstants.FLYOUTS.SAIL_WITH_CELEBRATIONS_MODULE],
    });
  }, [buttonText, setIsFlyoutOpened]);

  const onShareClick = useCallback(
    (isFlyout: boolean) => (shareType: string) => {
      const moduleOrFlyout = isFlyout
        ? tagmanager.trackerConstants.FLYOUTS.SAIL_WITH_CELEBRATIONS
        : tagmanager.trackerConstants.FLYOUTS.SAIL_WITH_CELEBRATIONS_MODULE;
      const titleText = `${title}${bannerMainPromoBenefit || ''}`;
      tagmanager.tracker.common.trackSocialShare({ labels: [shareType, titleText, moduleOrFlyout] });
    },
    [setIsFlyoutOpened],
  );

  const closeFlyoutHandler = useCallback(() => {
    setIsFlyoutOpened(false);
  }, [setIsFlyoutOpened]);

  const filterSailingsHandler = useCallback(
    (buttonText: string) => {
      tagmanager.tracker.common.trackButtonClick({
        labels: [
          tagmanager.eventBuilder.buildCtaLabel(buttonText),
          tagmanager.trackerConstants.FLYOUTS.SAIL_WITH_CELEBRATIONS,
        ],
      });

      dispatch(applyFilterForPromotion(id));
      closeFlyoutHandler();
    },
    [closeFlyoutHandler, id],
  );
  const promoClasses = cn('PromotionBannerCard', `PromotionBannerCard_theme_${backgroundColor || 'light'}`, {
    isSinglePackage,
  });

  if (!showPromotionBanner) {
    return null;
  }
  return (
    <>
      <div className={promoClasses}>
        <Image alt="Picture of the promotion" className="image" fill sizes="100vw, 100vh" src={image!} />
        <ShareButton
          isShortLink
          link={bannerShareLink}
          modalAlign="end"
          modalSide="bottom"
          onShare={onShareClick(false)}
        />
        <div className="PromotionBannerCard__content">
          <p className="PromotionBannerCard__eyebrow">{bannerEyebrow}</p>
          <h3 className="PromotionBannerCard__title">{title}</h3>
          {bannerMainPromoBenefit && <h3 className="PromotionBannerCard__title">{bannerMainPromoBenefit}</h3>}
          <p className="PromotionBannerCard__proposition">{bannerValueProp}</p>

          <button
            aria-label="open promotion banner flyout"
            className="PromotionBannerCard__more-button"
            onClick={openFlyoutHandler}
            tabIndex={0}
            type="button"
          >
            {buttonText}
            <FlyoutIcon />
          </button>
        </div>
      </div>

      {isFlyoutOpened && (
        <Flyout
          className="PromotionBannerFlyout"
          direction="right"
          onDismiss={closeFlyoutHandler}
          open={isFlyoutOpened}
        >
          <PromotionBannerFlyout
            filterSailings={filterSailingsHandler}
            onShareClick={onShareClick(true)}
            promotionBanner={promotionBanner}
          />
        </Flyout>
      )}
    </>
  );
};

type TPromotionBannerSlice = {
  backgroundColor: string;
  bannerEyebrow: string;
  bannerImage: TImage;
  bannerMainPromoBenefit: string;
  bannerShareLink: string;
  bannerValueProp: string;
  id: string;
  mobileBannerImage: TImage;
  tabletBannerImage: TImage;
  title: string;
};

export default PromotionBannerCard;
