import PropTypes from 'prop-types';
import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { selectCabins, selectCurrencyCode, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import { setAppliedFilterLabels as setAppliedFilterLabelsAction } from '@/ducks/pages/chooseVoyage1.0';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import { getLabelforCabinType, getSessionStorageValue } from '@/helpers/util';
import withSearchParams from '@/hocs/withSearchParams';

import CabinPrice from '../CabinCard/CabinPrice';
import { FormCheck } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

class CabinTypeFilter extends BaseFilter {
  checkSetCabinType = () => {
    const { onCabinTypeChange } = this.props;
    const { formatMessage } = this.context;
    let defaultVal = formatMessage({
      defaultMessage: 'Any — the best value available',
      id: 'AdvancedFilter.cabintype.Default.value',
    });
    [defaultVal] = defaultVal;
    onCabinTypeChange(defaultVal);
  };

  componentDidMount() {
    const { onRenderChange, searchParams } = this.props;
    onRenderChange(false);
    const cabinType = searchParams.get('cabinType');
    if (cabinType && (cabinType === 'TZ' || cabinType === 'IZ')) {
      this.checkSetCabinType(cabinType);
    }
    this.updateFilterLabel();
  }

  componentDidUpdate(prevProps) {
    const { selectedType } = this.props;
    if (prevProps?.selectedType !== selectedType) {
      this.updateFilterLabel();
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      label: '',
    };
  }

  getAmountFromGenericCatergoryCodes = (code) => {
    const { defaultGenericCategoryCodes, genericCategoryCodes } = this.props;
    if (isEmpty(genericCategoryCodes)) {
      const category = defaultGenericCategoryCodes?.find(
        (masterCategory) => masterCategory.genericCategoryCode === code,
      );
      return category;
    }
    const category = genericCategoryCodes?.find((masterCategory) => masterCategory.genericCategoryCode === code);
    return category;
  };

  getDefaultLabel = () => {
    const { formatMessage } = this.context;
    const title = formatMessage({
      defaultMessage: 'Any - best available',
      id: 'AdvancedFilter.Cabin.Default.value',
    });
    return title;
  };

  isDisabled(code) {
    const isAmountPrsnt = this.getAmountFromGenericCatergoryCodes(code);
    return !!isAmountPrsnt;
  }

  render() {
    const { label } = this.state;
    const {
      cabinTypes,
      cabins,
      closeFilterModal,
      currencyCode,
      isOpenFilterSection,
      onCabinTypeChange,
      priceType,
      sailors,
      searchParams,
      selectedType,
    } = this.props;
    const { formatMessage } = this.context;
    let defaultVal = formatMessage({
      defaultMessage: 'Any — the best value available',
      id: 'AdvancedFilter.cabintype.Default.value',
    });
    [defaultVal] = defaultVal;
    const cabinText = formatMessage({
      defaultMessage: 'Cabin type',
      id: 'AdvancedFilter.CabinType',
    });
    let isCabinTypeDisable = false;
    const cabinType = searchParams.get('cabinType');
    const lockitinrateblitz = getSessionStorageValue('lockitinrateblitz');
    if (lockitinrateblitz && cabinType && (cabinType === 'TZ' || cabinType === 'IZ')) {
      isCabinTypeDisable = true;
    }
    return (
      <div className="AF__row CabinTypeFilter__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          defaultValue={label}
          filterName={cabinText}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="cabinType-list action">
            {cabinTypes &&
              cabinTypes.map((meta) => (
                <div
                  className="CabinTypeRefinement__card"
                  key={meta.code}
                  onClick={() => {
                    if (selectedType !== meta.code && !isCabinTypeDisable) {
                      onCabinTypeChange(meta.code);
                    }
                  }}
                  role="button"
                  tabIndex={-1}
                >
                  <div className="CabinTypeRefinement__cardRadio" id="cardRadio">
                    <FormCheck
                      checked={selectedType === meta.code}
                      disabled={isCabinTypeDisable}
                      id={meta.code}
                      inputTabIndex={0}
                      name={meta.name}
                      role="presentation"
                      tabIndex={-1}
                      type="radio"
                      value={meta.code}
                    />
                  </div>
                  <div className="CabinTypeRefinement__cardImage">
                    {meta.imageUrl && (
                      <div className="CabinTypeRefinement__image">
                        <img alt="CabinTypeRefinement-img" aria-hidden src={meta?.imageUrl?.src} />
                      </div>
                    )}

                    <div className="CabinTypeRefinement__cardDesc">
                      <span className="CabinTypeRefinement__name">{meta.name}</span>

                      {this.isDisabled(meta.code) && !(this.isDisabled(meta.code) ? false : isCabinTypeDisable) ? (
                        <p className="price">
                          <FormattedMessage defaultMessage="From" id="AdvancedFilter.CabinType.from" />{' '}
                          <CabinPrice
                            abTestPriceType={priceType === 'SailorPerNight' ? 'perSailor' : priceType}
                            cabins={cabins}
                            currencyCode={currencyCode}
                            isFromAdvFilter
                            sailors={sailors}
                            startingPrice={this.getAmountFromGenericCatergoryCodes(meta.code)}
                          />{' '}
                          {priceType === 'perCabin' ? (
                            <FormattedMessage defaultMessage="per cabin" id="AdvancedFilter.CabinType.perCabin.type" />
                          ) : (
                            <FormattedMessage
                              defaultMessage="per sailor"
                              id="AdvancedFilter.CabinType.perSailor.type"
                            />
                          )}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>
              ))}
            <div
              className="CabinTypeRefinement__card default"
              onClick={() => {
                if (selectedType !== null && selectedType !== defaultVal) {
                  onCabinTypeChange(defaultVal);
                }
              }}
              role="button"
              tabIndex={-1}
            >
              <div className="CabinTypeRefinement__cardRadio" id="cardRadio">
                <FormCheck
                  checked={selectedType === defaultVal || selectedType === null}
                  id="default"
                  inputTabIndex={0}
                  name="default"
                  role="presentation"
                  tabIndex={-1}
                  type="radio"
                  value={formatMessage({
                    defaultMessage: 'Any — the best value available',
                    id: 'AdvancedFilter.cabintype.Default.value',
                  })}
                />
              </div>
              <p className="CabinTypeRefinement__description default">
                <FormattedMessage
                  defaultMessage="Any â€” the best value available"
                  id="AdvancedFilter.cabintype.Default.value"
                />
              </p>
            </div>
          </div>
        </AdvancedFilterHOC>
      </div>
    );
  }

  updateFilterLabel = () => {
    const { cabinTypes, labels, selectedType, setAppliedFilterLabels } = this.props;
    const { formatMessage } = this.context;

    const title = getLabelforCabinType(cabinTypes, selectedType);
    const cabinTypeTitle = title || this.getDefaultLabel();
    this.setState({ label: cabinTypeTitle });
    const label = formatMessage({
      defaultMessage: 'Any - the best value available',
      id: 'AdvancedFilter.cabintype.Default.value',
    });
    if (title.trim() === label[0].trim()) {
      setAppliedFilterLabels({ cabinType: '' });
    } else if (labels.cabinType !== title) {
      setAppliedFilterLabels({ cabinType: title });
    }
  };
}

CabinTypeFilter.propTypes = {
  cabinTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  cabins: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeFilterModal: PropTypes.func.isRequired,
  currencyCode: PropTypes.string.isRequired,
  defaultGenericCategoryCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  genericCategoryCodes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isOpenFilterSection: PropTypes.bool,
  labels: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  onCabinTypeChange: PropTypes.func.isRequired,
  onRenderChange: PropTypes.func.isRequired,
  priceType: PropTypes.string.isRequired,
  sailors: PropTypes.number.isRequired,
  selectedType: PropTypes.string.isRequired,
  setAppliedFilterLabels: PropTypes.func.isRequired,
};

CabinTypeFilter.defaultProps = {
  isOpenFilterSection: false,
};

CabinTypeFilter.contextType = FormattedMessageContext;

const mapStateToProps = (state) => ({
  cabins: selectCabins(state),
  currencyCode: selectCurrencyCode(state),
  defaultGenericCategoryCodes: state?.chooseVoyageNew?.defaultGenericCategoryCodes || [],
  genericCategoryCodes: state?.chooseVoyageNew?.genericCategoryCodes || [],
  labels: state?.appliedfilterLabels,
  priceType: selectPriceType(state),
  sailors: selectSailors(state),
});

const mapDispatchToProps = {
  setAppliedFilterLabels: setAppliedFilterLabelsAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(withSearchParams(CabinTypeFilter));
