import React, { useContext, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import { selectDurations } from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions/selectors';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import { getDurationLabel } from '@/helpers/util/sailing';
import { FilterKey, type TFilters, type TFiltersOptions } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const DurationFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const durations = useAppSelector(selectDurations) as TFilters['durations'];
  const durationOptions = useAppSelector(getFiltersOptions).durationOptions as TFiltersOptions['durationOptions'];
  const { formatMessage } = useContext(FormattedMessageContext);

  const label = useMemo(() => {
    if (isEmpty(durations)) {
      return null;
    }

    const label = durationOptions
      .reduce((acc, duration) => {
        if (durations?.find((d) => d.min === duration.min)) {
          acc.push(getDurationLabel(duration));
        }
        return acc;
      }, [] as string[])
      .join(' & ');

    return formatMessage({
      defaultMessage: '{durations} nights',
      id: 'AdvancedRefinement.label',
      values: { durations: label },
    });
  }, [durations, formatMessage, durationOptions]);

  return label ? (
    <SelectedFilterChip filterKey={FilterKey.DURATION} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default DurationFilterChip;
