import { forwardRef } from 'react';

import * as Primitive from '@radix-ui/react-dialog';
import cn from 'classnames';

import CrossIcon from '@publicImages/svg/close-without-bg.svg?static';

import classes from './Dialog.module.scss';

export { Close, Description, Root, Title, Trigger } from '@radix-ui/react-dialog';

export const Content: typeof Primitive.Content = forwardRef(({ children, className, title, ...props }, ref) => (
  <Primitive.Portal>
    <Primitive.Overlay className={classes.overlay} />
    <Primitive.Content className={cn(classes.content, className)} {...props} ref={ref}>
      <Primitive.Close aria-label={`Close ${title} dialog`} className={classes.close} tabIndex={0}>
        <CrossIcon />
      </Primitive.Close>
      {children}
    </Primitive.Content>
  </Primitive.Portal>
));
Content.displayName = 'DialogContent';
