import { useCallback, useState } from 'react';

type useMainFilterHandlersProps = {
  onDateFilterStatusChange?: (value: boolean) => void;
  onDestinationFilterStatusChange?: (value: boolean) => void;
};

export const useMainFilterHandlers = ({
  onDateFilterStatusChange,
  onDestinationFilterStatusChange,
}: useMainFilterHandlersProps = {}) => {
  const [destinationFilterOpened, setDestinationOpened] = useState(false);
  const [dateFilterOpened, setDateOpened] = useState(false);

  const setDestinationFilterOpenedHandler = useCallback(
    (value: boolean) => {
      setDestinationOpened(value);
      if (dateFilterOpened) {
        setDateOpened(false);
      }

      onDestinationFilterStatusChange?.(value);
    },
    [setDestinationOpened, setDateOpened, dateFilterOpened, onDestinationFilterStatusChange],
  );

  const setDateFilterOpenedHandler = useCallback(
    (value: boolean) => {
      setDateOpened(value);
      if (destinationFilterOpened) {
        setDestinationOpened(false);
      }

      onDateFilterStatusChange?.(value);
    },
    [setDestinationOpened, setDateOpened, destinationFilterOpened, onDateFilterStatusChange],
  );

  return {
    dateFilterOpened,
    destinationFilterOpened,
    setDateFilterOpenedHandler,
    setDestinationFilterOpenedHandler,
  };
};
