import { useSearchParams } from 'next/navigation';
import { useEffect, useMemo, useState } from 'react';

import type { TCurrencyCode } from '@/infra/types/common';

import Select from '@/components/Select';
import { selectMultiCurrencyOptions } from '@/ducks/common/lookup/selectors';
import { selectLookup } from '@/ducks/common/selectors';
import { setCurrencyCode, setTaxInclusive } from '@/ducks/filters';
import { getFilterAgencyIsAvailable } from '@/ducks/fm/getters';
import { setAgencyCurrencyCode } from '@/ducks/fm/setters';
import { useMNVV } from '@/ducks/mnvv/hooks';
import { getCurrencyCode } from '@/helpers/util/currency/misc';
import { useAppDispatch, useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import classes from './CurrencyDropdown.module.scss';

const {
  tracker: { createTracker },
  trackerConstants,
} = tagmanager;

const trackEvent = createTracker({
  location: trackerConstants.MODULES.HEADER,
});

export const makeCurrencyOption = (currency: { code: string; flagIcon: string; symbol: string }) => ({
  enabled: true,
  label: (
    <div className={classes.option}>
      <span>{currency.flagIcon}</span>
      <span>{currency.symbol}</span>
      <span>{currency.code}</span>
    </div>
  ),
  value: currency.code,
});

const CurrencyDropdown = () => {
  const dispatch = useAppDispatch();
  const searchParams = useSearchParams();

  const isFmLinking = getFilterAgencyIsAvailable();

  const lookupCurrencies = useAppSelector((state) => selectLookup(state)?.currencies) ?? [];
  const filterCurrency = useAppSelector((state) => getCurrencyCode(state, searchParams));
  const defaultCurrencyCode = useAppSelector((state) => selectLookup(state)?.defaultCurrencyCode);
  const multiCurrencies = useAppSelector(selectMultiCurrencyOptions);
  const currencies = isFmLinking ? multiCurrencies : lookupCurrencies;

  const { isMNVV } = useMNVV();

  const [selectedValue, setSelectedValue] = useState<TCurrencyCode | undefined>();

  const currencyOptions = useMemo(() => currencies.map(makeCurrencyOption), [currencies]);

  useEffect(() => setSelectedValue(filterCurrency), [filterCurrency]);

  const onApply = async (value: TCurrencyCode) => {
    setTaxInclusive(value);
    setSelectedValue(value);
    dispatch(setCurrencyCode(value));
    if (isFmLinking) {
      setAgencyCurrencyCode(value);
    }
    trackEvent({
      action: trackerConstants.EVENT_ACTIONS.CURRENCY_CONVERTER_CLICK,
      label: value,
    });
  };
  const defaultValue = filterCurrency || defaultCurrencyCode;
  const isDisabled = isMNVV || currencyOptions?.length <= 1;
  return (
    <Select
      className={classes.root}
      defaultValue={defaultValue}
      disabled={isDisabled}
      dropdownClassName={classes.portal}
      onValueChange={onApply}
      options={currencyOptions}
      value={selectedValue}
    />
  );
};

export default CurrencyDropdown;
