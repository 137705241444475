import type { TCurrencyCode } from '@/infra/types/common';
import type { AppStore } from '@/store';

import { getCookie, setCookie } from '@/ducks/cookies';
import { CURRENCY_CODE_SOURCE } from '@/ducks/currencyCode/types';
import { setFilters } from '@/ducks/filters';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { getFilterAgencyIsAvailable } from '@/ducks/fm/getters';
import { getPathname, getSearchParam, redirect } from '@/ducks/routes/history';
import { getStoredValue, setStoredValue } from '@/helpers/util';

export type TStoreSlice = Pick<AppStore, 'dispatch' | 'getState'>;

const agreeCurrencyCode = (store: TStoreSlice, currencyCode: TCurrencyCode, sources?: CURRENCY_CODE_SOURCE[]) => {
  const { dispatch, getState } = store;
  const isFMLink = getFilterAgencyIsAvailable();
  if (typeof window !== 'undefined') {
    const { VV_CURRENCIES } = window as unknown as { VV_CURRENCIES: { currencyCode: TCurrencyCode } };
    if (VV_CURRENCIES && VV_CURRENCIES.currencyCode !== currencyCode) VV_CURRENCIES.currencyCode = currencyCode;
  }
  if (!sources?.includes(CURRENCY_CODE_SOURCE.store)) {
    if (selectCurrencyCode(getState()) !== currencyCode) dispatch(setFilters({ currencyCode }));
  }
  if (!sources?.includes(CURRENCY_CODE_SOURCE.cookie)) {
    if (getCookie('currencyCode') !== currencyCode) setCookie('currencyCode', currencyCode, { path: '/' });
  }
  if (!sources?.includes(CURRENCY_CODE_SOURCE.fm)) {
    if (isFMLink && getCookie('agencyCurrencyCode') !== currencyCode) {
      setCookie('agencyCurrencyCode', currencyCode, { path: '/' });
    }
  }
  if (!sources?.includes(CURRENCY_CODE_SOURCE.url)) {
    if (getSearchParam('currencyCode') !== currencyCode) {
      redirect(getPathname(), { currencyCode }, { keepSearch: true });
    }
  }
  if (getStoredValue('cmsCurrencyBroadcast') !== currencyCode) {
    setStoredValue('cmsCurrencyBroadcast', currencyCode);
  }
};

export default agreeCurrencyCode;
