import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import { connect } from 'react-redux';

import { useMainFilterHandlers } from '@/components/ChooseVoyage/MainFilter/hooks';
import Filter from '@/components/Icon/FilterOption';
import DateRefinement from '@/components/ResultRefinements/DateRefinement';
import DestinationRefinement from '@/components/ResultRefinements/DestinationRefinement';
import { Skeleton } from '@/components/Skeleton';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { getSelectedRegionLabel } from '@/ducks/pages/chooseVoyage';
import { getFiltersForPackages } from '@/ducks/pages/chooseVoyage/destinationSelectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { FormattedMessage } from '@/helpers/formatted-message';
import { reducedMonthAndYear } from '@/helpers/util/dateUtil';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import tagmanager from '@/tagmanager';

import './FilterView.scss';

const FilterViewForSM = ({
  appliedEndDate,
  appliedStartDate,
  closeFilterModal,
  filteredSailings,
  filteredSailingsLoading,
  itineraryFilters,
  openFilterModal,
}) => {
  const label = getSelectedRegionLabel(itineraryFilters);

  const onDestinationFilterClick = useCallback(
    (isOpened) => {
      if (!isOpened) {
        return;
      }
      tagmanager.tracker.voyagesFilter.trackOpenMainFilters({
        name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.DESTINATIONS,
        value: label,
      });
    },
    [label],
  );

  const onDateFilterClick = useCallback(
    (isOpened) => {
      if (!isOpened) {
        return;
      }
      tagmanager.tracker.voyagesFilter.trackOpenMainFilters({
        name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.TRAVEL_DATES,
        value: `${reducedMonthAndYear(appliedStartDate)}_${reducedMonthAndYear(appliedEndDate)}`,
      });
    },
    [appliedStartDate, appliedEndDate],
  );

  const { dateFilterOpened, destinationFilterOpened, setDateFilterOpenedHandler, setDestinationFilterOpenedHandler } =
    useMainFilterHandlers({
      onDateFilterStatusChange: onDateFilterClick,
      onDestinationFilterStatusChange: onDestinationFilterClick,
    });

  const onKeyDownFilter = useOnKeyDown(openFilterModal, { isDefaultPrevented: true });

  return (
    <>
      <div className="FilterText">
        <div className="heading --mobile">
          <div className="refinementText">
            <span
              aria-label="Showing"
              className="text"
              onClick={openFilterModal}
              onKeyDown={onKeyDownFilter}
              role="button"
              tabIndex={0}
            >
              <FormattedMessage defaultMessage="Showing" id="ChooseVoyage.Results.showing" />
            </span>
            <span className="text action">
              <span className="sailingsCountText">
                {filteredSailingsLoading ? <Skeleton inline width="22px" /> : filteredSailings.length}
              </span>
            </span>
            <span
              aria-label="cruise vacations sailing"
              className="text"
              onClick={openFilterModal}
              onKeyDown={onKeyDownFilter}
              role="button"
              tabIndex={0}
            >
              <FormattedMessage defaultMessage="cruise vacations" id="ChooseVoyage.Results.cruise.vacations.name" />:
            </span>
            &nbsp;
            <span className="text action">
              <span className="regionsText">
                <DestinationRefinement
                  closeFilterModal={closeFilterModal}
                  filterOpened={destinationFilterOpened}
                  hideTitle
                  onRenderChange={setDestinationFilterOpenedHandler}
                  toggleFromMobileSelectedRegionText
                />
              </span>
            </span>
            &nbsp;
            <span className="text action">
              <span className="refinement">
                <DateRefinement filterOpened={dateFilterOpened} hideTitle onRenderChange={setDateFilterOpenedHandler} />
              </span>
            </span>
          </div>
        </div>
        <div
          aria-label="filter"
          className="filterIconMobile"
          onClick={openFilterModal}
          onKeyDown={onKeyDownFilter}
          role="button"
          tabIndex={0}
        >
          <Filter />
        </div>
      </div>
    </>
  );
};

FilterViewForSM.propTypes = {
  appliedEndDate: PropTypes.string.isRequired,
  appliedStartDate: PropTypes.string.isRequired,
  filteredSailings: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  filteredSailingsLoading: PropTypes.bool.isRequired,
  itineraryFilters: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

function mapStateToProps(state) {
  return {
    appliedEndDate: selectToDate(state) || '',
    appliedStartDate: selectFromDate(state) || '',
    filteredSailings: state?.chooseVoyageNew?.filteredPackages?.sailings || [],
    filteredSailingsLoading: selectIsFilteringLoading(state),
    itineraryFilters: getFiltersForPackages(state) || [],
  };
}

export default connect(mapStateToProps)(FilterViewForSM);
