import { useCallback, useMemo } from 'react';

import isEmpty from 'lodash/isEmpty';

import type { TSailingData } from '@/infra/types/voyageInfo/sailing';

import { useUIResourcePool } from '@/ducks/common/resources';
import { selectCabins, selectIsPriceRangeEmpty, selectSailors } from '@/ducks/filters/selectors';
import { setPriceRangeForPackages as setPriceRangeAction } from '@/ducks/pages/chooseVoyage';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { useAppDispatch, useAppSelector } from '@/store';

export const usePriceTypeOptions = () => {
  const { perCabin, perSailor, sailorPerNight } = useUIResourcePool({
    perCabin: 'VoyageFilter.PerCabin',
    perSailor: 'VoyageFilter.PerSailor',
    sailorPerNight: 'VoyageFilter.SailorPerNight',
  });
  return useMemo(
    () =>
      [
        {
          enabled: true,
          label: perCabin,
          value: 'perCabin',
        },
        {
          enabled: true,
          label: perSailor,
          value: 'perSailor',
        },
        {
          enabled: true,
          label: sailorPerNight,
          value: 'SailorPerNight',
        },
      ] as const,
    [perCabin, perSailor, sailorPerNight],
  );
};

export const useUpdatePriceRangeOnPriceTypeChangeHandler = () => {
  const dispatch = useAppDispatch();
  const appliedSailings = useAppSelector(
    // @ts-expect-error TODO: fix slice typings
    (state) => state?.chooseVoyageNew?.filteredPackages?.sailings as TSailingData[],
  );
  const cabins = useAppSelector(selectCabins);
  const sailors = useAppSelector(selectSailors);
  const isFilterPriceRangeEmpty = useAppSelector(selectIsPriceRangeEmpty);

  // TODO: Rethink how we update price range. Current code return unstable results.
  return useCallback(
    (priceType: string) => {
      if (isEmpty(appliedSailings) || isFilterPriceRangeEmpty) {
        return;
      }

      const filters = { cabins, priceType, sailors };

      let sailingsAmounts;
      if (priceType === 'SailorPerNight') {
        sailingsAmounts = appliedSailings.map((s) => getAmountPerVoyage(s.startingPrice, s.duration, filters));
      } else {
        sailingsAmounts = appliedSailings.map((s) => getAmountPerItem(s.startingPrice, filters));
      }

      const toBeAppliedPriceRange = {
        maxPrice: Math.ceil(Math.max(...sailingsAmounts)) || null,
        minPrice: Math.floor(Math.min(...sailingsAmounts)) || null,
      };
      dispatch(setPriceRangeAction(toBeAppliedPriceRange));
    },
    [appliedSailings, isFilterPriceRangeEmpty, dispatch, sailors, cabins],
  );
};
