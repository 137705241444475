import { useCallback } from 'react';

import { resetAKFlowFully, resetAKFlowSearchCriteria } from '@/components/AccessKeyCard/new/helpers';
import UIResource from '@/components/UIResource';
import { useAppDispatch } from '@/store';

import TrueZeroResults from './TrueZeroResults';

const AKTrueZeroResults = () => {
  const dispatch = useAppDispatch();

  const resetSearchCriteria = useCallback(() => {
    dispatch(resetAKFlowSearchCriteria());
  }, [dispatch]);

  const resetFully = useCallback(() => {
    dispatch(resetAKFlowFully());
  }, [dispatch]);

  return (
    <TrueZeroResults
      description={<UIResource id="ChooseVoyage.ZeroResults.AKheading" />}
      emoji={<UIResource id="ChooseVoyage.ZeroResults.AKemojis" />}
      firstButtonText={<UIResource id="ChooseVoyage.ZeroResults.AKprimaryCTA" />}
      onFirstButtonClick={resetSearchCriteria}
      onSecondButtonClick={resetFully}
      secondButtonText={<UIResource id="ChooseVoyage.ZeroResults.AKsecondaryCTA" />}
    />
  );
};

export default AKTrueZeroResults;
