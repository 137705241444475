import React, { useContext, useMemo } from 'react';

import { selectCurrencyCode, selectMaxPrice, selectMinPrice, selectPriceType } from '@/ducks/filters/selectors';
import { FormattedMessageContext } from '@/helpers/formatted-message';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { FilterKey, FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const normPrice = (value: null | number | string) => (!value ? 0 : parseInt(value as string, 10));

const PriceFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const currencyCode = useAppSelector(selectCurrencyCode);
  const maxPrice = useAppSelector(selectMaxPrice);
  const minPrice = useAppSelector(selectMinPrice);
  const priceType = useAppSelector(selectPriceType);
  const { formatMessage } = useContext(FormattedMessageContext);

  const label = useMemo(() => {
    const _minPrice = normPrice(minPrice);
    const _maxPrice = normPrice(maxPrice);

    if (_minPrice === 0 && _maxPrice === 0) {
      return null;
    }

    let defaultMessage = '{n} - {m} per sailor';
    let id = 'AdvancedFilter.PriceRange.label.perSailor';
    if (priceType === FiltersPriceType.perCabin) {
      defaultMessage = '{n} - {m} per cabin';
      id = 'AdvancedFilter.PriceRange.label.perCabin';
    } else if (priceType === FiltersPriceType.sailorPerNight) {
      defaultMessage = '{n} - {m} Sailor per night';
      id = 'AdvancedFilter.PriceRange.label.SailorPerNight';
    }

    const label = formatMessage({
      defaultMessage,
      id,
      values: {
        m: `${getSymbolFromCurrenciesData(currencyCode)}${Math.floor(_maxPrice)}`,
        n: `${getSymbolFromCurrenciesData(currencyCode)}${Math.floor(_minPrice)}`,
      },
    });

    return label;
  }, [currencyCode, maxPrice, minPrice, priceType, formatMessage]);

  return label ? (
    <SelectedFilterChip filterKey={FilterKey.PRICE} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default PriceFilterChip;
