'use client';

import cn from 'classnames';

import Countdown from '@/components/Countdown';
import useBlackFridayCountdown from '@/ducks/programs/blackFriday/hooks/useBlackFridayCountdown';

import './style.scss';

export const BlackFridayTimer = ({ className }: { className?: string }) => {
  const { endTime, isEnabled } = useBlackFridayCountdown();

  if (!isEnabled || new Date(endTime) < new Date()) {
    return null;
  }

  return (
    <div className={cn('BlackFridayTimer', className)}>
      <Countdown className="BlackFridayTimer__countdown" frequency={3} targetTime={endTime} />
    </div>
  );
};
