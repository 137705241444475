import { useMemo } from 'react';

import { type RootState, useAppSelector } from '@/store';

type withSkeletonProps<TProps> = {
  selector: (state: RootState, props: TProps) => boolean;
  skeleton: React.JSXElementConstructor<TProps>;
};

const withSkeleton = <TProps extends JSX.IntrinsicAttributes>(
  Component: React.JSXElementConstructor<TProps>,
  { selector, skeleton: Skeleton }: withSkeletonProps<TProps>,
) =>
  function SkeletonWrapper(props: TProps) {
    const selectIsLoaded = useMemo(() => (state: RootState) => selector(state, props), [props]);
    const isLoaded = useAppSelector(selectIsLoaded);
    return isLoaded ? <Component {...(props as TProps)} /> : <Skeleton {...(props as TProps)} />;
  };

export default withSkeleton;
