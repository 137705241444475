import React from 'react';

import type { TWhatsIncluded } from '@/components/WhatsIncluded/WhatsIncludedTypes';

import Flyout from '@/components/Flyout';
import Image from '@/components/Image';
import UIResource from '@/components/UIResource';
import { ModalContent, ModalContentBody } from '@/components/elements/ModalContent';

import './WhatsIncludedModal.scss';

interface WhatsIncludedModalProps {
  features: TWhatsIncluded[];
  isOpen: boolean;
  onDismiss: () => void;
}

const WhatsIncludedModal = ({ features, isOpen, onDismiss }: WhatsIncludedModalProps) => {
  const rootClass = 'WhatsIncludedModal';

  const includedItems = React.useMemo(
    () =>
      features &&
      features.map((feature) => (
        <li className={`${rootClass}__list__item`} key={feature.text} tabIndex={0}>
          <Image alt={feature.icon?.alt || ''} className={`${rootClass}__list__item__image`} src={feature.icon?.src} />
          <div className={`${rootClass}__list__item__text`}>
            <div className={`${rootClass}__list__item__text__header`}>{feature.text}</div>
            <div className={`${rootClass}__list__item__text__description`}>{feature.description}</div>
          </div>
        </li>
      )),
    [features],
  );

  return (
    <Flyout
      alignButtonRight
      contentClassName={`${rootClass}__flyoutContent`}
      direction="modal"
      focusOnOpenSelector={`.${rootClass}__close`}
      hideCrossButton
      invert
      onDismiss={onDismiss}
      open={isOpen}
    >
      <ModalContent extendedClassName={`${rootClass}__background`}>
        <ModalContentBody modalClass={`${rootClass}__content`}>
          <button className={`${rootClass}__close`} onClick={onDismiss}>
            <span aria-hidden={true}>&times;</span>
            <span className="sr-only">Close</span>
          </button>
          <div className={`${rootClass}__header`}>
            <UIResource id="WhatsIncluded.title" />
          </div>
          <ul className={`${rootClass}__list`}>{includedItems}</ul>
        </ModalContentBody>
      </ModalContent>
    </Flyout>
  );
};

export default WhatsIncludedModal;
