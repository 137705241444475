import { type KeyboardEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import type { TWhatsIncluded } from '@/components/WhatsIncluded/WhatsIncludedTypes';

import { Skeleton } from '@/components/Skeleton';
import { useUIResource } from '@/ducks/common/resources';
import { selectIsWhatsIncludedLoaded } from '@/ducks/pages/voyagePlanner/selectors';
import withSkeleton from '@/hocs/withSkeleton';

import Image from '../Image';

type WhatsIncludedContentProps = {
  onBannerClick: React.MouseEventHandler;
  sortedFeatures: TWhatsIncluded[];
};

const WhatsIncludedContent = ({ onBannerClick, sortedFeatures }: WhatsIncludedContentProps) => {
  const rootClass = 'WhatsIncluded';
  const title = useUIResource('WhatsIncluded.title');
  const [focusedItemIndex, setFocusedItemIndex] = useState(0);
  const whatsIncludedList = useRef<HTMLUListElement>(null);
  const showModalBtn = useRef<HTMLButtonElement>(null);

  const onArrowKeyDown: KeyboardEventHandler<HTMLLIElement> = useCallback(
    (e) => {
      if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
        e.preventDefault();
        setFocusedItemIndex((prevState) => (prevState - 1 + sortedFeatures.length) % sortedFeatures.length);
      }
      if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
        e.preventDefault();
        setFocusedItemIndex((prevState) => (prevState + 1) % sortedFeatures.length);
      }
      if (e.key === 'Tab' && e.shiftKey) {
        e.preventDefault();
        showModalBtn.current?.focus();
      }
    },
    [sortedFeatures],
  );

  useEffect(() => {
    (whatsIncludedList.current?.children[focusedItemIndex] as HTMLLIElement)?.focus();
  }, [focusedItemIndex]);

  const includedItems = useMemo(
    () =>
      sortedFeatures !== null &&
      sortedFeatures.length > 0 &&
      sortedFeatures.map((feature, index) => {
        const ariaLabel = `${index + 1} of ${sortedFeatures.length} items, ${feature.text}`;
        return (
          <li
            aria-label={ariaLabel}
            className={`${rootClass}__list__item`}
            key={feature.text}
            onKeyDown={onArrowKeyDown}
            role="menuitem"
            tabIndex={index === focusedItemIndex ? 0 : -1}
          >
            <Image alt={''} className={`${rootClass}__list__item__image`} src={feature.icon?.src} />
            <span className={`${rootClass}__list__item__text`}>{feature.text}</span>
          </li>
        );
      }),
    [sortedFeatures, focusedItemIndex, onArrowKeyDown],
  );

  return (
    <>
      <button
        aria-label={`Show more information about what’s Included in your cruise, ${title}`}
        aria-orientation="horizontal"
        className={rootClass}
        onClick={onBannerClick}
        ref={showModalBtn}
        role="menu"
        tabIndex={0}
      >
        <div>
          <div className={`${rootClass}__header`}>{title}</div>
          <ul className={`${rootClass}__list`} ref={whatsIncludedList} role="presentation">
            {includedItems}
          </ul>
        </div>
      </button>
    </>
  );
};

export default withSkeleton(WhatsIncludedContent, {
  selector: selectIsWhatsIncludedLoaded,
  skeleton: () => <Skeleton height="130px" />,
});
