import PropTypes from 'prop-types';
import React from 'react';

import classNames from 'classnames';

import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import { imitateClickOnKeyEvent } from '@/hooks/useOnKeyDown';

import LuggageAnimation from '../Animations/LuggageAnimation';
import { FormCheck } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

class DurationFilter extends BaseFilter {
  renderMainContent = () => {
    const {
      defaultAnimation,
      durations,
      filteredDurationOptions,
      getDurationLabel,
      isWeekendDisabled,
      onDurationChange,
      onWeekendChange,
      weekend,
    } = this.props;
    return (
      <>
        <div className="img">
          <LuggageAnimation
            defaultAnimation={defaultAnimation}
            durations={filteredDurationOptions}
            selectedDurations={durations}
          />
        </div>
        <div className="action">
          <div className="AdvancedRefinement__nights">
            {filteredDurationOptions &&
              filteredDurationOptions.map((duration) => {
                const active = durations?.find((d) => d.min === duration.min);

                const durationClassNames = classNames('AdvancedRefinement__noofdays', {
                  active,
                });
                return (
                  <div
                    aria-checked={!!active}
                    className={durationClassNames}
                    key={duration}
                    onClick={() => onDurationChange(duration, true)}
                    onKeyDown={imitateClickOnKeyEvent}
                    role="checkbox"
                    tabIndex={0}
                  >
                    <span>{getDurationLabel(duration)}</span>
                  </div>
                );
              })}
          </div>
        </div>

        <div className="AdvancedRefinement__weekend">
          <FormCheck
            checked={weekend}
            className="weekend-form-check"
            disabled={!weekend && !isWeekendDisabled}
            id="weekendOnly"
            inputTabIndex={0}
            name="weekendOnly"
            onChange={onWeekendChange}
            role="presentation"
            tabIndex={-1}
            type="checkbox"
            value="weekend"
          >
            <FormattedMessage defaultMessage="Weekend voyages only" id="AdvancedRefinement.weekendLabel" />
          </FormCheck>
        </div>
        <p className="subtitle">
          <FormattedMessage
            defaultMessage="When selected, only voyages with weekends will be included"
            id="AdvancedFilter.Duration.weekendLabel.subtitle"
          />
        </p>
      </>
    );
  };

  componentDidMount() {
    const { onRenderChange } = this.props;
    onRenderChange(false);
  }

  render() {
    const { closeFilterModal, isOpenFilterSection, label } = this.props;
    const { formatMessage } = this.context;
    const durationText = formatMessage({
      defaultMessage: 'Duration',
      id: 'AdvancedFilter.Duration',
    });
    return (
      <div className="DurationFilter__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          defaultValue={label}
          filterName={durationText}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          {this.renderMainContent()}
        </AdvancedFilterHOC>
      </div>
    );
  }
}

DurationFilter.propTypes = {
  closeFilterModal: PropTypes.func.isRequired,
  defaultAnimation: PropTypes.string,
  durations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  filteredDurationOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  getDurationLabel: PropTypes.func.isRequired,
  isOpenFilterSection: PropTypes.bool,
  isWeekendDisabled: PropTypes.bool.isRequired,
  label: PropTypes.node.isRequired,
  onDurationChange: PropTypes.func.isRequired,
  onRenderChange: PropTypes.func.isRequired,
  onWeekendChange: PropTypes.func.isRequired,
  weekend: PropTypes.bool.isRequired,
};
DurationFilter.defaultProps = { defaultAnimation: 'large', isOpenFilterSection: false };
DurationFilter.contextType = FormattedMessageContext;

export default DurationFilter;
